import { ComplaintOnDocumentActionType } from "src/app/root/store/actions/complaint-on-documnet-dlg.action";
import { ComplaintOnDocumentAction } from "src/app/root/store/actions/complaint-on-documnet-dlg.action";
import { ComplaintOnDocumentDlgState } from "src/app/root/store/reducers/complaint-on-document-dlg/complaint-on-document-dlg.state";

const initialState: ComplaintOnDocumentDlgState = {
    loading: false,
    success: false,
    error: null,
};

/**
 * Обработка событий, связанных с состоянием запроса жалобы оператора на документ.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 *
 * @return Состояние поле обработки события.
 */
export function complaintOnDocumentDlgReducer(
    state: ComplaintOnDocumentDlgState = initialState,
    action: ComplaintOnDocumentAction
): ComplaintOnDocumentDlgState {

    let result: ComplaintOnDocumentDlgState = state;

    switch (action.type) {

        case ComplaintOnDocumentActionType.COMPLAINT_ON_DOCUMENT_INIT: {

            result = initialState;
            break;
        }

        case ComplaintOnDocumentActionType.COMPLAINT_ON_DOCUMENT_REQUEST: {

            result = {
                ...result,
                success: false,
                loading: true,
                error: null,
            };
            break;
        }

        case ComplaintOnDocumentActionType.COMPLAINT_ON_DOCUMENT_SUCCESS: {

            result = {
                ...result,
                success: true,
                loading: false,
            };
            break;
        }

        case ComplaintOnDocumentActionType.COMPLAINT_ON_DOCUMENT_FAIL: {

            result = {
                ...result,
                loading: false,
                error: action.payload,
            };
            break;
        }
    }

    return result;
}
