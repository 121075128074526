import { createSelector } from "@ngrx/store";
import { OperatorDocumentType } from "src/app/common/models/document-type";
import { QueuedDocument } from "src/app/operator/models/queued-document";
import { QueueState } from "src/app/operator/store/reducers/";
import { queueStateSelector } from "src/app/operator/store/reducers/";
import { OperatorState } from "src/app/operator/store/reducers/operator.state";
import { documentTypesSelectors } from "src/app/root/store/selectors/document-types.selector";

/**
 * Селектор состояния работы оператора.
 */
const state = createSelector(
    queueStateSelector,
    (queueState: QueueState) => queueState && queueState.operatorState
);

/**
 * Селектор текущего документа в обработке.
 */
const queuedDocument = createSelector(
    state,
    (operatorState: OperatorState) => operatorState.queuedDocument
);

/**
 * Селектор индекса текущей страницы таблицы позиций документа.
 */
const currentTablePageIndex = createSelector(
    state,
    (operatorState: OperatorState) => operatorState.currentPageIndex,
);


/**
 * Селектор документа с исправленным типом.
 */
const fixedTypeQueuedDocument = createSelector(
    queuedDocument,
    documentTypesSelectors.foreignTypes,
    fixDocumentType
);


/**
 * Селектор флага иностранного документа в обработке.
 */
const foreignDocument = createSelector(
    queuedDocument,
    (document: QueuedDocument) =>
        !!document && !!document.recognitionSettings && document.recognitionSettings.foreign
);

/**
 * Объект селекторов состояния.
 */
const selectors = {
    state,
    queuedDocument,
    fixedTypeQueuedDocument,
    foreignDocument,
    currentTablePageIndex,
};

/**
 * Объект селекторов состояния для чтения.
 */
export const operatorStateSelectors: Readonly<typeof selectors> = selectors;


/**
 * Подменяем тип у иностранного документа, если у типа распознанного документа foreign = false.
 *
 * @param document Документ в очереди на обработку.
 * @param types Типы документов.
 *
 * @return Документ с подмененным типом.
 */
function fixDocumentType(document: QueuedDocument, types: OperatorDocumentType[]): QueuedDocument {

    let result = document;

    if (document && document.originalData && document.recognitionSettings && document.recognitionSettings.foreign) {

        const foreignType: OperatorDocumentType = types
            .filter((type: OperatorDocumentType) => type.foreign)
            .find((type: OperatorDocumentType) => document.originalData.type === type.id);

        if (!foreignType) {

            result = {
                ...result,
                originalData: {
                    ...result.originalData,
                    type: "UNKNOWN"
                }
            };
        }
    }

    return result;
}
