import { PageDTO } from "src/app/common/models/page-dto.model";
import { DropPagesAfterPageProps } from "src/app/operator/store/actions/props/operator/drop-pages-after-page.action-props";
import { LoginAction } from "src/app/root/store/actions/login.action";
import { LoginActionType } from "src/app/root/store/actions/login.action";
import { OperatorDocumentDto } from "../../models";
import { ParseByResult } from "../../models";
import { QueuedDocument } from "../../models";
import { OperatorActionType } from "../actions";
import { OperatorAction } from "../actions";

import { OperatorState } from "./operator.state";

/**
 * Начальное состояние работы оператора.
 */
const initialState = new OperatorState();

/**
 * Обработчик событий, связанных с работой оператора.
 *
 * @param state Состояние работы оператора.
 * @param action Событие произошедшее в системе.
 */
export function operatorReducer(state: OperatorState = initialState, action: OperatorAction | LoginAction): OperatorState {

    let result = state;

    switch (action.type) {

        // На требование загрузки текущего обрабатываемого документа или для редактирования.
        case OperatorActionType.LOAD_FOR_EDIT: {

            result = {
                ...state,
                loadingOverHttp: true,
                waiting: true,
                error: null
            };
            break;
        }

        // На событие успешной загрузки текущего обрабатываемого документа или документа для редактирования.
        case OperatorActionType.LOAD_FOR_EDIT_SUCCESS: {

            const queuedDocument: QueuedDocument = getClientSideQueuedDocument(action.payload);

            result = {
                ...state,
                queuedDocument,
                loadingOverHttp: false,
                waiting: false,
                pageViewerVisible: true,
            };
            break;
        }

        // На событие неудачной загрузки текущего обрабатываемого документа или для редактирования.
        case OperatorActionType.LOAD_FOR_EDIT_FAIL: {

            result = {
                ...state,
                loadingOverHttp: false,
                waiting: false,
                error: action.payload,
            };
            break;
        }

        // На требование получения документа из очереди на обработку.
        case OperatorActionType.ACQUIRE: {

            result = {
                ...state,
                waiting: true,
            };
            break;
        }

        // На событие успешного получения документа из очереди на обработку.
        case OperatorActionType.ACQUIRE_NEW_SUCCESS: {

            const queuedDocument: QueuedDocument = getClientSideQueuedDocument(action.payload);
            result = {
                ...state,
                queuedDocument,
                finishing: false,
                waiting: !queuedDocument,
                loadingOverHttp: false,
                blockingDocument: null,
                blockingDocumentLoading: false,
                pageViewerVisible: true,
                viewedPages: state.postponedViewedPages,
                postponedViewedPages: [],
                lastSentDocumentAction: null,
                failedSendingAttempts: 0,
            };
            break;
        }

        // На событие получение того же самого документа, который был отправлен ранее в очередь
        case OperatorActionType.ACQUIRE_OLD: {

            result = {
                ...state,
                failedSendingAttempts: state.failedSendingAttempts + 1,
                finishing: false,
            };
            break;
        }

        case OperatorActionType.OPEN_SPLITTING: {

            const queuedDocument: QueuedDocument = getClientSideQueuedDocument(action.payload);
            result = {
                ...state,
                queuedDocument,
                finishing: false,
                waiting: !queuedDocument,
                loadingOverHttp: false,
            };
            break;
        }

        case OperatorActionType.DOCUMENT_SPLITTED_SUCCESS: {

            let queuedDocument: QueuedDocument = state.queuedDocument;

            if (queuedDocument.id === action.queuedDocumentId) {

                queuedDocument = null;
            }

            result = {
                ...state,
                queuedDocument: queuedDocument,
                waiting: !queuedDocument,
                finishing: false,
                loadingOverHttp: false,
                pageViewerVisible: true,
                viewedPages: state.postponedViewedPages,
                postponedViewedPages: [],
            };
            break;
        }

        case OperatorActionType.DOCUMENT_SPLITTED_AND_STOP_SUCCESS: {

            let queuedDocument: QueuedDocument = state.queuedDocument;

            if (queuedDocument.id === action.queuedDocumentId) {

                queuedDocument = null;
            }

            result = {
                ...state,
                queuedDocument: queuedDocument,
                waiting: !queuedDocument,
                finishing: false,
                loadingOverHttp: false,
                pageViewerVisible: true,
                viewedPages: state.postponedViewedPages,
                postponedViewedPages: [],
            };
            break;
        }

        // На требование загрузки распознанных документов.
        case OperatorActionType.RECOGNIZED_DATA: {

            result = {
                ...state,
                recognizedDataLoading: true,
                recognizedDataError: null,
            };
            break;
        }

        // На событие успешной загрузки распознанных данных.
        case OperatorActionType.RECOGNIZED_DATA_SUCCESS: {

            result = {
                ...state,
                recognizedData: action.payload,
                recognizedDataLoading: false,
            };
            break;
        }

        // На событие неудачной загрузки распознанных данных.
        case OperatorActionType.RECOGNIZED_DATA_FAIL: {

            result = {
                ...state,
                recognizedDataLoading: false,
                recognizedDataError: action.payload,
            };
            break;
        }

        // На требование сохранения промежуточного результата обработки документа.
        case OperatorActionType.SAVE_DATA: {

            result = {
                ...state,
                saveLoading: true,
                saveError: null,
            };
            break;
        }

        // На событие успешного сохранения промежуточного результата обработки документа.
        case OperatorActionType.SAVE_DATA_SUCCESS: {

            result = {
                ...state,
                saveLoading: false,
                lastSaveDate: new Date().getTime(),
            };
            break;
        }

        // На событие неудачного сохранения промежуточного результата обработки документа.
        case OperatorActionType.SAVE_DATA_FAIL: {

            result = {
                ...state,
                saveLoading: false,
                saveError: action.payload,
            };
            break;
        }

        // На событие требования очистки состояния о сохранении промежуточного результата обработки документа.
        case OperatorActionType.SAVE_DATA_CLEAR: {

            result = {
                ...state,
                lastSaveDate: null,
                saveError: null,
            };
            break;
        }

        // На требование извлечения данных документа по разметке.
        case OperatorActionType.PARSE_BY_MARKUP: {

            result = {
                ...state,
                parseByMarkupLoading: true,
                waiting: true,
            };
            break;
        }

        // На событие успешного извлечения данных документа по разметке.
        case OperatorActionType.PARSE_BY_MARKUP_SUCCESS: {

            const parseByMarkupResult: ParseByResult = action.payload;
            const queuedDocument: QueuedDocument = {
                ...state.queuedDocument,
                originalData: {
                    ...parseByMarkupResult.document,
                    parsingProblems: state.queuedDocument.originalData.parsingProblems,
                    isFineRecognized: parseByMarkupResult.isFineRecognized,
                },
            };

            result = {
                ...state,
                queuedDocument,
                parseByMarkupLoading: false,
                waiting: false,
                parsedByOperator: true,
                viewedPages: [],
            };
            break;
        }

        // На событие неудачного извлечения данных документа по разметке.
        case OperatorActionType.PARSE_BY_MARKUP_FAIL: {

            result = {
                ...state,
                parseByMarkupLoading: false,
                waiting: false,
                error: action.payload
            };
            break;
        }

        // На требование извлечения данных документа по типу.
        case OperatorActionType.PARSE_BY_TYPE: {

            result = {
                ...state,
                parseByTypeLoading: true,
                waiting: true,
            };
            break;
        }

        // На событие успешного извлечения данных документа по типу.
        case OperatorActionType.PARSE_BY_TYPE_SUCCESS: {

            const parseByTypeResult: ParseByResult = action.payload;
            const queuedDocument: QueuedDocument = {
                ...state.queuedDocument,
                originalData: {
                    ...parseByTypeResult.document,
                    parsingProblems: state.queuedDocument.originalData.parsingProblems,
                    isFineRecognized: parseByTypeResult.isFineRecognized,
                },
            };

            result = {
                ...state,
                queuedDocument,
                parseByTypeLoading: false,
                waiting: false,
                parsedByOperator: true,
                viewedPages: [],
            };
            break;
        }

        // На событие неудачного извлечения данных документа по типу.
        case OperatorActionType.PARSE_BY_TYPE_FAIL: {

            result = {
                ...state,
                parseByTypeLoading: false,
                waiting: false,
                error: action.payload
            };
            break;
        }

        // На требование изменения текущей страницы таблицы документа.
        case OperatorActionType.CHANGE_CURRENT_TABLE_PAGE: {

            result = {
                ...state,
                currentPageIndex: action.pageIndex,
            };
            break;
        }


        // На событие требования сохранения результатов обработки документа.
        case OperatorActionType.FINISH_AND_STOP: {

            result = {
                ...state,
                finishing: true,
                waiting: false,
                blockingDocument: null,
                takeBlockingCountdownPending: false,
                lastSentDocumentAction: {document: action.payload, stop: true},
            };
            break;
        }
        case OperatorActionType.UPDATE_AFTER_EDIT: {

            result = {
                ...state,
                finishing: true,
                blockingDocument: null,
                takeBlockingCountdownPending: false,
                lastSentDocumentAction: null,
                failedSendingAttempts: 0,
            };
            break;
        }

        case OperatorActionType.FINISH_AND_NEXT: {

            result = {
                ...state,
                waiting: false,
                finishing: true,
                takeBlockingCountdownPending: false,
                lastSentDocumentAction: {document: action.payload, stop: false},
            };
            break;
        }

        // На событие успешного сохранения результатов обработки документа.
        case OperatorActionType.FINISH_AND_STOP_SUCCESS: {

            result = {
                ...state,
                queuedDocument: null,
                finishing: false,
                waiting: false
            };
            break;
        }

        case OperatorActionType.FINISH_AND_NEXT_SUCCESS: {

            let queuedDocument = null;

            if (!!state.blockingDocument) {

                queuedDocument = state.blockingDocument;
            }

            if (state.finishing) {

                result = {
                    ...state,
                    queuedDocument: queuedDocument,
                    blockingDocument: null,
                    finishing: false,
                    waiting: !queuedDocument,
                };
            }

            break;
        }

        // На событие неудачного сохранения результатов обработки документа.
        case OperatorActionType.FINISH_FAILED_NEED_RETRY: {

            result = {
                ...state,
                finishing: false,
                waiting: true,
            };
            break;
        }
        case OperatorActionType.FINISH_FAILED:
        case OperatorActionType.UPDATE_AFTER_EDIT_FAILED: {

            result = {
                ...state,
                finishing: false,
                error: action.payload,
            };
            break;
        }

        // На событие успешной загрузки информации о блокирующем документе.
        case OperatorActionType.GOT_BLOCKING_DOCUMENT: {

            const blockingDocument: QueuedDocument = action.blockingDocument || null;

            result = {
                ...state,
                blockingDocument,
            };
            break;
        }

        // На событие изменения обратного отсчёта перед автоматической загрузкой блокирущего документа на обработку.
        case OperatorActionType.TAKE_BLOCKING_COUNT_DOWN: {

            result = {
                ...state,
                takeBlockingCountdown: action.count,
                takeBlockingCountdownPending: true,
            };
            break;
        }

        // На событие требования выполнения взятия блокирующего документа из очереди.
        case OperatorActionType.LOAD_BLOCKING: {

            result = {
                ...state,
                blockingDocumentLoading: true,
                takeBlockingCountdownPending: false,
            };
            break;
        }

        // На событие требования изменения набора страниц документа.
        case OperatorActionType.CHANGE_PAGES: {

            const originalData: OperatorDocumentDto = {
                ...state.queuedDocument.originalData,
                ...action.processedData,
                pages: action.pages,
            };
            const queuedDocument = {
                ...state.queuedDocument,
                originalData,
            };

            result = {
                ...state,
                queuedDocument,
                localLoading: true,
            };
            break;
        }

        //На событие требования добавить номер страницы в просмотренные.
        case OperatorActionType.ADD_PAGE_TO_VIEWED_PAGES: {

            result = {
                ...state,
                viewedPages: [...state.viewedPages, action.pageNumber],
            };

            break;
        }

        // На событие в процессе проверки работы новичка, требующее возвращения к данным документа, с которыми он
        // пришёл в очередь до обработки новичка.
        case OperatorActionType.BACK_BEFORE_NEWBIE_DATA: {

            const queuedDocument = {
                ...state.queuedDocument,
                originalData: state.queuedDocument.trulyOriginalData,
                isNewbieData: false,
            };

            result = {
                ...state,
                queuedDocument,
                localLoading: true,
            };
            break;
        }

        // На событие в процессе проверки работы новичка, требующее возвращения к данным документа, которые
        // получил оператор новичок после обработки документа.
        case OperatorActionType.RETURN_NEWBIE_DATA: {

            const queuedDocument = {
                ...state.queuedDocument,
                originalData: {
                    ...state.queuedDocument.newbieData,
                    // Проблемы парсинга затираем.
                    parsingProblems: [],
                },
                isNewbieData: true,
            };

            result = {
                ...state,
                queuedDocument,
                localLoading: true,
            };
            break;
        }

        // На событие требования сброса флага операции, выполняющейся локально без запросов к серверу.
        case OperatorActionType.STOP_LOCAL_LOADING: {

            result = {
                ...state,
                localLoading: false,
            };
            break;
        }

        // На событие требования остановки ожидания документа из очереди на обработку.
        case OperatorActionType.STOP_WAITING: {

            result = {
                ...state,
                waiting: false
            };
            break;
        }

        // На событие требования очистки данных об ошибке выполнения запроса.
        case OperatorActionType.CLEAR_ERROR: {

            result = {
                ...state,
                error: null
            };
            break;
        }

        // На событие требования удалить все страницы после указанной.
        case OperatorActionType.DROP_PAGES_AFTER_PAGE: {

            const props: DropPagesAfterPageProps = action.props;
            const initialPages: PageDTO[] = [ ...state.queuedDocument.originalData.pages ];
            let pages: PageDTO[];

            if (props.localNavigation) {

                // При локальном режиме просмотра props.page соответствует индексу страницы (в человеческом понимании,
                // т.е. 1 соответствует первому элементу массива) в массиве страниц документа originalData.pages.
                // Поэтому страницы из массива выбрасываются по положению страницы в массиве, т.е. по индексу.
                pages = initialPages.filter((page: PageDTO, index: number): boolean => index < props.page);
            }
            else {

                // При глобальном режиме просмотра props.page соответствует номеру страницы в рамках всех страниц
                // в задаче на распознавание. Поэтому страницы из массива выбрасываются согласно их номеру в рамках
                // всех страниц задачи на распознавание.
                pages = initialPages.filter((page: PageDTO): boolean => page.number <= props.page);
            }

            if (initialPages.length !== pages.length) {

                const originalData: OperatorDocumentDto = {
                    ...state.queuedDocument.originalData,
                    ...props.processedData,
                    pages: pages,
                };

                result = {
                    ...state,
                    localLoading: true,
                    queuedDocument: {
                        ...state.queuedDocument,
                        originalData,
                    },
                };
            }

            break;
        }

        // На событие требования изменения флага видимости компоненты для просмотра обрабатываемых страниц
        case OperatorActionType.CHANGE_PAGE_VIEWER_VISIBILITY: {

            result = {
                ...state,
                pageViewerVisible: action.props,
            };
            break;
        }

        // На событие успешного входа в систему
        case LoginActionType.SIGN_IN_SUCCEEDED: {

            result = initialState;
        }
    }

    return result;
}

/**
 * Преобразует и возвращает состояние документа из очереди после получение его с сервера согласно логике,
 * которая нужна на клиентской стороне.
 *
 * @param serverSideQueuedDocument Данные документа из очереди с сервера.
 */
function getClientSideQueuedDocument(serverSideQueuedDocument: QueuedDocument): QueuedDocument {

    let clientSideQueuedDocument: QueuedDocument = null;

    if (serverSideQueuedDocument) {

        clientSideQueuedDocument = {
            ...serverSideQueuedDocument,
        };

        // Если документ приходит с уже обработанным состоянием, это значит, что документ уже был обработан оператором
        // новичком, и требуется проверка опытного оператора.
        if (clientSideQueuedDocument.processedData) {

            clientSideQueuedDocument = {
                ...clientSideQueuedDocument,
                originalData: {
                    ...clientSideQueuedDocument.processedData,
                    // Проблемы затираем, чтобы они не отображались никак.
                    parsingProblems: [],
                },
                processedData: null,
                newbieData: !!clientSideQueuedDocument.newbie ? clientSideQueuedDocument.processedData : null,
                trulyOriginalData: clientSideQueuedDocument.originalData,
                isNewbieData: !!clientSideQueuedDocument.newbie,
            };
        }

        // Если есть промежуточное состояние обработки, то берём его.
        if (clientSideQueuedDocument.intermediateData) {

            clientSideQueuedDocument = {
                ...clientSideQueuedDocument,
                originalData: clientSideQueuedDocument.intermediateData,
            };
        }
    }

    return clientSideQueuedDocument;
}
