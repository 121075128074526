import { ApiResponse } from "../../../common/models";
import { Company } from "../../../common/models";

/**
 * Состояние по созданию/обновлению компании.
 */
export class CompanySubmissionState {

    // Данные компании.
    company: Company;

    // Данные успешно загружены?
    loaded: boolean = false;

    // Данные загружаются?
    loading: boolean = false;

    // Загрузка завершилась ошибкой?
    failed: boolean = false;

    // Информация об ошибке.
    errorData: ApiResponse = null;
}