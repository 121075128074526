import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { select } from '@ngrx/store';
import { routeStateSelector } from "../../root/store/selectors"; /* circular dependency break */
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "@angular/common/http";
/**
 * Сервис с логикой для работы с пользователем.
 */
var UserService = /** @class */ (function () {
    //endregion
    //region Ctor
    function UserService(store, http) {
        var _this = this;
        this._http = http;
        // Следим за состоянием URL'а.
        store
            .pipe(select(routeStateSelector))
            .subscribe(function (routeState) { return _this._routeState = routeState; });
    }
    //endregion
    //region Public
    /**
     * Получение данных о текущем пользователе.
     */
    UserService.prototype.getCurrentUser = function () {
        return this._http
            .get('/api/v1/currentUser')
            .pipe(map(function (response) { return response.user; }), catchError(function (response) {
            // 401 Unauthorized ошибка воспринимается нормально, как отсутствие пользователя (user == null).
            // Другие ошибки пробрасываются дальше.
            if (response.status !== 401) {
                return throwError(response.error);
            }
            return of(null);
        }));
    };
    /**
     * Выполнение выхода из системы.
     *
     * @return Успех выполнения операции.
     */
    UserService.prototype.logout = function () {
        return this._http
            .post("/api/v1/logout", null, { withCredentials: true })
            .pipe(map(function () { return true; }), catchError(function (response) { return throwError(response.error); }));
    };
    /**
     * Возвращает имя пользователя с сокращённым именем и отчеством.
     *
     * Пример: Фамилия И.О.
     *
     * @param user Пользователь.
     *
     * @return Имя пользователя с сокращённым именем и отчеством.
     */
    UserService.prototype.getUserName = function (user) {
        var userName = "";
        if (user) {
            if (user.surname) {
                userName += user.surname;
            }
            if (user.name) {
                userName += " " + user.name.substr(0, 1).toUpperCase() + ".";
                if (user.patronymic) {
                    userName += user.patronymic.substr(0, 1).toUpperCase() + ".";
                }
            }
        }
        return userName;
    };
    /**
     * Возвращает имя пользователя и email с сокращённым именем и отчеством.
     *
     * Если имени нет, то вернёт только email.
     *
     * Пример: Фамилия И.О. | email@domen.ru
     *
     * @param user Пользователь.
     *
     * @return Имя пользователя и email с сокращённым именем и отчеством.
     */
    UserService.prototype.getUserNameWithEmail = function (user) {
        var userName = this.getUserName(user);
        if (user) {
            if (userName.length > 0 && user) {
                userName = userName + " | " + user.email;
            }
            else {
                userName = user.email;
            }
        }
        return userName;
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.Store), i0.inject(i2.HttpClient)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
