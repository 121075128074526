import { Action } from "@ngrx/store";
import { ApiResponsePayloadAction } from "rootStore";
import { ApiResponse } from "src/app/common/models";
import { ComplaintOnDocumentRequest } from "src/app/root/models/complaint-on-document-request.model";

/**
 * Типы событий, связанные с жалобой оператора на документ.
 */
export enum ComplaintOnDocumentActionType {
    COMPLAINT_ON_DOCUMENT_INIT = "[Complaint on a document] Init",
    COMPLAINT_ON_DOCUMENT_REQUEST = "[Complaint on a document] Complaint",
    COMPLAINT_ON_DOCUMENT_SUCCESS = "[Complaint on a document] Complaint Success",
    COMPLAINT_ON_DOCUMENT_FAIL = "[Complaint on a document] Complaint Fail",
}

/**
 * Событие инициализации начального состояния запроса жалобы.
 */
export class ComplaintOnDocumentInitAction implements Action {
    readonly type = ComplaintOnDocumentActionType.COMPLAINT_ON_DOCUMENT_INIT;
}

/**
 * Событие с требованием пожаловаться на документ.
 */
export class ComplaintOnDocumentRequestAction implements Action {
    readonly type = ComplaintOnDocumentActionType.COMPLAINT_ON_DOCUMENT_REQUEST;
    constructor(public payload: ComplaintOnDocumentRequest) { }
}

/**
 * Событие успешной жалобы на документ.
 */
export class ComplaintOnDocumentSuccessAction implements Action {
    readonly type = ComplaintOnDocumentActionType.COMPLAINT_ON_DOCUMENT_SUCCESS;
}

/**
 * Событие неудачной жалобы на документ.
 */
export class ComplaintOnDocumentFailAction implements ApiResponsePayloadAction {
    readonly type = ComplaintOnDocumentActionType.COMPLAINT_ON_DOCUMENT_FAIL;
    constructor(public payload: ApiResponse) { }
}

/**
 * Тип, объединяющий все события, связанные с жалобой оператора на документ.
 */
export type ComplaintOnDocumentAction =
    | ComplaintOnDocumentInitAction
    | ComplaintOnDocumentRequestAction
    | ComplaintOnDocumentSuccessAction
    | ComplaintOnDocumentFailAction
    ;
