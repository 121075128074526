var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { map } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { Actions } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { ofType } from "@ngrx/effects";
import { RouterActionType } from "../actions"; /* circular dependency break */
/**
 * Side-эффекты на события, связанные с изменением URL'а.
 */
var RouterEffects = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор эффектов на события, связанные с изменением URL'a.
     *
     * @param _actions$ Поток событий, происходящих в системе.
     * @param location Сервис для работы с URL.
     * @param router Сервис для работы с навигацией по приложению.
     */
    function RouterEffects(_actions$, location, router) {
        var _this = this;
        this._actions$ = _actions$;
        //endregion
        //region Public
        /**
         * Обработка события перехода по заданному URL'у.
         */
        this.navigate$ = this._actions$
            .pipe(ofType(RouterActionType.GO), map(function (action) { return action.payload; }), tap(function (_a) {
            var path = _a.path, queryParams = _a.query, extras = _a.extras;
            _this._router.navigate(path, __assign({ queryParams: queryParams }, extras));
        }));
        /**
         * Обработка события возврата назад по истории URL'ов.
         */
        this.navigateBack$ = this._actions$
            .pipe(ofType(RouterActionType.BACK), tap(function () { return _this._location.back(); }));
        /**
         * Обработка события перехода вперёд по истории URL'ов.
         */
        this.navigateForward$ = this._actions$
            .pipe(ofType(RouterActionType.FORWARD), tap(function () { return _this._location.forward(); }));
        this._router = router;
        this._location = location;
    }
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], RouterEffects.prototype, "navigate$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], RouterEffects.prototype, "navigateBack$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], RouterEffects.prototype, "navigateForward$", void 0);
    return RouterEffects;
}());
export { RouterEffects };
