var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { environment } from "src/environments/environment";
/**
 * Утилиты для работы с URL.
 */
var UrlUtils = /** @class */ (function () {
    function UrlUtils() {
    }
    //endregion
    //region Public
    //region Commons
    /**
     * Парсит заданный URL и возвращает его в форме, пригодной для использования для события с требованием выполнить
     * переход на другую страницу.
     *
     * @param url URL для парсинга.
     *
     * @return URL в форме, пригодной для использования для события с требованием выполнить переход на другую
     * страницу.
     */
    UrlUtils.parse = function (url) {
        var _a, _b, _c;
        var path;
        var queryParams;
        var questionMarkIndex = url.indexOf('?');
        if (questionMarkIndex === -1) {
            path = [url];
        }
        else {
            path = [url.substring(0, questionMarkIndex)];
            var queryString = url.substring(questionMarkIndex + 1);
            if (queryString) {
                queryParams = {};
                var keyValues = queryString.split('&')
                    .map(function (keyValue) { return keyValue.split('='); });
                for (var _i = 0, keyValues_1 = keyValues; _i < keyValues_1.length; _i++) {
                    var keyValue = keyValues_1[_i];
                    if (queryParams[keyValue[0]]) {
                        queryParams = __assign({}, queryParams, (_a = {}, _a[keyValue[0]] = queryParams[keyValue[0]].concat([keyValue[1]]), _a));
                    }
                    else {
                        queryParams = __assign({}, queryParams, (_b = {}, _b[keyValue[0]] = [keyValue[1]], _b));
                    }
                }
                for (var key in queryParams) {
                    if (queryParams[key].length === 1) {
                        queryParams = __assign({}, queryParams, (_c = {}, _c[key] = queryParams[key][0], _c));
                    }
                }
            }
        }
        return {
            path: path,
            queryParams: queryParams,
        };
    };
    //endregion
    //region Auth
    //region API
    /**
     * Возвращает URL API метода версии 1 для выполнения входа в систему.
     *
     * @return URL API метода версии 1 для выполнения входа в систему.
     */
    UrlUtils.loginApiUrl = function () {
        return UrlUtils.API_V1 + "/" + UrlUtils.loginPagePath();
    };
    /**
     * Возвращает URL API метода версии 1 для выполнения выхода из системы.
     *
     * @return URL API метода версии 1 для выполнения выхода из систему.
     */
    UrlUtils.logoutApiUrl = function () {
        return UrlUtils.API_V1 + "/logout";
    };
    //endregion
    //region UI
    /**
     * Возвращает URL страницы рабочего пространства оператора в виде пригодном для использования в route guard'е.
     *
     * @return URL страницы рабочего пространства оператора в виде пригодном для использования в в route guard'е
     */
    UrlUtils.operatorPagePath = function () {
        return "operator";
    };
    /**
     * Возвращает URL страницы со входом в систему в виде пригодном для использования в routing модуле.
     *
     * @return URL страницы со входом в систему в виде пригодном для использования в routing модуле.
     */
    UrlUtils.loginPagePath = function () {
        return "login";
    };
    /**
     * Возвращает URL страницы входа в систему в виде массива пригодном для использования в качестве route'а в дириктиве
     * в шаблоне.
     *
     * @return URL страницы входа в систему в виде массива пригодном для использования в качестве route'а в дириктиве
     * в шаблоне.
     */
    UrlUtils.loginPageRoute = function () {
        return [UrlUtils.loginPagePath()];
    };
    /**
     * Возвращает URL страницы входа в систему c параметром для перенаправления после успешного входа в систему.
     *
     * @param redirectTo URL, куда нужно вернуть пользователя после выполнения входа в систему.
     *
     * @return URL страницы входа в систему c параметром для перенаправления после успешного входа в систему.
     */
    UrlUtils.loginPageUrl = function (redirectTo) {
        if (redirectTo === void 0) { redirectTo = null; }
        var result = "/" + UrlUtils.loginPagePath();
        if (!!redirectTo) {
            redirectTo = encodeURIComponent(redirectTo);
            result = result + "?redirectTo=" + redirectTo;
        }
        return result;
    };
    //endregion
    //endregion
    //region WebSocket
    /**
     * Возвращает URL для доступа к веб сокету.
     */
    UrlUtils.webSocketUrl = function () {
        return "" + environment.operatorServerUrl.replace("http", "ws") + UrlUtils.API_PREFIX + "/socket/operator";
    };
    //endregion
    //region Something goes wrong
    //region UI
    /**
     * Возвращает URL страницы c информацией о том, что что-то пошло не так в виде пригодном для использования в
     * routing модуле.
     *
     * @return URL страницы c информацией о том, что что-то пошло не так в виде пригодном для использования в
     * routing модуле.
     */
    UrlUtils.somethingGoesWrongPagePath = function () {
        return "something-goes-wrong";
    };
    //endregion
    //endregion
    //region Version
    //region UI
    /**
     * Возвращает URL страницы с информацией о текущей версии системы в виде пригодном для использования в
     * routing модуле.
     *
     * @return URL страницы с информацией о текущей версии системы в виде пригодном для использования в
     * routing модуле.
     */
    UrlUtils.versionPagePath = function () {
        return "version";
    };
    //region Constants
    /**
     * Начало URL'ов API методов.
     */
    UrlUtils.API_PREFIX = "/api";
    /**
     * Начало URL'ов API методов версии 1.
     */
    UrlUtils.API_V1 = UrlUtils.API_PREFIX + "/v1";
    return UrlUtils;
}());
export { UrlUtils };
