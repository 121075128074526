import { Injectable } from "@angular/core";
import { MatDialogRef } from "@angular/material";
import { MatDialog } from "@angular/material";
import { SimpleDlgWithIframeComponent } from "src/app/common/components/simple-dlg-with-iframe/simple-dlg-with-iframe.component";
import { SimpleDlgWithIframeData } from "src/app/common/models/simple-dlg-with-iframe-data";
import { SimpleAlertDlgComponent } from "../components/simple-alert-dlg/simple-alert-dlg.component";
import { SimplePromptDlgComponent } from "../components/simple-prompt-dlg/simple-prompt-dlg.component";

import { SimpleDlgData } from "../models/simple-dlg-data";

/**
 * Сервис для работы с диалогами.
 */
@Injectable({
    providedIn: 'root'
})
export class DlgService {
    //region Ctor

    constructor(
        private _dialog: MatDialog
    ) {
    }

    //endregion
    //region Public

    /**
     * Открывает диалог с заданным текстом.
     *
     * @param data I18n-ключ текста для отображения в диалоге.
     */
    openSimpleDlg(data: SimpleDlgData): MatDialogRef<SimpleAlertDlgComponent, any> {

        return this._dialog.open(SimpleAlertDlgComponent, {
            data,
            autoFocus: false,
            disableClose: data.disableClose,
            panelClass: ['operator-dlg', 'operator-simple-dlg']
        });
    }

    /**
     * Открывает диалог с iframe.
     *
     * @param data Данные для отображения в диалоге.
     */
    openSimpleDlgWithIFrame(data: SimpleDlgWithIframeData): MatDialogRef<SimpleDlgWithIframeComponent, any> {

        return this._dialog.open(
            SimpleDlgWithIframeComponent,
            {
                data,
                autoFocus: false,
                disableClose: data.disableClose,
                width: "80%",
                height: "100%",
                minWidth: "80%",
                minHeight: "100%",
            }
        );
    }

    /**
     * Открывает диалог с заданным текстом и пользовательским вводом.
     *
     * @param data I18n-ключ текста для отображения в диалоге.
     */
    openSimplePromptDialog(data: SimpleDlgData): MatDialogRef<SimplePromptDlgComponent, any> {

        return this._dialog.open(SimplePromptDlgComponent, {
            data,
            autoFocus: true,
            disableClose: data.disableClose,
            panelClass: ['operator-dlg', 'operator-simple-dlg']
        });
    }

    //endregion
}
