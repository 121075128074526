import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { select } from '@ngrx/store';
import { RootState } from "../../root/store/states/root.state"; /* circular dependency break */
import { routeStateSelector } from "../../root/store/selectors"; /* circular dependency break */
import { RouteState } from '../../root/store/reducers/router.reducer';
import { ApiResponse } from '../models';
import { CurrentUserInfo } from '../models';
import { User } from '../models';
import { CurrentUserResponse } from '../models';

/**
 * Сервис с логикой для работы с пользователем.
 */
@Injectable({
    providedIn: 'root'
})
export class UserService {
    //region Private

    /**
     * HTTP-клиент.
     *
     * @private
     */
    private readonly _http: HttpClient;

    /**
     * Состояние URL'а.
     *
     * @private
     */
    private _routeState: RouteState;

    //endregion
    //region Ctor

    constructor(store: Store<RootState>, http: HttpClient) {

        this._http = http;

        // Следим за состоянием URL'а.
        store
            .pipe(
                select(routeStateSelector)
            )
            .subscribe((routeState: RouteState) => this._routeState = routeState);
    }

    //endregion
    //region Public

    /**
     * Получение данных о текущем пользователе.
     */
    getCurrentUser(): Observable<CurrentUserInfo> {

        return this._http
            .get<CurrentUserResponse>('/api/v1/currentUser')
            .pipe(
                map(response => response.user),
                catchError(
                    (response: HttpErrorResponse): Observable<CurrentUserInfo> => {
                        
                        // 401 Unauthorized ошибка воспринимается нормально, как отсутствие пользователя (user == null).
                        // Другие ошибки пробрасываются дальше.
                        if (response.status !== 401) {

                            return throwError(response.error as ApiResponse);
                        }

                        return of(null as CurrentUserInfo);
                    }
                )
            );
    }

    /**
     * Выполнение выхода из системы.
     *
     * @return Успех выполнения операции.
     */
    logout(): Observable<boolean> {

        return this._http
            .post(`/api/v1/logout`, null, { withCredentials: true })
            .pipe(
                map(() => true),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse)),
            );
    }

    /**
     * Возвращает имя пользователя с сокращённым именем и отчеством.
     *
     * Пример: Фамилия И.О.
     *
     * @param user Пользователь.
     *
     * @return Имя пользователя с сокращённым именем и отчеством.
     */
    getUserName(user: User): string {

        let userName = "";

        if (user) {

            if (user.surname) {

                userName += user.surname;
            }

            if (user.name) {

                userName += " " + user.name.substr(0, 1).toUpperCase() + ".";

                if (user.patronymic) {

                    userName += user.patronymic.substr(0, 1).toUpperCase() + ".";
                }
            }
        }

        return userName;
    }

    /**
     * Возвращает имя пользователя и email с сокращённым именем и отчеством.
     *
     * Если имени нет, то вернёт только email.
     *
     * Пример: Фамилия И.О. | email@domen.ru
     *
     * @param user Пользователь.
     *
     * @return Имя пользователя и email с сокращённым именем и отчеством.
     */
    getUserNameWithEmail(user: User): string {

        let userName: string = this.getUserName(user);

        if (user) {

            if (userName.length > 0 && user) {

                userName = userName + " | " + user.email;
            }
            else {

                userName = user.email;
            }
        }

        return userName;
    }

    //endregion
}
