import { HttpClient } from "@angular/common/http";
import { throwError } from "rxjs";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { ErrorUtils } from "src/app/common/utils/error.utils";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
/**
 * Сервис для работы с документами.
 */
var DocumentService = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор сервиса для работы с документами.
     *
     * @param httpClient HTTP клиент.
     */
    function DocumentService(httpClient) {
        this._httpClient = httpClient;
    }
    //endregion
    //region Public
    /**
     * Отправляет запрос жалобы оператора на документ.
     *
     * @param request Информация запроса.
     *
     * @return Успех выполнения запроса.
     */
    DocumentService.prototype.complaint = function (request) {
        return this._httpClient
            .post("/api/v1/documents/" + request.documentId + "/complaint", request.complaint)
            .pipe(map(function (response) { return response.result; }), catchError(function (response) {
            return throwError(ErrorUtils.from(response));
        }));
    };
    DocumentService.ngInjectableDef = i0.defineInjectable({ factory: function DocumentService_Factory() { return new DocumentService(i0.inject(i1.HttpClient)); }, token: DocumentService, providedIn: "root" });
    return DocumentService;
}());
export { DocumentService };
