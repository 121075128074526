import { HttpHeaders } from "@angular/common/http";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { map } from "rxjs/operators";
import { shareReplay } from "rxjs/operators";
import { ObjectUtils } from "src/app/common/utils/object.utils";
import { environment } from "src/environments/environment";
import { OperatorEnvironment } from "src/environments/operator-environment.model";

/**
 * Сервис по работе с конфигурациями окружения приложения.
 */
@Injectable({ providedIn: "root" })
export class ConfigurationService {
    //region Constants

    /**
     * URL конфигурации окружения приложения.
     */
    private static readonly CONFIG_URL = "assets/config/config.json";

    //endregion
    //region Fields

    /**
     * Конфигурация окружения приложения.
     */
    private configuration$: Observable<OperatorEnvironment>;

    /**
     * HTTP-клиент.
     */
    private _http: HttpClient;

    //endregion
    //region Ctor

    /**
     * Конструктор сервиса по работе с конфигурациями окружения приложения.
     *
     * @param http HTTP-клиент.
     */
    constructor(http: HttpClient) {

        this._http = http;
    }

    //endregion
    //region Public

    /**
     * Загружает конфигурацию окружения приложения.
     *
     * @return Конфигурация окружения приложения.
     */
    public loadConfigurations(): Observable<OperatorEnvironment> {

        if (!this.configuration$) {

            const headers = new HttpHeaders({ "Cache-Control": "no-cache" });

            this.configuration$ = this._http.get<Partial<OperatorEnvironment>>(
                ConfigurationService.CONFIG_URL,
                { headers: headers },
            )
                .pipe(
                    catchError((error: any): Observable<Partial<OperatorEnvironment>> => {

                        if (!environment.production) {

                            console.log("Can't get configuration", error);
                        }

                        return of({});
                    }),
                    map((config: Partial<OperatorEnvironment>): OperatorEnvironment => ({ ...environment, ...config })),
                    map((conf: OperatorEnvironment): Readonly<OperatorEnvironment>  => ObjectUtils.freezeInDeep(conf)),
                    tap((frozenConfig: Readonly<OperatorEnvironment>): void => {

                        Object.assign(environment, frozenConfig);
                        ObjectUtils.freezeInDeep(environment, false);
                    }),
                    shareReplay(1),
                );
        }

        return this.configuration$;
    }

    //endregion
}
