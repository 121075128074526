var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { ofType } from "@ngrx/effects";
import { map } from "rxjs/operators";
import { UrlUtils } from "src/app/common/utils/url.utils";
import { serverSideErrors } from "src/app/root/store/actions";
import { RouterGoAction } from "src/app/root/store/actions/router.action";
import { ServerSideErrorActionType } from "src/app/root/store/actions/server-side-error.action";
import { ServerSideErrorOccurredAction } from "src/app/root/store/actions/server-side-error.action";
/**
 * Side-эффекты, связанные с ошибками, произошедшими на сервере, которые ведут на отдельную страницу об ошибке.
 *
 * Эти ошибки явно показывают, что продолжать работу невозможно, при этом пользователь ещё ничего не сделал,
 * и показывать их через всплывашку не имеет смысла.
 */
var ServerSideErrorsEffects = /** @class */ (function () {
    //region Ctor
    /**
     * Конструктор эффектов, связанных с ошибками, произошедшими на сервере, которые ведут на отдельную страницу об
     * ошибке.
     *
     * @param _actions$ Поток событий, происходящих в системе.
     */
    function ServerSideErrorsEffects(_actions$) {
        this._actions$ = _actions$;
        //endregion
        //region Public
        /**
         * Обработка всех событий в модуле, сигнализирующих ошибку на сервере, и превращение их в единое событие о
         * произошедшей ошибке на сервере, которое вызовет обновление данных об ошибке в состоянии приложения и
         * приведёт к переходу на странцу об ошибке.
         */
        this.serverSideErrors$ = this._actions$
            .pipe(ofType.apply(void 0, serverSideErrors), map(function (action) { return action.payload; }), map(function (response) { return new ServerSideErrorOccurredAction(response); }));
        /**
         * Обработка события, что произошла ошибка на сервере. Переход на страницу об ошибке.
         */
        this.goToErrorPage$ = this._actions$
            .pipe(ofType(ServerSideErrorActionType.OCCURRED), 
        // TODO Хардкод URL
        map(function () { return new RouterGoAction({ path: [UrlUtils.somethingGoesWrongPagePath()] }); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], ServerSideErrorsEffects.prototype, "serverSideErrors$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], ServerSideErrorsEffects.prototype, "goToErrorPage$", void 0);
    return ServerSideErrorsEffects;
}());
export { ServerSideErrorsEffects };
