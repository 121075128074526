import { Injectable } from "@angular/core";
import { Action } from "@ngrx/store";
import { Store } from "@ngrx/store";
import { select } from "@ngrx/store";

import { Observable } from "rxjs";
import { of } from "rxjs";
import { concatMap } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { map } from "rxjs/operators";
import { withLatestFrom } from "rxjs/operators";
import { OperatorFinishFailNeedRetryAction } from "src/app/operator/store/actions/operator.action";

import { RootState } from "src/app/root/store/states/root.state";
import { loginStateSelector } from "../store/reducers";
import { ApiResponse } from "../../common/models";
import { SignOutAction } from "src/app/root/store/actions/login.action";

/**
 * Сервис с общей логикой обработки ошибочных ответов от API.
 */
@Injectable({
    providedIn: "root"
})
export class ErrorHandlerService {
    //region Fields

    /**
     * Состояние системы.
     */
    private readonly _store: Store<RootState>;

    //endregion
    //region Ctor

    /**
     * Конструктор сервиса с общей логикой обработки ошибочных ответов от API.
     *
     * @param store Сервис для управления и доступа к состоянию приложения.
     */
    constructor(store: Store<RootState>) {

        this._store = store;
    }

    //endregion
    //region Public

    /**
     * Выполняет корректную обработку ошибочного ответа от API.
     *
     * Проверяет, что если код ответа от API соответствует 401 (т.е. нет аутентификационной куки), то проверяет
     * состояние входа в систему. Если к этому моменту выход из системы уже был выполнен, то данная ошибка будет
     * проигнорирована за счёт фильтрации. Если же пользователь не выходил из системы, но ответ 401, то вместо
     * предусмотренного события на ошибку будет брошено событие, что нужно сделать выход из системы, т.к. сессия
     * истекла.
     * Если мы ловим специфическую ошибку "50004.50004", то эмитим экшн необходимости попытки повторной отправки
     * документа.
     *
     * @param error Ответ от API.
     * @param errorAction Событие, которое предусмотрено на ошибочный ответ от API.
     *
     * @return Событие, в ответ на ошибочный ответ от API.
     */
    handle(error: ApiResponse, errorAction: Action = null): Observable<Action> {

        return of(null)
            .pipe(
                concatMap((_) => of(_)
                    .pipe(
                        withLatestFrom(this._store.pipe(select(loginStateSelector))),
                    )
                ),
                filter(([_, loginState]): boolean => {

                    return error.code !== 401 || loginState.loggedIn;
                }),
                map(([_, loginState]): Action => {

                    if (error.code === 401) {

                        return new SignOutAction({ expired: true });
                    }
                    if (error.errorCode === "50004.50004") {

                        return new OperatorFinishFailNeedRetryAction();
                    }
                    else {

                        return errorAction;
                    }
                }),
                filter((action: Action): boolean => !!action),
            );
    }

    //endregion
}
