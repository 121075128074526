import { HostListener } from "@angular/core";
import { Inject } from "@angular/core";
import { Component } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { OnDestroy } from "@angular/core";
import { OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FormBuilder } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialogRef } from "@angular/material/dialog";
import { select } from "@ngrx/store";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { map } from "rxjs/operators";
import { ApiResponse } from "src/app/common/models/api-response.model";
import { ComplaintProblemPlace } from "src/app/root/models/complaint-problem-place.enum";
import { DocumentComplaint } from "src/app/root/models/document-complaint.model";
import { ComplaintOnDocumentInitAction } from "src/app/root/store/actions/complaint-on-documnet-dlg.action";
import { ComplaintOnDocumentRequestAction } from "src/app/root/store/actions/complaint-on-documnet-dlg.action";
import { ComplaintOnDocumentDlgState } from "src/app/root/store/reducers/complaint-on-document-dlg/complaint-on-document-dlg.state";
import { complaintOnDocumentDlgStateSelector } from "src/app/root/store/reducers/index";
import { RootState } from "src/app/root/store/states/root.state";

/**
 * Компонента диалога жалобы оператора.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "complaint-on-document-dlg",
    styleUrls: ["complaint-on-document-dlg.component.scss"],
    templateUrl: "./complaint-on-document-dlg.component.html"
})
export class ComplaintOnDocumentDlgComponent implements OnInit, OnDestroy {
    //region Fields

    //region Public

    /**
     * Группа формы диалога.
     */
    form: FormGroup;

    /**
     * Список мест ошибок для жалобы.
     */
    readonly complaintProblemPlaces: ComplaintProblemPlace[] = ComplaintProblemPlace.values();

    /**
     * Диалог находится в состоянии загрузки?
     */
    loading$: Observable<boolean>;

    /**
     * Ошибка диалога.
     */
    error$: Observable<ApiResponse>;

    /**
     * Выделено ли хоть одно место проблемы?
     */
    anyPlaceSelected$: Observable<boolean>;

    //endregion
    //region Private

    /**
     * Хранилище.
     */
    private readonly _store: Store<RootState>;

    /**
     * Построитель форм.
     */
    private readonly _formBuilder: FormBuilder;

    /**
     * Ссылка на обертку диалога.
     */
    private readonly _dialogRef: MatDialogRef<ComplaintOnDocumentDlgComponent>;

    /**
     * ID документа, на который жалуются.
     */
    private readonly _documentId: string;

    /**
     * Подписка на удачное завершение запроса.
     */
    private _subscription: Subscription;

    //endregion

    //endregion
    //region Ctor

    /**
     * Конструктор диалога жалобы оператора.
     *
     * @param store Хранилище.
     * @param formBuilder Построитель форм.
     * @param dialogRef Ссылка на обертку диалога.
     * @param data ID документа, на который жалуются.
     */
    constructor(
        store: Store<RootState>,
        formBuilder: FormBuilder,
        dialogRef: MatDialogRef<ComplaintOnDocumentDlgComponent>,
        @Inject(MAT_DIALOG_DATA) data: { documentId: string },
    ) {
        this._store = store;
        this._formBuilder = formBuilder;
        this._dialogRef = dialogRef;
        this._documentId = data.documentId;
    }

    //endregion
    //region Hooks

    /**
     * Выполняет инициализирующую логику компонента.
     */
    ngOnInit(): void {

        this._store.dispatch(new ComplaintOnDocumentInitAction());

        const inner: any = {};
        ComplaintProblemPlace.values()
            .forEach((problemPlace: ComplaintProblemPlace) => inner[problemPlace.key] = false);
        inner.comment = null;
        this.form = this._formBuilder.group(inner);

        this.loading$ = this._store
            .pipe(
                select(complaintOnDocumentDlgStateSelector),
                map((state: ComplaintOnDocumentDlgState) => state.loading),
            );

        this.error$ = this._store
            .pipe(
                select(complaintOnDocumentDlgStateSelector),
                map((state: ComplaintOnDocumentDlgState): ApiResponse => state.error),
            );

        this._subscription = this._store
            .pipe(
               select(complaintOnDocumentDlgStateSelector),
               map((state: ComplaintOnDocumentDlgState): boolean => state.success),
               filter(Boolean),
            )
            .subscribe(() => this._dialogRef.close());

        this.anyPlaceSelected$ = this.form.valueChanges
            .pipe(
                map((form: any) => Object.values(form).some((value: any) => value === true)),
            );
    }

    /**
     * Выполняет логику при уничтожении компоненты.
     */
    ngOnDestroy(): void {

        this._subscription.unsubscribe();
    }

    //endregion
    //region Events

    /**
     * Обработчик нажатия на Esc.
     *
     * Закрывает диалог.
     */
    @HostListener("window:keyup.esc")
    onKeyUp(): void {

        this._dialogRef.close();
    }

    /**
     * Обработчик события жалобы оператора.
     */
    complaintHandler(): void {

        const value: any = this.form.value;
        const problemPlaces: string[] = Object.entries(value)
            .filter(([name, _]: [string, any]) => name !== "comment")
            .filter(([_, check]: [string, boolean]) => check)
            .map(([name, _]: [string, any]) => name);

        const request: DocumentComplaint = {
            problemPlaces: problemPlaces,
            comment: value.comment
        };

        this._store.dispatch(
            new ComplaintOnDocumentRequestAction({ documentId: this._documentId, complaint: request })
        );
    }

    //endregion
}
