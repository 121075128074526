import { ApiResponse } from "src/app/common/models/api-response.model";
import { ServerSideErrorAction } from "src/app/root/store/actions/server-side-error.action";
import { ServerSideErrorActionType } from "src/app/root/store/actions/server-side-error.action";

/**
 * Состояние данных об ошибке на сервере.
 */
export interface ServerSideErrorState {

    // Ответ от сервера.
    response: ApiResponse;
}

/**
 * Начальное состояние по данным произошедшей ошибки.
 */
export const initialState: ServerSideErrorState = {

    // Данных нет.
    response: undefined
};

/**
 * Функция, отвечающая за обновление состояния данных об ошибке на сервере в ответ на происходящие в
 * приложении события.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 */
export function serverSideErrorReducer(state = initialState, action: ServerSideErrorAction): ServerSideErrorState {

    switch (action.type) {

        // На событие возникновения ошибки на сервере сохраняем ответ от сервера.
        case ServerSideErrorActionType.OCCURRED: {

            const response = action.payload;
            return {
                ...state,
                response
            };
        }
    }

    return state;
}

/**
 * Возвращает ответ от сервера на произошедшую ошибку на сервере.
 *
 * @param state Состояние данных об ошибке на сервере.
 */
export const getServerSideErrorResponse = (state: ServerSideErrorState) => state.response;
