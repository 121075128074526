import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { UrlTree } from "@angular/router";
import { UrlUtils } from "../../common/utils";

/**
 * Сервис для выполнения каких-либо переходов по URL'ам.
 */
@Injectable({
    providedIn: "root"
})
export class RouteService {
    //region Fields

    /**
     * Сервис для работы с навигацией по приложению.
     */
    private readonly _router: Router;

    //endregion
    //region Ctor

    /**
     * Конструктор сервиса для выполнения каких-либо переходов по URL'ам.
     *
     * @param router Сервис для работы с навигацией по приложению.
     */
    constructor(router: Router) {

        this._router = router;
    }

    //endregion
    //region Public

    /**
     * Возвращает URL для перехода на страницу логина в форме пригодной для использования в route guard'е.
     *
     * @param redirectTo URL, куда нужно вернуть пользователя после выполнения входа в систему.
     *
     * @return URL для перехода на страницу логина в форме, пригодной для использования в route guard'е.
     */
    goToLogin(redirectTo: string): UrlTree {

        return this._router.parseUrl(UrlUtils.loginPageUrl(redirectTo));
    }

    /**
     * Возвращает URL для перехода по указанному URL в форме пригодной для использования в route guard'е.
     *
     * @param url URL для перехода.
     * @return URL для перехода по указанному URL в форме пригодной для использования в route guard'е.
     */
    goTo(url: string): UrlTree {

        return this._router.parseUrl(url);
    }

    //endregion
}
