var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { SpaceCounterpartiesActionType } from '../actions/space-counterparties.action';
/**
 * Начальное состояние списка уникальных контрагентов, привязанных к документам в заданном пространстве документов.
 */
export var initialState = {
    spaceCustomers: undefined,
    spaceCustomersLoading: false,
    spaceCustomersError: null,
    spaceSuppliers: undefined,
    spaceSuppliersLoading: false,
    spaceSuppliersError: null,
};
/**
 * Логика обработчика событий, связанных со списком уникальных контрагентов, привязанных к документам в заданном
 * пространстве документов.
 */
export function spaceCounterpartiesReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var result = state;
    switch (action.type) {
        case SpaceCounterpartiesActionType.SPACE_CUSTOMERS_LOAD: {
            result = __assign({}, state, { spaceCustomersLoading: true, spaceCustomersError: null, spaceCustomers: undefined });
            break;
        }
        case SpaceCounterpartiesActionType.SPACE_CUSTOMERS_LOADED: {
            result = __assign({}, state, { spaceCustomersLoading: false, spaceCustomers: action.spaceCustomers });
            break;
        }
        case SpaceCounterpartiesActionType.SPACE_CUSTOMERS_FAILED: {
            result = __assign({}, state, { spaceCustomersLoading: false, spaceCustomersError: action.error });
            break;
        }
        case SpaceCounterpartiesActionType.SPACE_SUPPLIERS_LOAD: {
            result = __assign({}, state, { spaceSuppliersLoading: true, spaceSuppliersError: null, spaceSuppliers: undefined });
            break;
        }
        case SpaceCounterpartiesActionType.SPACE_SUPPLIERS_LOADED: {
            result = __assign({}, state, { spaceSuppliersLoading: false, spaceSuppliers: action.spaceSuppliers });
            break;
        }
        case SpaceCounterpartiesActionType.SPACE_SUPPLIERS_FAILED: {
            result = __assign({}, state, { spaceSuppliersLoading: false, spaceSuppliersError: action.error });
            break;
        }
    }
    return result;
}
