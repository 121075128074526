import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { Routes } from "@angular/router";
import { DocumentTypesGuard } from "src/app/root/guards/document-types.guard";
import { RootGuard } from "src/app/root/guards/root.guard";
import { EmptyComponent } from "../common/components";
import { NotFoundComponent } from "../common/components";
import { UrlUtils } from "../common/utils";
import { VersionPageComponent } from "./containers";

import { UserGuard } from "./guards";
import { NoUserGuard } from "./guards";

/**
 * Пути корневого модуля.
 */
const ROUTES: Routes = [
    {
        path: "",
        pathMatch: "full",
        canActivate: [ RootGuard ],
        component: EmptyComponent,
    },
    {
        path: "operator",
        canActivateChild: [ UserGuard, DocumentTypesGuard ],
        loadChildren: "../operator/operator.module#OperatorModule",
    },
    {
        path: UrlUtils.loginPagePath(),
        canActivateChild: [ NoUserGuard ],
        loadChildren: "../login/login.module#LoginModule",
    },
    {
        path: UrlUtils.somethingGoesWrongPagePath(),
        loadChildren: "../server-side-error/server-side-error.module#ServerSideErrorModule",
    },
    {
        path: UrlUtils.versionPagePath(),
        canActivateChild: [UserGuard],
        component: VersionPageComponent,
    },
    {
        path: "**",
        canActivate: [UserGuard],
        component: NotFoundComponent,
    }
];

@NgModule({
    imports: [RouterModule.forRoot(ROUTES)],
    exports: [RouterModule],
})
export class RootModuleRouting { }
