import { createSelector } from '@ngrx/store';

import { routerReducerStateSelector } from '../reducers';
import { getRouteState } from '../reducers/router.reducer';

/**
 * Селектор данных текущего URL'а.
 */
export const routeStateSelector = createSelector(routerReducerStateSelector, getRouteState);

/**
 * Селектор текущего URL'а без query части.
 */
export const routePathSelector = createSelector(
    routeStateSelector, 
    (routeState) => routeState ? routeState.path : null
);


/**
 * Селектор query параметров текущего URL.
 */
export const routeQueryParameterSelector = createSelector(
    routeStateSelector,
    (routeState) => routeState ? routeState.queryParams : null
);
