import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";

import { map } from "rxjs/operators";
import { tap } from "rxjs/operators";

import { Actions } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { ofType } from "@ngrx/effects";

import { RouterActionType } from "../actions"; /* circular dependency break */
import { RouterActionPayload } from "../actions"; /* circular dependency break */
import { RouterGoAction } from "../actions";

/**
 * Side-эффекты на события, связанные с изменением URL'а.
 */
@Injectable()
export class RouterEffects {
    //region Fields

    /**
     * Сервис для работы с навигацией по приложению
     */
    private readonly _router: Router;

    /**
     * Сервис для работы с URL.
     */
    private readonly _location: Location;

    //endregion
    //region Ctor

    /**
     * Конструктор эффектов на события, связанные с изменением URL'a.
     *
     * @param _actions$ Поток событий, происходящих в системе.
     * @param location Сервис для работы с URL.
     * @param router Сервис для работы с навигацией по приложению.
     */
    constructor(
        private _actions$: Actions,
        location: Location,
        router: Router,
    ) {
        this._router = router;
        this._location = location;
    }

    //endregion
    //region Public

    /**
     * Обработка события перехода по заданному URL'у.  
     */
    @Effect({ dispatch: false })
    navigate$ = this._actions$
        .pipe(
            ofType(RouterActionType.GO),
            map((action: RouterGoAction): RouterActionPayload => action.payload),
            tap(({ path, query: queryParams, extras }): void => {
                this._router.navigate(path, { queryParams, ...extras });
            }),
        );

    /**
     * Обработка события возврата назад по истории URL'ов.
     */
    @Effect({ dispatch: false })
    navigateBack$ = this._actions$
        .pipe(
            ofType(RouterActionType.BACK),
            tap((): void => this._location.back()),
        );

    /**
     * Обработка события перехода вперёд по истории URL'ов.
     */
    @Effect({ dispatch: false })
    navigateForward$ = this._actions$
        .pipe(
            ofType(RouterActionType.FORWARD),
            tap((): void => this._location.forward()),
        );

    //endregion
}
