import { MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
/**
 * Компонент простого диалога с iFrame.
 */
var SimpleDlgWithIframeComponent = /** @class */ (function () {
    //endregion
    //region Ctor
    function SimpleDlgWithIframeComponent(_dialogRef, data, translateService) {
        this._dialogRef = _dialogRef;
        this._data = data;
        this._translateService = translateService;
        this.headerKey = this._data.headerKey;
        this.headerParams = this._data.headerParams || {};
        this.iframeUrl = this._data.iframeUrl;
        this.loading = this._data.loading;
        this.closeIcon = this._data.closeIcon;
    }
    Object.defineProperty(SimpleDlgWithIframeComponent.prototype, "hasHeader", {
        //endregion
        //region Getters and Setters
        /**
         * У диалога есть заголовок?
         */
        get: function () {
            return (!!this._data.headerKey);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SimpleDlgWithIframeComponent.prototype, "hasOkButton", {
        /**
         * У диалога есть кнопка принятия?
         */
        get: function () {
            return !!(this._data.okCallback);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SimpleDlgWithIframeComponent.prototype, "okBtnKey", {
        /**
         * I18n-ключ текста кнопки принятия диалога.
         */
        get: function () {
            return (this._data.okBtnKey || "dialogs.ok");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SimpleDlgWithIframeComponent.prototype, "hasCloseIcon", {
        /**
         * У диалога есть иконка закрытия диалога?
         */
        get: function () {
            return !!(this._data.closeIcon);
        },
        enumerable: true,
        configurable: true
    });
    //endregion
    //region Events
    /**
     * Обработчик клика по кнопке принятия диалога.
     */
    SimpleDlgWithIframeComponent.prototype.okClickHandler = function () {
        if (this._data.okCallback) {
            this._data.okCallback();
        }
        this._dialogRef.close();
    };
    /**
     * Обработчик клика по кнопке закрытия диалога.
     */
    SimpleDlgWithIframeComponent.prototype.closeClickHandler = function () {
        if (this._data.cancelCallback) {
            this._data.cancelCallback();
        }
        this._dialogRef.close();
    };
    return SimpleDlgWithIframeComponent;
}());
export { SimpleDlgWithIframeComponent };
