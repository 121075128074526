import { AmountModifierComponent } from "src/app/common/components/amount-modifier/amount-modifier.component";
import { ButtonWithDropdownMenuComponent } from "src/app/common/components/button-with-dropdown-menu/button-with-dropdown-menu.component";
import { NotificationWithInfoIconComponent } from "src/app/common/components/notification-with-info-icon/notification-with-info-icon.component";
import { SimpleDlgWithIframeComponent } from "src/app/common/components/simple-dlg-with-iframe/simple-dlg-with-iframe.component";
import { CompanyFormComponent } from "src/app/common/components/company-form/company-form.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { ForbiddenErrorDetailsComponent } from "./forbidden-error-details/forbidden-error-details.component";
import { EmptyComponent } from "./empty/empty.component";
import { SingleSelectComponent } from "./single-select/single-select.component";
import { ErrorDetailsComponent } from "./error-details/error-details.component";
import { ServerSideErrorDetailsComponent } from "./server-side-error-details/server-side-error-details.component";
import { ServerSideErrorDetailsDlgComponent } from "./server-side-error-details-dlg/server-side-error-details-dlg.component";
import { SimpleAlertDlgComponent } from "./simple-alert-dlg/simple-alert-dlg.component";
import { SimplePromptDlgComponent } from "./simple-prompt-dlg/simple-prompt-dlg.component";
import { NumberInputComponent } from "./number-input/number-input.component";
import { PagesViewerComponent } from "./pages-viewer/pages-viewer.component";
import { PageNavigationComponent } from "./page-navigation/page-navigation.component";
import { svgIcons } from "./svg";

export { NotFoundComponent } from "./not-found/not-found.component";
export { ForbiddenErrorDetailsComponent } from "./forbidden-error-details/forbidden-error-details.component";
export { EmptyComponent } from "./empty/empty.component";
export { SimpleAlertDlgComponent } from "./simple-alert-dlg/simple-alert-dlg.component";
export { SingleSelectComponent } from "./single-select/single-select.component";
export { PagesViewerComponent } from "./pages-viewer/pages-viewer.component";
export { SimplePromptDlgComponent } from "./simple-prompt-dlg/simple-prompt-dlg.component";
export { CompanyFormComponent }  from "src/app/common/components/company-form/company-form.component";
export { PageNavigationComponent } from "./page-navigation/page-navigation.component";

export const entryComponents = [
    ServerSideErrorDetailsComponent,
    SimpleAlertDlgComponent,
    SimpleDlgWithIframeComponent,
    SimplePromptDlgComponent,
    CompanyFormComponent,
];

export const components = [
    ...entryComponents,
    NotFoundComponent,
    ForbiddenErrorDetailsComponent,
    EmptyComponent,
    SingleSelectComponent,
    ErrorDetailsComponent,
    ServerSideErrorDetailsDlgComponent,
    SimpleAlertDlgComponent,
    SimplePromptDlgComponent,
    NumberInputComponent,
    PagesViewerComponent,
    CompanyFormComponent,
    PageNavigationComponent,
    ButtonWithDropdownMenuComponent,
    NotificationWithInfoIconComponent,
    AmountModifierComponent,
    ...svgIcons,
];
