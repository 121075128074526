import { Input } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { Component } from "@angular/core";
import { HelpDialogDirective } from "src/app/common/directives/help-dialog.directive";

/**
 * Компонент плашки с иконкой информации.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "notification-with-icon",
    templateUrl: "./notification-with-info-icon.component.html",
    styleUrls: ["./notification-with-info-icon.component.scss"],
})
export class NotificationWithInfoIconComponent extends HelpDialogDirective {
    //region Inputs

    /**
     * Текст плашки.
     */
    @Input()
    text: string;

    /**
     * Название иконки.
     */
    @Input()
    iconName: string;

    /**
     * Стиль уведомления с иконкой.
     */
    @Input()
    level: "warn" | "alert" | "info";

    //endregion
}
