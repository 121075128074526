import { Currency } from "src/app/common/models/currency.model";
import { CurrenciesActionType } from "src/app/root/store/actions/currencies.action";
import { CurrenciesAction } from "src/app/root/store/actions/currencies.action";
import { CurrenciesState } from "src/app/root/store/states/currencies.state";

/**
 * Начальное состояния списка валют.
 */
const initialState: CurrenciesState = {
    loading: false,
    loaded: false,
    failed: false,
    currencies: [],
};

/**
 * Обработчик событий, связанных с валютами.
 *
 * @param state Состояния списка валют.
 * @param action Событие произошедшее в системе.
 */
export function currenciesReducer(
    state = initialState,
    action: CurrenciesAction,
): CurrenciesState {
    switch (action.type) {

        // Требование загрузить список валют.
        case CurrenciesActionType.LOAD: {

            return {
                ...state,
                loading: true,
                loaded: false,
                failed: false,
            };
        }

        // Уведомление об успешной загрузки списка валют.
        case CurrenciesActionType.LOAD_SUCCESS: {

            return {
                ...state,
                loading: false,
                loaded: true,
                currencies: action.currencies,
            };
        }

        // Уведомление о неудачной попытке загрузки списка валют.
        case CurrenciesActionType.LOAD_FAIL: {

            return {
                ...state,
                loading: false,
                failed: true,
                currencies: [],
            };
        }
    }

    return state;
}
