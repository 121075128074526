import { ChangeDetectionStrategy } from "@angular/core";
import { Component } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { Input } from "@angular/core";
import { OnInit } from "@angular/core";
import { Output } from "@angular/core";
import { ApiResponse } from "../../models/api-response.model";

/**
 * Компонент для отображения деталей об ошибке.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'error-details',
    templateUrl: './error-details.component.html',
    styleUrls: ['./error-details.component.scss'],
})
export class ErrorDetailsComponent implements OnInit {
    //region Inputs

    /**
     * Входящие данные - код ошибки.
     */
    @Input()
    errorCode: string;

    /**
     * Входящие данные - текст ошибки.
     */
    @Input()
    errorText: string;

    /**
     * Входящие данные - скрыть кнопку закрытия деталей ошибки?
     */
    @Input()
    hideCloseBtn: boolean;

    /**
     * Входящие данные - скрыть кнопку перехода на главную страницу?
     */
    @Input()
    hideGoToBtn: boolean;

    /**
     * Входящие данные - ошибки.
     */
    @Input()
    errors: ApiResponse[];

    //endregion
    //region Outputs

    /**
     * Исходящее событие - уведомление о том, что была нажата кнопка перехода на главную страницу.
     */
    @Output()
    goToRoot = new EventEmitter<void>();

    //endregion
    //region Ctor

    constructor() { }

    //endregion
    //region Hoks

    ngOnInit() { }

    //endregion
    //region Events

    /**
     * Обработчик клика по кнопке копироваия данных об ошибках в буфер обмена.
     */
    copyBtnClickHandler() {

        // TODO Сделать какую-нибудь всплывашку, что данные скопированы.
        console.log("TODO: Copy errors details", this.errors);
    }

    /**
     * Обработчик клика по кнопке перехода на главную страницу.
     */
    goToBtnClickHandler() {

        this.goToRoot.emit();
    }

    //endregion
}
