var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from "@ngrx/effects";
import { TranslateService } from "@ngx-translate/core";
import { of } from "rxjs";
import { tap } from "rxjs/operators";
import { catchError, map, switchMap } from "rxjs/operators";
import { DocumentTypesService } from "src/app/common/services/document-types.service";
import { DlgService } from "src/app/common/services";
import { DocumentTypesLoadFailAction } from "src/app/root/store/actions/document-types.action";
import { DocumentTypesLoadSuccessAction } from "src/app/root/store/actions/document-types.action";
import { DocumentTypesActionType } from "src/app/root/store/actions/document-types.action";
/**
 * Side-эффекты на события, связанные с типами документов.
 */
var DocumentTypesEffects = /** @class */ (function () {
    //endregion
    //region Ctor
    function DocumentTypesEffects(actions$, documentTypesService, dlgService, translateService) {
        var _this = this;
        this.actions$ = actions$;
        //endregion
        //region Public
        /**
         * Выполнение загрузки типов документов.
         */
        this.loadDocumentTypes$ = this.actions$
            .pipe(ofType(DocumentTypesActionType.LOAD), switchMap(function () { return _this._documentTypesService.getDocumentTypes()
            .pipe(map(function (documentTypes) { return new DocumentTypesLoadSuccessAction(documentTypes); }), catchError(function (apiResponse) { return of(new DocumentTypesLoadFailAction(apiResponse)); })); }));
        /**
         * Действие при ошибке загрузки типов документов.
         */
        this.loadDocumentTypesError$ = this.actions$
            .pipe(ofType(DocumentTypesActionType.LOAD_FAIL), tap(function (props) {
            return _this._dlgService.openSimpleDlg({
                headerKey: "operator.documentTypes.error.title",
                text: _this._translateService.get("operator.documentTypes.error.text", { message: props.errorMessage, code: props.errorCode }),
                disableClose: true,
                closeBtnKey: "operator.documentTypes.error.reloadButton",
                cancelCallback: function () { return window.location.reload(); },
            });
        }));
        this._documentTypesService = documentTypesService;
        this._dlgService = dlgService;
        this._translateService = translateService;
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DocumentTypesEffects.prototype, "loadDocumentTypes$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], DocumentTypesEffects.prototype, "loadDocumentTypesError$", void 0);
    return DocumentTypesEffects;
}());
export { DocumentTypesEffects };
