import { createSelector } from "@ngrx/store";

import { currentUserStateSelector } from "../reducers";
import { getCurrentUserInfo } from "../reducers/current-user.reducer";
import { getCurrentUserLang } from "../reducers/current-user.reducer";
import { getCurrentUserFailed } from "../reducers/current-user.reducer";
import { getCurrentUserLoaded } from "../reducers/current-user.reducer";
import { getCurrentUserLoading } from "../reducers/current-user.reducer";
import { CurrentUserInfo } from '../../../common/models';

/**
 * Селектор данных текущего пользователя.
 */
export const currentUserInfoSelector = createSelector(currentUserStateSelector, getCurrentUserInfo);


/**
 * Селектор языка текущего пользователя.
 */
export const currentUserLangSelector = createSelector(currentUserInfoSelector, getCurrentUserLang);

/**
 * Селектор прав текущего пользователя в виде объекта.
 */
export const currentUserPermissionsSelector = createSelector(
    currentUserInfoSelector,
    (user: CurrentUserInfo) => user.permissions
        .reduce((p: { [key: string]: boolean }, item: string) => { p[item] = true; return p; }, {})
);

/**
 * Селектор флага успешной загрузки данных текущего пользователя.
 */
export const currentUserLoadedSelector = createSelector(currentUserStateSelector, getCurrentUserLoaded);

/**
 * Селектор флага выполняющейся загрузки данных текущего пользователя.
 */
export const currentUserLoadingSelector = createSelector(currentUserStateSelector, getCurrentUserLoading);

/**
 * Селектор флага была ли ошибка при загрузке данных текущего пользователя.
 */
export const currentUserFailedSelector = createSelector(currentUserStateSelector, getCurrentUserFailed);
