import { ElementRef } from "@angular/core";
import { DlgService } from "src/app/common/services/dlg.service";
/**
 * Директива-декоратор для открытия диалогового окна с заданной по ссылке информацией.
 */
var HelpDialogDirective = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор директивы.
     *
     * @param dlgService Сервис для работы с диалогами.
     * @param element Хост-элемент директивы.
     */
    function HelpDialogDirective(dlgService, element) {
        //region Inputs
        /**
         * Url для генерации iframe.
         */
        this.iframeUrl = null;
        this._dlgService = dlgService;
        this._element = element;
    }
    Object.defineProperty(HelpDialogDirective.prototype, "helpCursor", {
        //endregion
        //region Getters
        /**
         * Присваивает элементу, к которому присвоена директива, стиль курсора.
         */
        get: function () {
            return !!this.iframeUrl;
        },
        enumerable: true,
        configurable: true
    });
    //endregion
    //region Public
    /**
     * Эмитит событие открытия диалога с информацией.
     */
    HelpDialogDirective.prototype.openInfoDialog = function () {
        this._dlgService.openSimpleDlgWithIFrame({
            closeIcon: true,
            iframeUrl: this.iframeUrl,
        });
    };
    //endregion
    //region Events
    /**
     * Обработчик события клика по элементу.
     *
     * Открывает диалоговое окно с заданной iFrame-ссылкой.
     */
    HelpDialogDirective.prototype.clickHandler = function () {
        if (this.iframeUrl) {
            this.openInfoDialog();
        }
    };
    return HelpDialogDirective;
}());
export { HelpDialogDirective };
