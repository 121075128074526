import { MatDialog } from "@angular/material/dialog";
import { Constants } from "src/app/common/models/constants.model";
import { ComplaintOnDocumentDlgComponent } from "src/app/root/containers/complaint-on-document-dlg/complaint-on-document-dlg.component";
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
/**
 * Сервис для работы с диалогом жалобы оператора на документ.
 */
var ComplaintOnDocumentDlgService = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор для работы с диалогом жалобы оператора на документ.
     *
     * @param dialog Сервис для работы с диалогами.
     */
    function ComplaintOnDocumentDlgService(dialog) {
        this._dialog = dialog;
    }
    //endregion
    //region Public
    /**
     * Открывает диалог жалобы оператора на документ.
     *
     * @param documentId ID документа.
     *
     * @return Объект для управления диалогом и компонентом, который находится в диалоге.
     */
    ComplaintOnDocumentDlgService.prototype.open = function (documentId) {
        this._dialog.closeAll();
        return this._dialog.open(ComplaintOnDocumentDlgComponent, {
            panelClass: [Constants.DIALOG_PANEL_CLASS],
            data: { documentId: documentId },
            autoFocus: true,
            disableClose: true,
        });
    };
    ComplaintOnDocumentDlgService.ngInjectableDef = i0.defineInjectable({ factory: function ComplaintOnDocumentDlgService_Factory() { return new ComplaintOnDocumentDlgService(i0.inject(i1.MatDialog)); }, token: ComplaintOnDocumentDlgService, providedIn: "root" });
    return ComplaintOnDocumentDlgService;
}());
export { ComplaintOnDocumentDlgService };
