import { HttpErrorResponse } from "@angular/common/http";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { ApiResponse } from "src/app/common/models/api-response.model";
import { ErrorUtils } from "src/app/common/utils/error.utils";
import { ComplaintOnDocumentRequest } from "src/app/root/models/complaint-on-document-request.model";

/**
 * Сервис для работы с документами.
 */
@Injectable({
    providedIn: "root"
})
export class DocumentService {
    //region Fields

    /**
     * HTTP клиент.
     */
    private readonly _httpClient: HttpClient;

    //endregion
    //region Ctor

    /**
     * Конструктор сервиса для работы с документами.
     *
     * @param httpClient HTTP клиент.
     */
    constructor(httpClient: HttpClient) {

        this._httpClient = httpClient;
    }

    //endregion
    //region Public

    /**
     * Отправляет запрос жалобы оператора на документ.
     *
     * @param request Информация запроса.
     *
     * @return Успех выполнения запроса.
     */
    complaint(request: ComplaintOnDocumentRequest): Observable<boolean> {

        return this._httpClient
            .post<ApiResponse>(`/api/v1/documents/${request.documentId}/complaint`, request.complaint)
            .pipe(
                map((response: ApiResponse): boolean => response.result),
                catchError((response: HttpErrorResponse): Observable<never> =>
                    throwError(ErrorUtils.from(response))
                ),
            );
    }

    //endregion
}
