import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { CanActivate } from "@angular/router";
import { CanActivateChild } from "@angular/router";
import { Router } from "@angular/router";
import { RouterStateSnapshot } from "@angular/router";
import { UrlTree } from "@angular/router";
import { Store } from "@ngrx/store";
import { select } from "@ngrx/store";

import { Observable } from "rxjs";
import { of } from "rxjs";
import { filter } from "rxjs/operators";
import { map } from "rxjs/operators";
import { switchMap } from "rxjs/operators";

import { CurrentUserState } from "../store/reducers/current-user.reducer";
import { LoginState } from "../store/states";
import { StatePair } from "../store/states";
import { RootState } from "src/app/root/store/states/root.state";
import { userAndLoginStatesSelector } from "rootStore";
import { CurrentUserInfo } from "../../common/models";

/**
 * Route guard, который проверяет возможность входа на URL в зависимости от наличия аутентифицированного пользователя.
 *
 * Если текущего пользователя нет, то переход по URL будет возможен, если текущий пользователь есть, то невозможен.
 */
@Injectable({
    providedIn: "root"
})
export class NoUserGuard implements CanActivate, CanActivateChild {
    //region Fields

    /**
     * Сервис для управления и доступа к состоянию приложения.
     */
    private readonly _store: Store<RootState>;

    /**
     * Сервис для работы с навигацией по приложению.
     */
    private readonly _router: Router;

    //endregion
    //region Ctor

    /**
     * Конструктор route guard, который проверяет возможность входа на URL в зависимости от наличия
     * аутентифицированного пользователя.
     *
     * @param store Сервис для управления и доступа к состоянию приложения.
     * @param router Сервис для работы с навигацией по приложению.
     */
    constructor(store: Store<RootState>, router: Router) {

        this._store = store;
        this._router = router;
    }

    //endregion
    //region Public

    /**
     * Проверяет, можно ли осуществлять переход по текущему состоянию навигации и, если нельзя, то перенаправляет на
     * главную страницу.
     *
     * Если текущего пользователя нет, то переход по URL будет возможен, если текущий пользователь есть, то невозможен.
     *
     * @param route Состояние текущего активированного URL'а.
     * @param state Состояние навигации.
     *
     * @return Да, если можно осуществить переход по текущему состоянию навигации и, если нельзя, то перенаправляет на
     * главную страницу.
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {

        return this._getCurrentUserState()
            .pipe(
                map((userState: CurrentUserState): CurrentUserInfo => userState.currentUserInfo),
                switchMap((user: CurrentUserInfo): Observable<boolean | UrlTree> => {

                    if (user) {

                        return of(this._router.parseUrl("/"));
                    }
                    else {

                        return of(true);
                    }
                }),
            );
    }

    /**
     * Проверяет, можно ли осуществлять переход по текущему состоянию навигации и, если нельзя, то перенаправляет на
     * главную страницу.
     *
     * Если текущего пользователя нет, то переход по URL будет возможен, если текущий пользователь есть, то невозможен.
     *
     * @param route Состояние текущего активированного URL'а.
     * @param state Состояние навигации.
     *
     * @return Да, если можно осуществить переход по текущему состоянию навигации и, если нельзя, то перенаправляет на
     * главную страницу.
     */
    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {

        return this.canActivate(route, state);
    }

    //endregion
    //region Private

    /**
     * Возвращает состояние данных текущего аутентифицированного пользователя.
     *
     * Либо дожидается, когда данные текущего аутентифицированного пользователя будут загружены, либо если
     * пользователь выполнил выход из системы.
     *
     * @return Состояние данных текущего аутентифицированного пользователя.
     */
    private _getCurrentUserState(): Observable<CurrentUserState> {

        return this._store
            .pipe(
                select(userAndLoginStatesSelector),
                filter((states: StatePair<CurrentUserState, LoginState>): boolean => {

                    return (states.first.loaded || states.first.failed || !states.second.loggedIn);
                }),
                map((states: StatePair<CurrentUserState, LoginState>): CurrentUserState => states.first),
            );
    }

    //endregion
}
