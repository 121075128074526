import { Action } from "@ngrx/store";
import { ApiResponse } from "src/app/common/models/api-response.model";

/**
 * Типы событий, связанные с ошибками на сервере.
 */
export enum ServerSideErrorActionType {
    OCCURRED = '[Root] Server side error occurred'
}

/**
 * Событие, сигнализирующее, что на сервере произошла ошибка.
 */
export class ServerSideErrorOccurredAction implements Action {
    readonly type = ServerSideErrorActionType.OCCURRED;
    constructor(public payload: ApiResponse) { }
}

/**
 * Тип, объединяющий все события, связанные ошибками на сервере.
 */
export type ServerSideErrorAction = ServerSideErrorOccurredAction;
