var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { DocumentTypesActionType } from "src/app/root/store/actions/document-types.action";
/**
 * Начальное состояние типов документов.
 */
var initialState = {
    /**
     * Типы документов не загружаются.
     */
    loading: false,
    /**
     * Загрузка типов документов ошибкой не завершалась.
     */
    error: null,
    /**
     * Типов документов нет.
     */
    documentTypes: [],
};
/**
 * Обработчик событий, связанных с типами документов.
 *
 * @param state Состояние типов документов.
 * @param action Событие произошедшее в системе.
 */
export function documentTypesReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case DocumentTypesActionType.LOAD: {
            return __assign({}, state, { loading: true, error: null });
        }
        case DocumentTypesActionType.LOAD_SUCCESS: {
            var documentTypes = action.payload;
            return __assign({}, state, { loading: false, documentTypes: documentTypes });
        }
        case DocumentTypesActionType.LOAD_FAIL: {
            return __assign({}, state, { loading: false, error: action.payload, documentTypes: [] });
        }
    }
    return state;
}
