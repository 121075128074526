var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { ofType } from "@ngrx/effects";
import { TranslateService } from "@ngx-translate/core";
import { map } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { LangActionType } from "../actions";
/**
 * Side-эффекты на события, связанные с языком интерфейса.
 */
var LangEffects = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор эффектов на события, связанные с языком интерфейса.
     *
     * @param _actions$ Поток событий, происходящих в системе.
     * @param translateService Сервис для получения текстов на текущем языке по их ключам.
     */
    function LangEffects(_actions$, translateService) {
        var _this = this;
        this._actions$ = _actions$;
        //endregion
        //region Effects
        /**
         * Обработка события изменения языка.
         */
        this.changeLangEffect$ = this._actions$
            .pipe(ofType(LangActionType.CHANGE), map(function (action) { return action.payload; }), tap(function (lang) { return _this._translateService.use(lang); }));
        this._translateService = translateService;
    }
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], LangEffects.prototype, "changeLangEffect$", void 0);
    return LangEffects;
}());
export { LangEffects };
