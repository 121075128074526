import { Store } from "@ngrx/store";
import { select } from "@ngrx/store";
import { of } from "rxjs";
import { concatMap } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { map } from "rxjs/operators";
import { withLatestFrom } from "rxjs/operators";
import { OperatorFinishFailNeedRetryAction } from "src/app/operator/store/actions/operator.action";
import { loginStateSelector } from "../store/reducers";
import { SignOutAction } from "src/app/root/store/actions/login.action";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
/**
 * Сервис с общей логикой обработки ошибочных ответов от API.
 */
var ErrorHandlerService = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор сервиса с общей логикой обработки ошибочных ответов от API.
     *
     * @param store Сервис для управления и доступа к состоянию приложения.
     */
    function ErrorHandlerService(store) {
        this._store = store;
    }
    //endregion
    //region Public
    /**
     * Выполняет корректную обработку ошибочного ответа от API.
     *
     * Проверяет, что если код ответа от API соответствует 401 (т.е. нет аутентификационной куки), то проверяет
     * состояние входа в систему. Если к этому моменту выход из системы уже был выполнен, то данная ошибка будет
     * проигнорирована за счёт фильтрации. Если же пользователь не выходил из системы, но ответ 401, то вместо
     * предусмотренного события на ошибку будет брошено событие, что нужно сделать выход из системы, т.к. сессия
     * истекла.
     * Если мы ловим специфическую ошибку "50004.50004", то эмитим экшн необходимости попытки повторной отправки
     * документа.
     *
     * @param error Ответ от API.
     * @param errorAction Событие, которое предусмотрено на ошибочный ответ от API.
     *
     * @return Событие, в ответ на ошибочный ответ от API.
     */
    ErrorHandlerService.prototype.handle = function (error, errorAction) {
        var _this = this;
        if (errorAction === void 0) { errorAction = null; }
        return of(null)
            .pipe(concatMap(function (_) { return of(_)
            .pipe(withLatestFrom(_this._store.pipe(select(loginStateSelector)))); }), filter(function (_a) {
            var _ = _a[0], loginState = _a[1];
            return error.code !== 401 || loginState.loggedIn;
        }), map(function (_a) {
            var _ = _a[0], loginState = _a[1];
            if (error.code === 401) {
                return new SignOutAction({ expired: true });
            }
            if (error.errorCode === "50004.50004") {
                return new OperatorFinishFailNeedRetryAction();
            }
            else {
                return errorAction;
            }
        }), filter(function (action) { return !!action; }));
    };
    ErrorHandlerService.ngInjectableDef = i0.defineInjectable({ factory: function ErrorHandlerService_Factory() { return new ErrorHandlerService(i0.inject(i1.Store)); }, token: ErrorHandlerService, providedIn: "root" });
    return ErrorHandlerService;
}());
export { ErrorHandlerService };
