import { Inject } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { Component } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material";
import { MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { SimpleDlgWithIframeData } from "src/app/common/models/simple-dlg-with-iframe-data";

/**
 * Компонент простого диалога с iFrame.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "simple-dlg-with-iframe",
    templateUrl: "./simple-dlg-with-iframe.component.html",
    styleUrls: ["./simple-dlg-with-iframe.component.scss"],
})
export class SimpleDlgWithIframeComponent {
    //region Public fields

    /**
     * I18n-ключ заголовка диалога.
     */
    readonly headerKey: string;

    /**
     * Параметры для подстановки в заголовок диалога.
     */
    readonly headerParams: Object;

    /**
     * I18n-ключ текста для отображения в диалоге.
     */
    readonly iframeUrl: string;

    /**
     * Находтся ли диалог в стандии загрузки.
     */
    readonly loading: boolean;

    /**
     * Нужна ли иконка закрытия диалога?
     */
    readonly closeIcon: boolean;

    //endregion
    //region Private fields

    /**
     * Данные диалога.
     */
    private _data: SimpleDlgWithIframeData;

    /**
     * Сервис для получения текстов на текущем языке по их ключам.
     */
    private _translateService: TranslateService;

    //endregion
    //region Ctor

    constructor(
        private _dialogRef: MatDialogRef<SimpleDlgWithIframeComponent>,
        @Inject(MAT_DIALOG_DATA) data: SimpleDlgWithIframeData,
        translateService: TranslateService,
    ) {
        this._data = data;
        this._translateService = translateService;

        this.headerKey = this._data.headerKey;
        this.headerParams = this._data.headerParams || {};
        this.iframeUrl = this._data.iframeUrl;
        this.loading = this._data.loading;
        this.closeIcon = this._data.closeIcon;
    }

    //endregion
    //region Getters and Setters

    /**
     * У диалога есть заголовок?
     */
    get hasHeader(): boolean {

        return (!!this._data.headerKey);
    }

    /**
     * У диалога есть кнопка принятия?
     */
    get hasOkButton(): boolean {

        return !!(this._data.okCallback);
    }

    /**
     * I18n-ключ текста кнопки принятия диалога.
     */
    get okBtnKey(): string {

        return (this._data.okBtnKey || "dialogs.ok");
    }

    /**
     * У диалога есть иконка закрытия диалога?
     */
    get hasCloseIcon(): boolean {

        return !!(this._data.closeIcon);
    }

    //endregion
    //region Events

    /**
     * Обработчик клика по кнопке принятия диалога.
     */
    okClickHandler(): void {

        if (this._data.okCallback) {

            this._data.okCallback();
        }

        this._dialogRef.close();
    }

    /**
     * Обработчик клика по кнопке закрытия диалога.
     */
    closeClickHandler(): void {

        if (this._data.cancelCallback) {

            this._data.cancelCallback();
        }

        this._dialogRef.close();
    }

    //endregion
}
