import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

/**
 * Данные для установки заголовка таблицы.
 */
export interface TitleData {
    
    /**
     * I18n-ключ текста заголовка страницы, который будет после 'Obrabot4ik - '.
     */
    titleKey: string;

    /**
     * Флаг, который указывает, что не нужно использовать часть 'Obrabot4ik - '.
     */
    withoutPrefix?: boolean;
}

/**
 * Сервис для выставления заголовка страницы в браузере.
 */
@Injectable({
    providedIn: 'root'
})
export class TitleService {
    //region Private fields

    /**
     * Подписка на изменение текста i18n-ключа.
     */
    private _titleSubscription: Subscription = null;

    //endregion
    //region Ctor

    constructor(
        private _title: Title,
        private _translateService: TranslateService
    ) {}

    //endregion
    //region Public

    /**
     * Выполняет подписку на i18n-ключ текста заголовка страницы и меняет заголовок. 
     * 
     * @param titleData Данные для заголовка страницы браузера.
     */
    setTitle(titleData: TitleData) {

        if (this._titleSubscription !== null) {

            this._titleSubscription.unsubscribe();
        }

        this._titleSubscription = this._translateService
            .get(titleData.titleKey)
            .subscribe((title: string) => {

                if (!titleData.withoutPrefix) {

                    title = 'Obrabot4ik - ' + title;
                }
                this._title.setTitle(title);
            });
    }

    /**
     * Сбрасывает заголовок страницы на значение по умолчанию.
     */
    clearTitle() {

        if (this._titleSubscription !== null) {

            this._titleSubscription.unsubscribe();
            this._titleSubscription = null;
        }

        this._title.setTitle('Obrabot4ik');
    }

    //endregion
}