import { Country } from "src/app/common/models/country.model";
import { CountriesActionType } from "src/app/root/store/actions/countries.action";
import { CountriesAction } from "src/app/root/store/actions/countries.action";
import { CountriesState } from "src/app/root/store/states/countries.state";

/**
 * Начальное состояние списка стран.
 */
const initialState: CountriesState = {
    loading: false,
    loaded: false,
    failed: false,
    countries: [],
};

/**
 * Обработчик событий, связанных со странами.
 * 
 * @param state Состояние списка стран.
 * @param action Событие произошедшее в системе.
 */
export function countriesReducer(
    state = initialState,
    action: CountriesAction,
): CountriesState {
    switch (action.type) {

        // Требование загрузить список стран.
        case CountriesActionType.LOAD: {

            return {
                ...state,
                loading: true,
                loaded: false,
                failed: false,
            };
        }

        // Уведомление об успешной загрузке списка стран.
        case CountriesActionType.LOAD_SUCCESS: {

            return {
                ...state,
                loading: false,
                loaded: true,
                countries: action.countries,
            };
        }

        // Увдомление о неудачной попытке загрузки списка стран.
        case CountriesActionType.LOAD_FAIL: {

            return {
                ...state,
                loading: false,
                failed: true,
                countries: [],
            };
        }
    }

    return state;
}

/**
 * Возвращает список стран из состояния списка стран.
 *
 * @param state Состояние списка стран.
 */
export const getCountries = (state: CountriesState): Country[] => state.countries;

/**
 * Возвращает флаг успешно выполненной загрузки списка стран из состояния списка стран.
 *
 * @param state Состояние списка стран.
 */
export const getCountriesLoaded = (state: CountriesState): boolean => state.loaded;
