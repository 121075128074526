var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/**
 * Утилиты для работы с объектами.
 */
var ObjectUtils = /** @class */ (function () {
    function ObjectUtils() {
    }
    //region Public
    /**
     * Возвращает неизменяемый объект и всех его внутренних объектов.
     *
     * @param object Объект.
     * @param copy Создать глубокую копию объекта?
     *
     * @return Неизменяемый объект.
     */
    ObjectUtils.freezeInDeep = function (object, copy) {
        if (copy === void 0) { copy = true; }
        var _a;
        if (Object.isFrozen(object)) {
            return object;
        }
        var frozenObject;
        if (object instanceof Array) {
            frozenObject = [];
        }
        else {
            frozenObject = {};
        }
        for (var propertyName in object) {
            if (object.hasOwnProperty(propertyName)) {
                if (object instanceof Array) {
                    frozenObject = frozenObject.concat([
                        ObjectUtils.freezeInDeep(object[propertyName], copy),
                    ]);
                }
                else {
                    frozenObject = __assign({}, frozenObject, (_a = {}, _a[propertyName] = ObjectUtils.freezeInDeep(object[propertyName], copy), _a));
                }
            }
        }
        if (copy) {
            return Object.freeze(frozenObject);
        }
        else {
            return Object.freeze(object);
        }
    };
    return ObjectUtils;
}());
export { ObjectUtils };
