/**
 * Место проблемы в документе, на которое может пожаловаться оператор.
 */
var ComplaintProblemPlace = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор места проблемы в документе, на которое может пожаловаться оператор.
     *
     * @param key Ключ места проблемы.
     */
    function ComplaintProblemPlace(key) {
        this._key = key;
    }
    //endregion
    //region Public static
    /**
     * Возвращает массив всех значений enum'а.
     *
     * @return Массив всех значений enum'а.
     */
    ComplaintProblemPlace.values = function () {
        return Object.keys(ComplaintProblemPlace)
            .map(function (key) { return ComplaintProblemPlace[key]; })
            .filter(function (value) { return value instanceof ComplaintProblemPlace; })
            .map(function (value) { return value; });
    };
    Object.defineProperty(ComplaintProblemPlace.prototype, "i18nKey", {
        //endregion
        //region Public
        /**
         * Ключ интернализации.
         */
        get: function () {
            return ("complaintOnDocument.place." + this._key);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ComplaintProblemPlace.prototype, "key", {
        /**
         * Уникальное строковое представление места проблемы.
         */
        get: function () {
            return this._key;
        },
        enumerable: true,
        configurable: true
    });
    //region Enum values
    /**
     * Тип документа.
     */
    ComplaintProblemPlace.TYPE = new ComplaintProblemPlace("TYPE");
    /**
     * Номер документа.
     */
    ComplaintProblemPlace.NUMBER = new ComplaintProblemPlace("NUMBER");
    /**
     * Дата документа.
     */
    ComplaintProblemPlace.DATE = new ComplaintProblemPlace("DATE");
    /**
     * Стороны документа.
     */
    ComplaintProblemPlace.PARTIES = new ComplaintProblemPlace("PARTIES");
    /**
     * Таблица.
     */
    ComplaintProblemPlace.TABLE = new ComplaintProblemPlace("TABLE");
    /**
     * Итоги.
     */
    ComplaintProblemPlace.SUMMARY = new ComplaintProblemPlace("SUMMARY");
    /**
     * Плохое качество.
     */
    ComplaintProblemPlace.BAD_QUALITY = new ComplaintProblemPlace("BAD_QUALITY");
    return ComplaintProblemPlace;
}());
export { ComplaintProblemPlace };
