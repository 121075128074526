<div class="single-select">
    <mat-form-field
        class="single-select__mat-form-field"
        [ngClass]="{ 'single-select__mat-form-field_right': rightAlign }"
        [hideRequiredMarker]="true"
    >
        <mat-select
            [formControl]="valueControl"
            [placeholder]="placeholder"
            [compareWith]="compareWith"
            [required]="required"
            [errorStateMatcher]="errorStateMatcher"
            [panelClass]="panelClass"
        >
            <mat-select-trigger *ngIf="isSelectedValueExists">
                {{ getResultText(selectedValue) }}
            </mat-select-trigger>
            <ngx-mat-select-search
                *ngIf="searchEnabled"
                [formControl]="searchControl"
                [placeholderLabel]="searchPlaceholder$ | async"
                [noEntriesFoundLabel]="noSearchResultsPlaceholder$ | async"
                (keydown)="keydownHandler($event)"
            >
            </ngx-mat-select-search>
            <mat-option *ngIf="isSelectedValueExists" [value]="getOptionValue(selectedValue)" [disabled]="true" >
                <span [innerHTML]="getOptionText(selectedValue)"></span>
            </mat-option>
            <mat-option *ngIf="isStartTypingPlaceholderVisible" [disabled]="true">
                {{ startSearchPlaceholder$ | async }}
            </mat-option>
            <mat-option *ngIf="hasNullOption"
                [value]="getNullOptionValue()"
            >{{ nullOptionText }}</mat-option>
            <mat-option *ngFor="let option of (searchOptions$ | async)"
                [value]="getOptionValue(option)"
            >
                <span [innerHTML]="getOptionText(option)"></span>
            </mat-option>
            <mat-option *ngFor="let option of (filteredOptions$ | async)"
                [value]="getOptionValue(option)"
            >
                <span [innerHTML]="getOptionText(option)"></span>
            </mat-option>
            <mat-option *ngIf="loading || infinityLoading"
                [disabled]="true"
                class="single-select__loading"
            >
                <mat-spinner [diameter]="25"></mat-spinner>
            </mat-option>
            <mat-option *ngIf="isNoSearchResultsVisible"
                [disabled]="true"
            >
                {{ noSearchResultsPlaceholder$ | async }}
            </mat-option>
            <mat-option *ngIf="searchFn" class="single-select__invisible" [disabled]="true"></mat-option>
        </mat-select>
        <mat-icon 
            *ngIf="valueControl.value && valueControl.enabled && clearBtnEnabled"
            class="single-select__clear-btn"
            [matTooltip]="(clearBtnTitle ? clearBtnTitle : ('clear' | translate))"
            (click)="clearBtnClickHandler($event);"
        >clear</mat-icon>
        <mat-error *ngIf="valueControl.errors?.required && requiredErrorMessage">{{requiredErrorMessage}}</mat-error>
        <mat-error *ngIf="!valueControl.errors?.required && customError && customErrorMessage">
            {{customErrorMessage}}
        </mat-error>
    </mat-form-field>
</div>
