import { Action } from "@ngrx/store";

import { ApiResponse } from "../../../common/models";
import { SignInProps } from "./props/login";
import { SignInSucceededProps } from "./props/login";
import { SignOutProps } from "./props/login";

/**
 * Типы событий, связанные со входом в систему и выходом из неё.
 */
export enum LoginActionType {
    SIGN_IN = "[Login] Sign in",
    SIGN_IN_SUCCEEDED = "[Login] Sign in succeeded",
    SIGN_IN_FAILED = "[Login] Sign in failed",
    SIGN_OUT = "[Login] Sign out",
    SIGN_OUT_SUCCEEDED = "[Login] Sign out succeeded",
    SIGN_OUT_FAILED = "[Login] Sign out failed",
}

/**
 * Событие, требующее выполнения входа в систему.
 */
export class SignInAction implements Action {
    readonly type = LoginActionType.SIGN_IN;
    constructor(public props: SignInProps) { }
}

/**
 * Событие при успешном выполнении входа в систему.
 */
export class SignInSucceededAction implements Action {
    readonly type = LoginActionType.SIGN_IN_SUCCEEDED;
    constructor(public props: SignInSucceededProps) { }
}

/**
 * Событие при ошибке попытки входа в систему.
 */
export class SignInFailedAction implements Action {
    readonly type = LoginActionType.SIGN_IN_FAILED;
    constructor(public response: ApiResponse) { }
}

/**
 * Событие, требующее выполнения выхода из системы.
 */
export class SignOutAction implements Action {
    readonly type = LoginActionType.SIGN_OUT;
    constructor(public props: SignOutProps) { }
}

/**
 * Событие при успешном выполнении выхода из системы.
 */
export class SignOutSucceededAction implements Action {
    readonly type = LoginActionType.SIGN_OUT_SUCCEEDED;
}

/**
 * Событие при ошибке попытки выхода из системы.
 */
export class SignOutFailedAction implements Action {
    readonly type = LoginActionType.SIGN_OUT_FAILED;
    constructor(public response: ApiResponse) { }
}

/**
 * Тип, объединяющий все события, связанные со входом в систему и выходом из неё.
 */
export type LoginAction =
    SignInAction
    | SignInSucceededAction
    | SignInFailedAction
    | SignOutAction
    | SignOutSucceededAction
    | SignOutFailedAction
;
