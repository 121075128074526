var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CurrentUserActionType } from "../actions/current-user.action";
import { LoginActionType } from "../actions/login.action";
/**
 * Начальное состояние по данным текущего пользователя.
 */
export var initialState = {
    // Данных нет.
    currentUserInfo: undefined,
    // Данные не загружены.
    loaded: false,
    // Данные не загружаются в данный момент.
    loading: false,
    // Ошибки загрузки нет.
    failed: false
};
/**
 * Обработка состояния о текущем пользователе в ответ на события.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 */
export function currentUserReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        // На событие загрузки данных меняем флаг загрузки.
        case CurrentUserActionType.LOAD: {
            return __assign({}, state, { loading: true, loaded: false, failed: false });
        }
        // На событие успешной загрузки данных меняем флаги и меняем данные пользователя.
        case CurrentUserActionType.LOAD_SUCCESS: {
            return __assign({}, state, { currentUserInfo: action.payload, loaded: true, loading: false });
        }
        // На событие неудачной загрузки данных меняем флаги.
        case CurrentUserActionType.LOAD_FAIL: {
            return __assign({}, state, { currentUserInfo: undefined, loaded: false, loading: false, failed: true });
        }
        // На событие успешного выхода из системы данные пользователя сбрасываются.
        case LoginActionType.SIGN_OUT_SUCCEEDED: {
            return initialState;
        }
    }
    return state;
}
/**
 * Возвращает данные пользователя из состояния данных о текущем пользователе.
 *
 * @param state Состояние данных о текущем пользователе.
 */
export var getCurrentUserInfo = function (state) { return state.currentUserInfo; };
/**
 * Возвращает язык текущего пользователя.
 *
 * @param user Данные о текущего пользователя.
 */
export var getCurrentUserLang = function (user) { return user ? user.lang : null; };
/**
 * Возвращает флаг загружены ли данные пользователя из состояния данных о текущем пользователе.
 *
 * @param state Состояние данных о текущем пользователе.
 */
export var getCurrentUserLoaded = function (state) { return state.loaded; };
/**
 * Возвращает флаг выполнения загрузки данных пользователя из состояния данных о текущем пользователе.
 *
 * @param state Состояние данных о текущем пользователе.
 */
export var getCurrentUserLoading = function (state) { return state.loading; };
/**
 * Возвращает флаг была ли ошибка при попытке загрузки данных пользователя из состояния данных о текущем
 * пользователе.
 *
 * @param state Состояние данных о текущем пользователе.
 */
export var getCurrentUserFailed = function (state) { return state.failed; };
