import { CurrentUserAction } from "../actions/current-user.action";
import { CurrentUserActionType } from "../actions/current-user.action";
import { LoginActionType } from "../actions/login.action";
import { LoginAction } from "../actions/login.action";

import { CurrentUserInfo } from "../../../common/models";

/**
 * Состояние по данным текущего пользователя.
 */
export interface CurrentUserState {

    // Данные пользователя.
    currentUserInfo: CurrentUserInfo;

    // Данные успешно загружены?
    loaded: boolean;

    // Данные загружаются?
    loading: boolean;

    // Загрузка завершилась ошибкой?
    failed: boolean;
}

/**
 * Начальное состояние по данным текущего пользователя.
 */
export const initialState: CurrentUserState = {
    
    // Данных нет.
    currentUserInfo: undefined,

    // Данные не загружены.
    loaded: false,

    // Данные не загружаются в данный момент.
    loading: false,

    // Ошибки загрузки нет.
    failed: false
};

/**
 * Обработка состояния о текущем пользователе в ответ на события.
 * 
 * @param state Текущее состояние.
 * @param action Событие.
 */
export function currentUserReducer(state = initialState, action: CurrentUserAction | LoginAction): CurrentUserState {
    
    switch (action.type) {

        // На событие загрузки данных меняем флаг загрузки.
        case CurrentUserActionType.LOAD: {

            return {
                ...state,
                loading: true,
                loaded: false,
                failed: false
            };
        }

        // На событие успешной загрузки данных меняем флаги и меняем данные пользователя.
        case CurrentUserActionType.LOAD_SUCCESS: {

            return {
                ...state,
                currentUserInfo: action.payload,
                loaded: true,
                loading: false,
            };
        }

        // На событие неудачной загрузки данных меняем флаги.
        case CurrentUserActionType.LOAD_FAIL: {

            return {
                ...state,
                currentUserInfo: undefined,
                loaded: false,
                loading: false,
                failed: true,
            };
        }

        // На событие успешного выхода из системы данные пользователя сбрасываются.
        case LoginActionType.SIGN_OUT_SUCCEEDED: {

            return initialState;
        }
    }

    return state;
}

/**
 * Возвращает данные пользователя из состояния данных о текущем пользователе.
 * 
 * @param state Состояние данных о текущем пользователе.
 */
export const getCurrentUserInfo = (state: CurrentUserState) => state.currentUserInfo;

/**
 * Возвращает язык текущего пользователя.
 * 
 * @param user Данные о текущего пользователя.
 */
export const getCurrentUserLang = (user: CurrentUserInfo) => user ? user.lang : null;

/**
 * Возвращает флаг загружены ли данные пользователя из состояния данных о текущем пользователе.
 * 
 * @param state Состояние данных о текущем пользователе.
 */
export const getCurrentUserLoaded = (state: CurrentUserState) => state.loaded;

/**
 * Возвращает флаг выполнения загрузки данных пользователя из состояния данных о текущем пользователе.
 * 
 * @param state Состояние данных о текущем пользователе.
 */
export const getCurrentUserLoading = (state: CurrentUserState) => state.loading;

/**
 * Возвращает флаг была ли ошибка при попытке загрузки данных пользователя из состояния данных о текущем 
 * пользователе.
 * 
 * @param state Состояние данных о текущем пользователе.
 */
export const getCurrentUserFailed = (state: CurrentUserState) => state.failed;
