import { APP_INITIALIZER } from "@angular/core";
import { RouterStateSerializer } from "@ngrx/router-store";
import { MetaReducer } from "@ngrx/store";
import { META_REDUCERS } from "@ngrx/store";
import { ConfigurationService } from "src/app/common/services/configuration.service";
import { RootState } from "src/app/root/store/states/root.state";

import { CustomSerializer } from "../store";

/**
 * Возвращает функцию для получения конфигурации.
 */
export function getConfiguration(configService: ConfigurationService): Function {

    return () => configService.loadConfigurations().toPromise();
}

/**
 * Возвращает список MetaReducer'ов приложения.
 */
export function getMetaReducers(): MetaReducer<RootState>[] {

    return [];
}

/**
 * Провайдеры, которые необходимо зарегистрировать в общем модуле.
 */
export const providers = [
    {
        provide: RouterStateSerializer,
        useClass: CustomSerializer
    },
    {
        provide: META_REDUCERS,
        useFactory: getMetaReducers,
    },
    {
        provide: APP_INITIALIZER,
        useFactory: getConfiguration,
        deps: [ConfigurationService],
        multi: true,
    }
];
