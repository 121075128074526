var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { CountriesService } from "src/app/root/services/countries.service";
import { countriesActions } from "src/app/root/store/actions/countries.action";
import { CountriesActionType } from "src/app/root/store/actions/countries.action";
/**
 * Side-эффекты на события, связанные со странами.
 */
var CountriesEffects = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор эффектов на события, относящиеся к странам.
     *
     * @param _actions$ События.
     * @param countriesService Сервис для работы со списком стран.
     */
    function CountriesEffects(_actions$, countriesService) {
        var _this = this;
        this._actions$ = _actions$;
        //endregion
        //region Public
        /**
         * Выполнение загрузки списка стран.
         */
        this.loadCountries$ = this._actions$
            .pipe(ofType(CountriesActionType.LOAD), switchMap(function () { return _this._countriesService.get()
            .pipe(map(function (countries) {
            return countriesActions.countriesLoadSuccess({ countries: countries });
        }), catchError(function (apiResponse) {
            return of(countriesActions.countriesLoadFail(apiResponse));
        })); }));
        this._countriesService = countriesService;
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CountriesEffects.prototype, "loadCountries$", void 0);
    return CountriesEffects;
}());
export { CountriesEffects };
