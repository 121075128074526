import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { Inject } from '@angular/core';

import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';
import { SimpleDlgData } from "../../models/simple-dlg-data";

/**
 * Компонент простого диалога с текстом.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'simple-prompt-dlg',
    templateUrl: './simple-prompt-dlg.component.html',
    styleUrls: ['./simple-prompt-dlg.component.scss'],
})
export class SimplePromptDlgComponent implements OnInit {
    //region Fields

    /**
     * I18n-ключ заголовка диалога.
     */
    headerKey: string;

    /**
     * Параметры для подстановки в заголовок диалога.
     */
    headerParams: Object;

    /**
     * I18n-ключ текста для отображения в диалоге.
     */
    textKey: string;

    /**
     * Параметры для подстановки в текст диалога.
     */
    textParams: Object;

    /**
     * Пользовательский ввод в диалоге.
     */
    value: string = "";

    //region
    //region Ctor

    constructor(
        private _dialogRef: MatDialogRef<SimplePromptDlgComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: SimpleDlgData,
    ) { 
        this.headerKey = this._data.headerKey;
        this.headerParams = this._data.headerParams || {};
        this.textKey = this._data.textKey;
        this.textParams = this._data.textParams || {};
    }

    //endregion
    //region Hooks

    ngOnInit() { }

    onClose() {
        this._dialogRef.close();
        if (this._data.cancelCallback) {
            this._data.cancelCallback();
        }
    }

    onConfirm() {
        this._dialogRef.close(this.value);
        if (this._data.confirmCallback) {
            this._data.confirmCallback(this.value);
        }
    }

    //endregion
}
