import { Store } from "@ngrx/store";
import { select } from "@ngrx/store";
import { currentUserStateSelector } from "rootStore";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { map } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { UrlUtils } from "src/app/common/utils/url.utils";
import { RouteService } from "../services";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "../services/route.service";
/**
 * Route guard, который проверяет, есть ли аутентифицированный пользователь, и если есть, то перенаправляет на страницу
 * рабочего пространства оператора. Иначе, перенаправляет на страницу входа в систему.
 */
var RootGuard = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор route guard, который проверяет, есть ли аутентифицированный пользователь, и если есть, то
     * перенаправляет на страницу рабочего пространства оператора. Иначе, перенаправляет на страницу входа в систему.
     *
     * @param store Сервис для управления и доступа к состоянию приложения.
     * @param routeService Сервис для выполнения каких-либо переходов по URL'ам.
     */
    function RootGuard(store, routeService) {
        this._store = store;
        this._routeService = routeService;
    }
    //endregion
    //region Public
    /**
     * Проверяет, есть ли аутентифицированный пользователь, и если есть, то перенаправляет на страницу рабочего
     * пространства оператора. Иначе, перенаправляет на страницу входа в систему.
     *
     * @param route Состояние текущего активированного URL'а.
     * @param state Состояние навигации.
     *
     * @return URL для перехода на нужную страницу.
     */
    RootGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        return this._store
            .pipe(select(currentUserStateSelector), filter(function (userState) { return userState.loaded || userState.failed; }), map(function (userState) { return userState.currentUserInfo; }), switchMap(function (user) {
            if (user) {
                return of(_this._routeService.goTo(UrlUtils.operatorPagePath()));
            }
            else {
                return of(_this._routeService.goToLogin(state.url));
            }
        }), catchError(function () { return of(_this._routeService.goToLogin(state.url)); }));
    };
    /**
     * Проверяет, есть ли аутентифицированный пользователь, и если есть, то перенаправляет на страницу рабочего
     * пространства оператора. Иначе, перенаправляет на страницу входа в систему.
     *
     * @param route Состояние текущего активированного URL'а.
     * @param state Состояние навигации.
     *
     * @return URL для перехода на нужную страницу.
     */
    RootGuard.prototype.canActivateChild = function (route, state) {
        return this.canActivate(route, state);
    };
    RootGuard.ngInjectableDef = i0.defineInjectable({ factory: function RootGuard_Factory() { return new RootGuard(i0.inject(i1.Store), i0.inject(i2.RouteService)); }, token: RootGuard, providedIn: "root" });
    return RootGuard;
}());
export { RootGuard };
