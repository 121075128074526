import { ChangeDetectionStrategy } from "@angular/core";
import { Component } from "@angular/core";
import { Input } from "@angular/core";
import { OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ServerSideErrorDetailsComponent } from "src/app/common/components/server-side-error-details/server-side-error-details.component";
import { ApiResponse } from "src/app/common/models/api-response.model";

/**
 * Компонент диалога с деталями произошедших на сервере ошибок.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'server-side-error-details-dlg',
    template: '',
    styleUrls: [],
})
export class ServerSideErrorDetailsDlgComponent implements OnInit {
    //region Inputs

    /**
     * Входящие данные - ошибки, произошедшие на сервере.
     */
    @Input()
    errors: ApiResponse[] = [];

    //endregion
    //region Ctor

    constructor(
        public dialog: MatDialog
    ) { }

    //endregion
    //region Hooks

    ngOnInit() {

        setTimeout(() => {

            this.dialog.open(ServerSideErrorDetailsComponent, {
                data: { errors: this.errors },
                panelClass: 'operator-error-details-panel'
            });
        })
    }

    //endregion
}
