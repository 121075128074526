import { HostBinding } from "@angular/core";
import { HostListener } from "@angular/core";
import { ElementRef } from "@angular/core";
import { Input } from "@angular/core";
import { Directive } from "@angular/core";
import { DlgService } from "src/app/common/services/dlg.service";

/**
 * Директива-декоратор для открытия диалогового окна с заданной по ссылке информацией.
 */
@Directive({
    selector: "[helpDialog]",
})
export class HelpDialogDirective {
    //region Inputs

    /**
     * Url для генерации iframe.
     */
    @Input()
    iframeUrl: string = null;

    //endregion
    //region Fields

    /**
     * Хост-элемент директивы.
     */
    private readonly _element: ElementRef<HTMLInputElement>;

    /**
     * Сервис для работы с диалогами.
     */
    private readonly _dlgService: DlgService;

    //endregion
    //region Ctor

    /**
     * Конструктор директивы.
     *
     * @param dlgService Сервис для работы с диалогами.
     * @param element Хост-элемент директивы.
     */
    constructor(dlgService: DlgService, element: ElementRef) {

        this._dlgService = dlgService;
        this._element = element;
    }

    //endregion
    //region Getters

    /**
     * Присваивает элементу, к которому присвоена директива, стиль курсора.
     */
    @HostBinding("class.help-cursor")
    get helpCursor() {

        return !!this.iframeUrl;
    }

    //endregion
    //region Public

    /**
     * Эмитит событие открытия диалога с информацией.
     */
    openInfoDialog(): void {

        this._dlgService.openSimpleDlgWithIFrame({
            closeIcon: true,
            iframeUrl: this.iframeUrl,
        });
    }

    //endregion
    //region Events

    /**
     * Обработчик события клика по элементу.
     *
     * Открывает диалоговое окно с заданной iFrame-ссылкой.
     */
    @HostListener("click", ["$event"])
    private clickHandler(): void {

        if (this.iframeUrl) {

            this.openInfoDialog();
        }
    }

    //endregion
}
