var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CountriesActionType } from "src/app/root/store/actions/countries.action";
/**
 * Начальное состояние списка стран.
 */
var initialState = {
    loading: false,
    loaded: false,
    failed: false,
    countries: [],
};
/**
 * Обработчик событий, связанных со странами.
 *
 * @param state Состояние списка стран.
 * @param action Событие произошедшее в системе.
 */
export function countriesReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        // Требование загрузить список стран.
        case CountriesActionType.LOAD: {
            return __assign({}, state, { loading: true, loaded: false, failed: false });
        }
        // Уведомление об успешной загрузке списка стран.
        case CountriesActionType.LOAD_SUCCESS: {
            return __assign({}, state, { loading: false, loaded: true, countries: action.countries });
        }
        // Увдомление о неудачной попытке загрузки списка стран.
        case CountriesActionType.LOAD_FAIL: {
            return __assign({}, state, { loading: false, failed: true, countries: [] });
        }
    }
    return state;
}
/**
 * Возвращает список стран из состояния списка стран.
 *
 * @param state Состояние списка стран.
 */
export var getCountries = function (state) { return state.countries; };
/**
 * Возвращает флаг успешно выполненной загрузки списка стран из состояния списка стран.
 *
 * @param state Состояние списка стран.
 */
export var getCountriesLoaded = function (state) { return state.loaded; };
