import { Router } from "@angular/router";
import { UrlUtils } from "../../common/utils";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
/**
 * Сервис для выполнения каких-либо переходов по URL'ам.
 */
var RouteService = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор сервиса для выполнения каких-либо переходов по URL'ам.
     *
     * @param router Сервис для работы с навигацией по приложению.
     */
    function RouteService(router) {
        this._router = router;
    }
    //endregion
    //region Public
    /**
     * Возвращает URL для перехода на страницу логина в форме пригодной для использования в route guard'е.
     *
     * @param redirectTo URL, куда нужно вернуть пользователя после выполнения входа в систему.
     *
     * @return URL для перехода на страницу логина в форме, пригодной для использования в route guard'е.
     */
    RouteService.prototype.goToLogin = function (redirectTo) {
        return this._router.parseUrl(UrlUtils.loginPageUrl(redirectTo));
    };
    /**
     * Возвращает URL для перехода по указанному URL в форме пригодной для использования в route guard'е.
     *
     * @param url URL для перехода.
     * @return URL для перехода по указанному URL в форме пригодной для использования в route guard'е.
     */
    RouteService.prototype.goTo = function (url) {
        return this._router.parseUrl(url);
    };
    RouteService.ngInjectableDef = i0.defineInjectable({ factory: function RouteService_Factory() { return new RouteService(i0.inject(i1.Router)); }, token: RouteService, providedIn: "root" });
    return RouteService;
}());
export { RouteService };
