import { ChangeDetectionStrategy } from "@angular/core";
import { Component } from "@angular/core";
import { Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material";
import { MatDialogRef } from "@angular/material";
import { ProcessingError } from "src/app/operator/models/processing-errors/processing-error";
import { ProcessingErrors } from "src/app/operator/models/processing-errors/processing-errors";
import { ProcessingErrorsGroup } from "src/app/operator/models/processing-errors/processing-errors-group";
import { ProcessingHeaderErrors } from "src/app/operator/models/processing-errors/processing-header-errors";
import { ProcessingSummaryErrors } from "src/app/operator/models/processing-errors/processing-summary-errors";

/**
 * Компонент диалога о найденных ошибках данных обработанного документа.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "processing-errors-dialog",
    templateUrl: "processing-errors-dialog.component.html",
    styleUrls: [ "processing-errors-dialog.component.scss" ],
})
export class ProcessingErrorsDialogComponent {
    //region Constants

    /**
     * I18n-ключ заголовка ошибок, относящихся к шапке.
     */
    private static readonly _HEADER_ERRORS_GROUP_TITLE: string = "operator.errorsGroup.header";

    /**
     * I18n-ключ заголовка ошибок, относящихся к таблице документа.
     */
    private static readonly _TABLE_ERRORS_GROUP_TITLE: string = "operator.errorsGroup.table";

    /**
     * I18n-ключ заголовка ошибок, относящихся к итогам документа.
     */
    private static readonly _SUMMARY_ERRORS_GROUP_TITLE: string = "operator.errorsGroup.summary";

    /**
     * I18n-ключ заголовка ошибок, относящихся к критическим ошибкам документа.
     */
    private static readonly _CRITICAL_ERRORS_GROUP_TITLE: string = "operator.errorsGroup.critical";

    /**
     * Максимальное количество строк с ошибкой одной группы для отображения в предупреждении.
     */
    private static readonly _MAX_ROWS_NUM_WITH_ERROR: number = 12;

    /**
     * Ключи критических ошибок.
     */
    private static readonly _CRITICAL_ERROR_KEYS = [
        "operator.errors.notAllPagesViewed",
        "operator.errors.vatPairedInequality",
    ];

    //endregion
    //region Fields

    /**
     * Группы найденных ошибок данных обработанного документа.
     */
    public readonly processingErrorsGroups: ProcessingErrorsGroup[];

    /**
     * Действие на нажатие "Всё равно отправить".
     */
    public okCallback: () => void;

    /**
     * Заблокирована ли кнопка сохранения?
     */
    public isSavingLocked: boolean;

    //endregion
    //region Ctor

    /**
     * Конструктор компонента диалога о найденных ошибках данных обработанного документа.
     *
     * @param _dialogRef Компонент диалога.
     * @param data Ошибки валидации данных, которые пользователь собирается отправить на сервер как результат обработки.
     */
    constructor(
        private _dialogRef: MatDialogRef<ProcessingErrorsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: { processingErrors: ProcessingErrors, okCallback: any},
    ) {
        this.processingErrorsGroups = this._getProcessingErrorsGroups(data.processingErrors);
        this.okCallback = data.okCallback;
    }

    //endregion
    //region Public

    /**
     * Обработчик клика по кнопке "Всё равно отправить".
     */
    okClickHandler(): void {

        this.okCallback();
        this._dialogRef.close();
    }

    /**
     * Строка из списка строк, в которых встречается ошибка группы, должна отображаться?
     *
     * @param rowIndex Номер строки в списке строк, которые содержат ошибку.
     * @param rowsNum Количество строк в списке строк, которые содержат ошибку.
     *
     * @return Да/Нет.
     */
    isCurrentRowIndexVisible(rowIndex: number, rowsNum: number): boolean {

        return (
            rowIndex < ProcessingErrorsDialogComponent._MAX_ROWS_NUM_WITH_ERROR / 2
            || rowIndex >= rowsNum - ProcessingErrorsDialogComponent._MAX_ROWS_NUM_WITH_ERROR / 2
        );
    }

    /**
     * Текущая строка из списка строк, в которых встречается ошибка группы является первой из скрытых для отображения
     * строк?
     *
     * @param rowIndex Номер строки в списке строк, которые содержат ошибку.
     * @param rowsNum Количество строк в списке строк, которые содержат ошибку.
     *
     * @return Да/Нет.
     */
    isCurrentRowIndexFirstHidden(rowIndex: number, rowsNum: number): boolean {

        return (
            rowsNum > ProcessingErrorsDialogComponent._MAX_ROWS_NUM_WITH_ERROR
            && rowIndex === ProcessingErrorsDialogComponent._MAX_ROWS_NUM_WITH_ERROR / 2
        );
    }

    //endregion
    //region Private

    /**
     * Возвращает список групп найденных ошибок данных обработанного документа.
     *
     * @param processingErrors Ошибки валидации данных, которые пользователь собирается отправить на сервер как
     * результат обработки.
     *
     * @return Список групп найденных ошибок данных обработанного документа.
     */
    private _getProcessingErrorsGroups(processingErrors: ProcessingErrors): ProcessingErrorsGroup[] {

        return [
            ...this._addHeaderErrors(processingErrors.headerErrors),
            ...this._addSummaryErrors(processingErrors.summaryErrors),
            ...this._addTableErrors(processingErrors.itemsErrors),
            ...this._addCriticalErrors(processingErrors.criticalErrors),
        ];
    }

    /**
     * Добавляет ошибки шапки в список групп ошибок обработанного документа.
     *
     * @param headerErrors Ошибки данных шапки, которые пользователь собирается отправить на сервер как результат
     * обработки.
     *
     * @return Список групп найденных ошибок данных обработанного документа.
     */
    private _addHeaderErrors(headerErrors: ProcessingHeaderErrors): ProcessingErrorsGroup[] {

        let resultHeaderErrors: ProcessingError[] = [];
        let processingErrorsGroups: ProcessingErrorsGroup[] = [];

        if (headerErrors) {

            const numberErrors: ProcessingError[] = headerErrors.numberErrors;
            if (numberErrors && numberErrors.length > 0) {

                resultHeaderErrors = [ ...resultHeaderErrors, ...numberErrors];
            }

            const typeErrors: ProcessingError[] = headerErrors.typeErrors;
            if (typeErrors && typeErrors.length > 0) {

                resultHeaderErrors = [ ...resultHeaderErrors, ...typeErrors];
            }

            const dateErrors: ProcessingError[] = headerErrors.dateErrors;
            if (dateErrors && dateErrors.length > 0) {

                resultHeaderErrors = [ ...resultHeaderErrors, ...dateErrors];
            }

            const customerErrors: ProcessingError[] = headerErrors.customerErrors;
            if (customerErrors && customerErrors.length > 0) {

                resultHeaderErrors = [ ...resultHeaderErrors, ...customerErrors];
            }

            const supplierErrors: ProcessingError[] = headerErrors.supplierErrors;
            if (supplierErrors && supplierErrors.length > 0) {

                resultHeaderErrors = [ ...resultHeaderErrors, ...supplierErrors];
            }

            const paymentOrderErrors: ProcessingError[] = headerErrors.paymentOrderErrors;
            if (paymentOrderErrors && paymentOrderErrors.length > 0) {

                resultHeaderErrors = [ ...resultHeaderErrors, ...paymentOrderErrors];
            }

            const commonHeaderErrors: ProcessingError[] = headerErrors.commonErrors;
            if (commonHeaderErrors && commonHeaderErrors.length > 0) {

                resultHeaderErrors = [ ...resultHeaderErrors, ...commonHeaderErrors];
            }

            const currencyErrors: ProcessingError[] = headerErrors.currencyErrors;
            if (currencyErrors && currencyErrors.length > 0) {

                resultHeaderErrors = [...resultHeaderErrors, ...currencyErrors];
            }

            const documentShipmentsErrors: ProcessingError[] = headerErrors.documentShipmentsErrors;
            if (documentShipmentsErrors && documentShipmentsErrors.length > 0) {

                resultHeaderErrors = [...resultHeaderErrors, ...documentShipmentsErrors];
            }

            processingErrorsGroups = [
                ...processingErrorsGroups,
                {
                    errors: resultHeaderErrors,
                    titleKey: ProcessingErrorsDialogComponent._HEADER_ERRORS_GROUP_TITLE,
                },
            ];
        }

        return processingErrorsGroups;
    }

    /**
     * Добавляет ошибки таблицы документа в список групп ошибок обработанного документа.
     *
     * @param itemsErrors Ошибки данных таблицы, которые пользователь собирается отправить на сервер как результат
     * обработки.
     *
     * @return Список групп найденных ошибок данных обработанного документа.
     */
    private _addTableErrors(itemsErrors: ProcessingError[]): ProcessingErrorsGroup[] {

        let processingErrorsGroups: ProcessingErrorsGroup[] = [];
        processingErrorsGroups = [
            ...processingErrorsGroups,
            {
                errors: itemsErrors,
                titleKey: ProcessingErrorsDialogComponent._TABLE_ERRORS_GROUP_TITLE,
            },
        ];

        const hasCriticalErrors = itemsErrors.some(
            error => ProcessingErrorsDialogComponent._CRITICAL_ERROR_KEYS.includes(error.messageKey)
        );

        if (hasCriticalErrors) {

            this.isSavingLocked = true;
        }

        return processingErrorsGroups;
    }

    /**
     * Добавляет ошибки таблицы документа в список групп ошибок обработанного документа.
     *
     * @param additionalErrors Ошибки данных таблицы, которые пользователь собирается отправить на сервер как результат
     * обработки.
     *
     * @return Список групп найденных ошибок данных обработанного документа.
     */
    private _addCriticalErrors(additionalErrors: ProcessingError[]): ProcessingErrorsGroup[] {

        let processingErrorsGroups: ProcessingErrorsGroup[] = [];
        processingErrorsGroups = [
            ...processingErrorsGroups,
            {
                errors: additionalErrors,
                titleKey: ProcessingErrorsDialogComponent._CRITICAL_ERRORS_GROUP_TITLE,
            },
        ];

        const hasCriticalErrors = additionalErrors.some(
            error => ProcessingErrorsDialogComponent._CRITICAL_ERROR_KEYS.includes(error.messageKey)
        );

        if (hasCriticalErrors) {

            this.isSavingLocked = true;
        }

        return processingErrorsGroups;
    }

    /**
     * Добавляет ошибки итогов документа в список групп ошибок обработанного документа.
     *
     * @param summaryErrors Ошибки данных итогов, которые пользователь собирается отправить на сервер как результат
     * обработки.
     *
     * @return Список групп найденных ошибок данных обработанного документа.
     */
    private _addSummaryErrors(summaryErrors: ProcessingSummaryErrors): ProcessingErrorsGroup[] {

        let resultSummaryErrors: ProcessingError[] = [];
        let processingErrorsGroups: ProcessingErrorsGroup[] = [];

        if (summaryErrors) {

            const amountErrors: ProcessingError[] = summaryErrors.amountErrors;
            if (amountErrors && amountErrors.length > 0) {

                resultSummaryErrors = [ ...resultSummaryErrors, ...amountErrors];
            }

            const vatErrors: ProcessingError[] = summaryErrors.vatErrors;
            if (vatErrors && vatErrors.length > 0) {

                resultSummaryErrors = [ ...resultSummaryErrors, ...vatErrors];
            }
        }
        processingErrorsGroups = [
            ...processingErrorsGroups,
            {
                errors: resultSummaryErrors,
                titleKey: ProcessingErrorsDialogComponent._SUMMARY_ERRORS_GROUP_TITLE,
            },
        ];

        const hasCriticalErrors = resultSummaryErrors.some(
            error => ProcessingErrorsDialogComponent._CRITICAL_ERROR_KEYS.includes(error.messageKey)
        );

        if (hasCriticalErrors) {

            this.isSavingLocked = true;
        }

        return processingErrorsGroups;
    }

    //endregion
}
