import { Injectable } from "@angular/core";
import { Actions } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Store } from "@ngrx/store";

import { Observable } from "rxjs";
import { of } from "rxjs";
import { tap } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { map } from "rxjs/operators";
import { switchMap } from "rxjs/operators";

import { ApiResponse } from "../../../common/models";
import { UrlUtils } from "../../../common/utils";
import { LoginService } from "../../../login/services";

import { ErrorHandlerService } from "../../services";
import { CurrentUserAction } from "../actions";
import { CurrentUserLoadAction } from "../actions";
import { LoginActionType } from "../actions";
import { RouterAction } from "../actions";
import { RouterGoAction } from "../actions";
import { SignInAction } from "../actions";
import { SignInFailedAction } from "../actions";
import { SignInSucceededAction } from "../actions";
import { SignOutAction } from "../actions";
import { SignOutSucceededAction } from "../actions";
import { SignOutFailedAction } from "../actions";
import { RouterGoProps } from "../actions/props";
import { SignInProps } from "../actions/props/login";

import { RootState } from "src/app/root/store/states/root.state";

/**
 * Side-эффекты на события, связанные со входом в систему и выходом из неё.
 */
@Injectable()
export class LoginEffects {
    //region Fields

    /**
     * Сервис для входа в систему.
     */
    private readonly _loginService: LoginService;

    /**
     * Состояние системы.
     */
    private readonly _store: Store<RootState>;

    /**
     * Сервис с общей логикой обработки ошибочных ответов от API.
     */
    private readonly _errorHandlerService: ErrorHandlerService;

    //endregion
    //region Ctor

    /**
     * Конструктор класса с side-эффектами на события, связанными со входом в систему и выходом из неё.
     *
     * @param _actions$ Поток событий, происходящих в системе.
     * @param loginService Сервис для входа в систему.
     * @param store Состояние системы.
     * @param errorHandlerService Сервис с общей логикой обработки ошибочных ответов от API.
     */
    constructor(
        private _actions$: Actions,
        loginService: LoginService,
        store: Store<RootState>,
        errorHandlerService: ErrorHandlerService,
    ) {
        this._loginService = loginService;
        this._store = store;
        this._errorHandlerService = errorHandlerService;
    }

    //endregion
    //region Effects

    /**
     * Обработка события с требованием выполнить вход в систему.
     */
    @Effect()
    signIn$ = this._actions$
        .pipe(
            ofType(LoginActionType.SIGN_IN),
            map((signInAction: SignInAction): SignInProps => signInAction.props),
            switchMap((signInProps: SignInProps) =>
                this._loginService.signIn(signInProps.credentials)
                    .pipe(
                        map((): Action =>
                            new SignInSucceededAction({ redirectTo: signInProps.redirectTo })
                        ),
                        catchError((error: ApiResponse): Observable<Action> => of(new SignInFailedAction(error))),
                    )
            )
        );

    /**
     * Обработка события успешного входа в систему.
     *
     * Создаёт событие, требующее загрузки данных текущего пользователя.
     */
    @Effect()
    loadCurrentUser$ = this._actions$
        .pipe(
            ofType(LoginActionType.SIGN_IN_SUCCEEDED),
            map((): CurrentUserAction => new CurrentUserLoadAction()),
        );

    /**
     * Обработка события успешного входа в систему.
     *
     * Создаёт событие, требующее перехода по перенаправляющему пути после успешного входа в систему.
     */
    @Effect()
    routeToRedirectUrl$ = this._actions$
        .pipe(
            ofType(LoginActionType.SIGN_IN_SUCCEEDED),
            tap((action: SignInSucceededAction) => {

                if (action.props.redirectTo && action.props.redirectTo.toLowerCase().startsWith("http")) {

                    window.location.href = action.props.redirectTo;
                }
            }),
            map((signInSucceededAction: SignInSucceededAction): RouterGoProps => {

                let routerGoProps: RouterGoProps = { path: [ "" ] };

                if (signInSucceededAction.props.redirectTo) {

                    routerGoProps = UrlUtils.parse(signInSucceededAction.props.redirectTo);
                }

                return routerGoProps;
            }),
            map((redirectTo: RouterGoProps): RouterAction => new RouterGoAction(redirectTo)),
        );

    /**
     * Обработка события с требованием выполнить выход из системы.
     */
    @Effect()
    logout$ = this._actions$
        .pipe(
            ofType(LoginActionType.SIGN_OUT),
            map((signOutAction: SignOutAction): boolean => signOutAction.props.expired),
            filter((expired: boolean): boolean => !expired),
            switchMap(() =>
                this._loginService.signOut()
                    .pipe(
                        map((): Action => new SignOutSucceededAction()),
                        catchError((error: ApiResponse): Observable<Action> =>
                            this._errorHandlerService.handle(error, new SignOutFailedAction(error))
                        ),
                    )
            ),
        );

    /**
     * Обработка события с требованием выполнить выход из системы в случае, когда сессия истекла и запрос к серверу
     * выполнять не нужно.
     */
    @Effect()
    expiredLogout$ = this._actions$
        .pipe(
            ofType(LoginActionType.SIGN_OUT),
            map((signOutAction: SignOutAction): boolean => signOutAction.props.expired),
            filter((expired: boolean): boolean => expired),
            map((): Action => new SignOutSucceededAction()),
        );

    /**
     * Обработка события успешного выхода из системы.
     *
     * Создаёт событие с для перехода на форму входа.
     */
    @Effect()
    logoutSucceeded$ = this._actions$
        .pipe(
            ofType(LoginActionType.SIGN_OUT_SUCCEEDED),
            map((): Action => new RouterGoAction({ path: UrlUtils.loginPageRoute() })),
        );

    //endregion
}
