/**
 * Место проблемы в документе, на которое может пожаловаться оператор.
 */
export class ComplaintProblemPlace {
    //region Enum values

    /**
     * Тип документа.
     */
    public static readonly TYPE: ComplaintProblemPlace = new ComplaintProblemPlace("TYPE");

    /**
     * Номер документа.
     */
    public static readonly NUMBER: ComplaintProblemPlace = new ComplaintProblemPlace("NUMBER");

    /**
     * Дата документа.
     */
    public static readonly DATE: ComplaintProblemPlace = new ComplaintProblemPlace("DATE");

    /**
     * Стороны документа.
     */
    public static readonly PARTIES: ComplaintProblemPlace = new ComplaintProblemPlace("PARTIES");

    /**
     * Таблица.
     */
    public static readonly TABLE: ComplaintProblemPlace = new ComplaintProblemPlace("TABLE");

    /**
     * Итоги.
     */
    public static readonly SUMMARY: ComplaintProblemPlace = new ComplaintProblemPlace("SUMMARY");

    /**
     * Плохое качество.
     */
    public static readonly BAD_QUALITY: ComplaintProblemPlace = new ComplaintProblemPlace("BAD_QUALITY");

    //endregion
    //region Fields

    /**
     * Уникальное строковое представление места проблемы.
     */
    private readonly _key;

    //endregion
    //region Ctor

    /**
     * Конструктор места проблемы в документе, на которое может пожаловаться оператор.
     *
     * @param key Ключ места проблемы.
     */
    constructor(key) {

        this._key = key;
    }

    //endregion
    //region Public static

    /**
     * Возвращает массив всех значений enum'а.
     *
     * @return Массив всех значений enum'а.
     */
    public static values(): ComplaintProblemPlace[] {

        return Object.keys(ComplaintProblemPlace)
            .map((key: string): any => ComplaintProblemPlace[key])
            .filter((value: any): boolean => value instanceof ComplaintProblemPlace)
            .map((value: ComplaintProblemPlace): ComplaintProblemPlace => value);
    }

    //endregion
    //region Public

    /**
     * Ключ интернализации.
     */
    public get i18nKey(): string {

        return ("complaintOnDocument.place." + this._key);
    }

    /**
     * Уникальное строковое представление места проблемы.
     */
    public get key(): string {

        return this._key;
    }

    //endregion
}
