import { TxtFileIconSvgComponent } from "./txt-file-icon-svg/txt-file-icon-svg.component";
import { XlsxFileIconSvgComponent } from "./xlsx-file-icon-svg/xlsx-file-icon-svg.component";

export { TxtFileIconSvgComponent } from "./txt-file-icon-svg/txt-file-icon-svg.component";
export { XlsxFileIconSvgComponent } from "./xlsx-file-icon-svg/xlsx-file-icon-svg.component";

/**
 * Компоненты для отображения svg.
 */
export const svgIcons = [
    TxtFileIconSvgComponent,
    XlsxFileIconSvgComponent,
];
