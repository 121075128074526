/**
 * Типы событий, связанные с создание компании покупателя.
 */
import { Action } from "@ngrx/store";
import { ApiResponsePayloadAction } from "rootStore";
import { Company } from "../../../../common/models";
import { ApiResponse } from "../../../../common/models";

export enum CustomerCreationActionType {
    CREATE = '[Operator] Create new customer company',
    CREATE_SUCCESS = '[Operator] Customer company created success',
    CREATE_FAIL = '[Operator] Customer company created fail',
}

/**
 * Событие запроса создания компании.
 */
export class CustomerCreationRequestAction implements Action {
    readonly type = CustomerCreationActionType.CREATE;
    constructor(public payload: Company) { }
}

/**
 * Событие удачного создания компании.
 */
export class CustomerCreationSuccessAction implements Action {
    readonly type = CustomerCreationActionType.CREATE_SUCCESS;
    constructor(public payload: Company) { }
}

/**
 * Событие неудачного создания компании.
 */
export class CustomerCreationFailAction implements ApiResponsePayloadAction {
    readonly type = CustomerCreationActionType.CREATE_FAIL;
    constructor(public payload: ApiResponse) { }
}

/**
 * Тип, объединяющий все события.
 */
export type CustomerCreationAction =
    | CustomerCreationRequestAction
    | CustomerCreationFailAction
    | CustomerCreationSuccessAction