import { WebsocketEventType } from "src/app/common/models/websocket/websocket-event-type";

/**
 * Утильный класс для работы с веб сокетами.
 */
export class WebsocketUtils {
    //region Constants

    /**
     * Префикс экшенов дял событий по веб сокету.
     */
    private static readonly ACTION_PREFIX = "[Websocket event]";

    //endregion
    //region Public

    /**
     * Возвращает строку типа события стора.
     *
     * @param eventType Тип события веб сокета.
     */
    public static getEventActionType(eventType: WebsocketEventType | string): string {

        return `${WebsocketUtils.ACTION_PREFIX} ${eventType}`;
    }

    //endregion
}