import { ApiResponse } from "src/app/common/models";
import { QueuedDocument } from "../../models";
import { RecognizedDataDTO } from "../../models";

/**
 * Состояние работы оператора.
 */
export class OperatorState {
    //region Fields

    /**
     * Обрабатываемый оператором документ.
     */
    readonly queuedDocument: QueuedDocument = null;

    /**
     * Документ загружается через HTTP?
     */
    readonly loadingOverHttp: boolean = false;

    /**
     * Выполняется ожидание доступного для обработки документа?
     *
     * Отличается от флага acquireLoading тем, что в момент ожидания последовательно происходят попытки получения
     * документа из очереди, и флаг acquireLoading поочерёдно меняет своё значение, в то время как флаг waiting
     * имеет постоянное значение в это время.
     */
    readonly waiting: boolean = false;

    /**
     * Выполняется извлечение данных документа по разметке?
     */
    readonly parseByMarkupLoading: boolean = false;

    /**
     * Выполняется извлечение данных документа по типу?
     */
    readonly parseByTypeLoading: boolean = false;

    /**
     * Выполняется локальная операция без запросов к серверу?
     */
    readonly localLoading: boolean = false;

    /**
     * Выполняется сохранение результатов обработки документа?
     */
    readonly finishing: boolean = false;

    /**
     * Данные об ошибке выполнения запроса к REST API.
     */
    readonly error: ApiResponse = null;

    /**
     * Распознанные данные по текущему документу.
     */
    readonly recognizedData: RecognizedDataDTO = null;

    /**
     * Выполняется загрузка распознанных данных?
     */
    readonly recognizedDataLoading: boolean = false;

    /**
     * Данные об ошибке, произошедшей при попытке загрузки распознанных данных.
     */
    readonly recognizedDataError: ApiResponse = null;

    /**
     * Выполняется сохранение промежуточного результата обработки документа?
     */
    readonly saveLoading: boolean = false;

    /**
     * Момент времени последнего успешного сохранения промежуточного результата обработки документа.
     */
    readonly lastSaveDate: number = null;

    /**
     * Данные об ошибке, произошедшей при попытке сохранения промежуточного результата обработки документа.
     */
    readonly saveError: ApiResponse = null;

    /**
     * Блокирующий документ, который должен прервать обработку текущего документа.
     */
    readonly blockingDocument: QueuedDocument = null;

    /**
     * Выполняется загрузка информации о блокирующем документе, который должен прервать обработку текущего документа?
     *
     * Это не взятие блокирующего документа, а загрузка информации есть ли он вообще.
     */
    readonly blockingDocumentLoading: boolean = false;

    /**
     * Данные об ошибке, произошедшей при попытке загрузки информации о блокирующем документе, который должен прервать
     * обработку текущего документа.
     */
    readonly blockingDocumentError: ApiResponse = null;

    /**
     * Обратный отсчёт перед автоматическим взятием блокирущего документа в обработку (в секундах).
     */
    readonly takeBlockingCountdown: number = 0;

    /**
     * Выполняется обратный отсчёт перед автоматическим взятием блокирущего документа в обработку?
     */
    readonly takeBlockingCountdownPending: boolean = false;

    /**
     * Компонент для просмотра обрабатываемых страниц виден?
     */
    readonly pageViewerVisible: boolean = false;

    /**
     * Номера просмотренных страниц документа в обработке.
     */
    readonly viewedPages: number[] = [];

    /**
     * Номера просмотренных страниц отложенного документа.
     */
    readonly postponedViewedPages: number[] = [];

    /**
     * Документ был распарсен оператором (в том числе и через разметку)?
     */
    readonly parsedByOperator: boolean = false;

    /**
     * Счётчик провальных попыток отправок документа в течении одной и той же сессии.
     */
    readonly failedSendingAttempts: number = 0;

    /**
     * Кэшированные данные документа и флага "стоп", которые оператор попытался отправить на сервис в последний раз.
     */
    readonly lastSentDocumentAction: { document: QueuedDocument, stop: boolean } = null;

    /**
     * Индекс текущей страницы таблицы позиций документа.
     */
    readonly currentPageIndex: number = 0;

    //endregion
}
