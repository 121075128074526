import { ChangeDetectorRef } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material";
import { MatSelect } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { MatSelectSearchComponent } from "ngx-mat-select-search";
import { Subject } from "rxjs";
import { of } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { startWith } from "rxjs/operators";
import { map } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { debounceTime } from "rxjs/operators";
import { UtilsService } from "../../services";
/**
 * Компонент поля формы для выбора из списка значений с возможностью поиска.
 */
var SingleSelectComponent = /** @class */ (function () {
    //endregion
    //region Ctor
    function SingleSelectComponent(_translateService, _cd, _utilService) {
        var _this = this;
        this._translateService = _translateService;
        this._cd = _cd;
        this._utilService = _utilService;
        /**
         * Входящие данные - включить длинную панель для отображения опций выбора.
         *
         * Максимальная высота панели становится 80% от высоты экрана.
         */
        this.longPanel = false;
        /**
         * Входящие данные - функция динамической ajax-загрузки значения для выбора.
         */
        this.searchFn = null;
        /**
         * Входящие данные - задержка перед выполнением поиска после последнего нажатия на клавиши.
         */
        this.searchDebounce = 170;
        /**
         * Входящие данные - кнопка очистки выбранного значения включена?
         */
        this.clearBtnEnabled = true;
        /**
         * Входящие данные - поле обязательно для заполнения?
         */
        this.required = false;
        /**
         * Входящие данные - отображать custom ошибку для поля?
         */
        this.customError = false;
        /**
         * Входящие данные - включить выравнивание по правому краю?.
         */
        this.rightAlign = false;
        //endregion
        //region Public fields
        /**
         * Минимальная длина поискового запроса.
         */
        this.minLengthToSearch = 3;
        /**
         * Игнорировать пробелы при подсчете минимальной блинны для поиска?
         */
        this.skipSpacesForMinLength = false;
        /**
         * Открывать ли при нажатии на Enter.
         */
        this.dontOpenOnEnterKey = false;
        /**
         * Флаг, что длина поискового запроса достигла нужной длины для выполнения запроса.
         */
        this.searchLengthReached = false;
        /**
         * FormControl привязанный к выпадашке.
         */
        this.valueControl = new FormControl();
        /**
         * FormControl привязанный к полю поиска.
         */
        this.searchControl = new FormControl();
        /**
         * Динамически загруженные варианты для выбора согласно поисковому запросу.
         */
        this.searchOptions$ = new Subject();
        /**
         * Флаг выполняющейся первой загрузки динамических вариантов выбора.
         */
        this.loading = false;
        /**
         * Флаг выполняющейся последующей подгрузки динамических вариантов выбора.
         */
        this.infinityLoading = false;
        /**
         * Флаг того, что часть динамических вариантов выбора загружена.
         */
        this.loaded = false;
        /**
         * Выбранное значение.
         */
        this.selectedValue = null;
        //endregion
        //region Private fields
        /**
         * Callback, когда выбранное в выпадашке значение изменилось.
         *
         * @private
         */
        this._changeCallback = function (_) { };
        /**
         * Callback, когда пользователь начал взаимодействовать с выпадашкой.
         *
         * @private
         */
        this._touchCallback = function (_) { };
        /**
         * Поле для хранения значений для выбора.
         *
         * @private
         */
        this._options = [];
        /**
         * Поле для хранения флага отключения контрола при отсутствии вариантов для выбора.
         *
         * @private
         */
        this._disableOnEmptyOptions = true;
        /**
         * Функция для сравнения вариантов выбора по id.
         *
         * @private
         */
        this._compareWith = function (o1, o2) { return o1 && o2 && o1['id'] === o2['id']; };
        /**
         * Функция для сравнения вариантов выбора по заданному полю.
         *
         * @private
         */
        this._compareWithField = function (o1, o2) { return o1 && o2 && o1[_this.valueField] === o2[_this.valueField]; };
        /**
         * Поле для хранения флага отключения контрола.
         *
         * @private
         */
        this._selectDisabled = false;
        /**
         * Объект глобальной отписки.
         */
        this._globalUnsubscribe$ = new Subject();
    }
    Object.defineProperty(SingleSelectComponent.prototype, "optionField", {
        /**
         * Возвращает поле варианта выбора, которое отображается в выпадашке.
         */
        get: function () {
            return (this._optionField ? this._optionField : 'name');
        },
        /**
         * Входящие данные - какое поле варианта выбора отображать в выпадашке. По умолчанию "name".
         */
        set: function (optionField) {
            this._optionField = optionField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SingleSelectComponent.prototype, "resultField", {
        /**
         * Возвращает поле выбранного варианта, которое отображается.
         */
        get: function () {
            return (this._resultField ? this._resultField : 'name');
        },
        /**
         * Входящие данные - какое поле выбранного варианта отображать. По умолчанию "name".
         */
        set: function (resultField) {
            this._resultField = resultField;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SingleSelectComponent.prototype, "options", {
        /**
         * Возвращает значения для выбора.
         */
        get: function () {
            return this._options;
        },
        /**
         * Входящие данные - статические значения для выбора.
         */
        set: function (options) {
            this._options = options;
            this._disableOrEnableControl();
            this.searchControl.setValue("");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SingleSelectComponent.prototype, "disableOnEmptyOptions", {
        /**
         * Входящие данные - отключать выпадашку, когда вариантов для выбора нет?
         */
        set: function (disableOnEmptyOptions) {
            this._disableOnEmptyOptions = disableOnEmptyOptions;
            this._disableOrEnableControl();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SingleSelectComponent.prototype, "selectDisabled", {
        /**
         * Входящие данные - отключение контрола.
         */
        set: function (disabled) {
            this._selectDisabled = disabled;
            this._disableOrEnableControl();
        },
        enumerable: true,
        configurable: true
    });
    //endregion
    //region Hooks
    SingleSelectComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Поиск среди статически заданных вариантов выбора.
        this.filteredOptions$ = this.searchControl.valueChanges
            .pipe(startWith(''), map(function (search) { return _this.filterOptions(search); }));
        // Поиск динамически подгружаемых вариантов выбора.
        if (this.searchFn) {
            this.searchControl.valueChanges
                .pipe(takeUntil(this._globalUnsubscribe$), debounceTime(this.searchDebounce), map(function (search) { return search.trim(); }), switchMap(function (search) {
                _this.searchOptions$.next([]);
                _this.searchLengthReached = false;
                var searchLength = (_this.skipSpacesForMinLength && search.replace(/\s/g, "") || search).length;
                if (searchLength < _this.minLengthToSearch) {
                    _this.loading = false;
                    return of([]);
                }
                else {
                    _this.loading = true;
                    return _this.searchFn(search, 1);
                }
            }))
                .subscribe(function (options) {
                _this.searchOptions$.next(options);
            });
        }
        else {
            this.searchOptions$.next([]);
        }
        this.searchOptions$
            .pipe(takeUntil(this._globalUnsubscribe$))
            .subscribe(function (options) {
            _this.loading = false;
            _this.infinityLoading = false;
            _this.loaded = !!(options && options.length);
        });
        this._valueChangesSubscription = this.valueControl.valueChanges
            .subscribe(function (value) {
            _this.selectedValue = value;
            _this._changeCallback(value);
            setTimeout(function () { return _this._cd.markForCheck(); }, 100);
        });
    };
    SingleSelectComponent.prototype.ngOnDestroy = function () {
        this._valueChangesSubscription.unsubscribe();
        this.searchOptions$.complete();
        this._globalUnsubscribe$.complete();
    };
    //endregion
    //region ControlValueAccessor
    SingleSelectComponent.prototype.writeValue = function (value) {
        if (value !== undefined && this.valueControl.value !== value) {
            this.valueControl.setValue(value);
        }
    };
    /**
     * Регистрирует функцию для уведомлении контрола формы об изменении значения.
     *
     * Вызов полученной функции fn оборачивается в try/catch и одна из возможных ошибок игнорируется.
     *
     * Подобная ошибка происходит, когда form control, привязанный к single-select, пересоздаётся, то
     * регистрируется другое значение функции fn, вызов которой приводит к ошибке. После чего вновь
     * регистрируется нормальное значение fn, вызов которой не приводит к ошибкам. При этом на работоспособность
     * single-select возникающая ошибка никак не влияет. Но чтобы не мусорить в логе, эта ошибка игнорируется.
     *
     * Пример, где возникает такая ситуация - это страница обработки документа оператором. Если при открытом
     * просмотрщике страниц документа нажать на кнопку выброса страниц, то состояние документы изменяется,
     * новый экземпляр документа приходит в @Input, выполняется пересоздание form control'ов.
     * Три single-select'а расположенные на этой странице ловят это исключение.
     *
     * @param fn Функция для уведомления контрола формы об изменении значения.
     */
    SingleSelectComponent.prototype.registerOnChange = function (fn) {
        this._changeCallback = function (value) {
            try {
                fn(value);
            }
            catch (e) {
                var formControlError = "There is no FormControl instance attached to form control element with name";
                if (!e.toString().includes(formControlError)) {
                    throw e;
                }
            }
        };
    };
    SingleSelectComponent.prototype.registerOnTouched = function (fn) {
        this._touchCallback = fn;
    };
    Object.defineProperty(SingleSelectComponent.prototype, "compareWith", {
        //endregion
        //region Getters and Setters
        /**
         * Возвращает функцию для сравнения вараинтов выбора для mat-select.
         */
        get: function () {
            if (this.userCompareWith) {
                return this.userCompareWith;
            }
            if (this.valueField) {
                return this._compareWithField;
            }
            return this._compareWith;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SingleSelectComponent.prototype, "searchPlaceholder$", {
        /**
         * Возвращает Observable placeholder'а, пока строка поиска пуста.
         */
        get: function () {
            if (this.searchPlaceholder) {
                return of(this.searchPlaceholder);
            }
            else {
                return this._translateService.get('search.label');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SingleSelectComponent.prototype, "startSearchPlaceholder$", {
        /**
         * Возвращает Observable placeholder'а, что пользователю нужно начать вводить, чтобы начался поиск.
         */
        get: function () {
            if (this.startSearchPlaceholder) {
                return of(this.startSearchPlaceholder);
            }
            else {
                return this._translateService.get('search.startTyping');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SingleSelectComponent.prototype, "noSearchResultsPlaceholder$", {
        /**
         * Возвращает Observable placeholder'а, когда поиск не вернул результатов.
         */
        get: function () {
            if (this.noSearchResultsPlaceholder) {
                return of(this.noSearchResultsPlaceholder);
            }
            else {
                return this._translateService.get('search.empty.result');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SingleSelectComponent.prototype, "isStartTypingPlaceholderVisible", {
        /**
         * Placeholder для приглашения ввода для начала поиска виден?
         */
        get: function () {
            return !!((!this.options || this.options.length === 0)
                && this.searchFn
                && !this.loading
                && !this.infinityLoading
                && !this.loaded
                && (!this.searchControl.value || !this.searchLengthReached));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SingleSelectComponent.prototype, "isSelectedValueExists", {
        /**
         * Вариант с выбранным значением нужно отображать?
         */
        get: function () {
            var result = !!this.searchFn;
            if (this.selectedValue instanceof Object) {
                result = result && this.selectedValue.id != undefined;
            }
            else {
                result = result && this.selectedValue != undefined;
            }
            return result;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SingleSelectComponent.prototype, "isNoSearchResultsVisible", {
        /**
         * Текст, что ничего не найдено виден?
         */
        get: function () {
            return (!this.isStartTypingPlaceholderVisible
                && !this.loading
                && !this.infinityLoading
                && !this.loaded
                && this.searchFn
                && this.searchControl.value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SingleSelectComponent.prototype, "panelClass", {
        /**
         * Возвращает класс для панели вариантов выбора.
         */
        get: function () {
            var mainClass = "single-select-panel";
            var result = mainClass;
            if (this.longPanel) {
                result += " " + mainClass + "_long";
            }
            return result;
        },
        enumerable: true,
        configurable: true
    });
    //endregion
    //region Public
    /**
     * Возвращает текстовое представление варианта для выбора.
     *
     * @param option Вариант для выбора.
     */
    SingleSelectComponent.prototype.getOptionText = function (option) {
        var text = '';
        if (this.formatOption) {
            text = this.formatOption(option);
        }
        else {
            text = option[this.optionField];
        }
        if (this.searchControl.value) {
            text = this._utilService.highlight(text, this.searchControl.value, "highlight");
        }
        return text;
    };
    /**
     * Возвращает текстовое представление выбранного варианта.
     *
     * @param selected Выбранный вариант.
     */
    SingleSelectComponent.prototype.getResultText = function (selected) {
        var text = '';
        if (this.formatResult) {
            text = this.formatResult(selected);
        }
        else {
            text = selected[this.resultField];
        }
        return text;
    };
    /**
     * Возвращает значение варианта для выбора, которое будет использоваться как значение всего контрола.
     *
     * @param option Вариант для выбора.
     */
    SingleSelectComponent.prototype.getOptionValue = function (option) {
        var value = option;
        if (this.valueField) {
            value = option[this.valueField];
        }
        return value;
    };
    /**
     * Возвращает значение для варианта выбора обозначающего 'null'-значение.
     */
    SingleSelectComponent.prototype.getNullOptionValue = function () {
        var value = {
            id: 'null'
        };
        if (this.valueField) {
            value = 'null';
        }
        return value;
    };
    /**
     * Открывает выпадашку, подставляет заданную поисковую строку и запускает поиск.
     *
     * @param search Поисковая строка.
     */
    SingleSelectComponent.prototype.openAndSearch = function (search) {
        this.matSelect.open();
        this.selectSearch.searchSelectInput.nativeElement.value = search;
        this.searchControl.setValue(search);
    };
    //endregion
    //region Events
    /**
     * Обработчик клика очистки значения выбранного в выпадашке.
     */
    SingleSelectComponent.prototype.clearBtnClickHandler = function (event) {
        this.valueControl.reset(null);
        // Предотвращаем дальнейшее всплытие, т.к. иначе выпадашка откроется.
        event.stopPropagation();
    };
    /**
     * Обработчик нажатия кнопок в поле поиска.
     */
    SingleSelectComponent.prototype.keydownHandler = function (event) {
        // Кнопки HOME и END останавливаем от всплытия, т.к. иначе mat-select сделает event.preventDefault()
        // для них, что не даст в поле поиска перемещатся в начало или конец поля.
        if (event.keyCode === 35 || event.keyCode === 36) {
            event.stopPropagation();
        }
    };
    //endregion
    //region Private
    /**
     * Фильтрует варианты для выбора согласно поиковой строке.
     *
     * @param search Поисковая строка.
     */
    SingleSelectComponent.prototype.filterOptions = function (search) {
        var _this = this;
        search = search.toLowerCase();
        return this.options.filter(function (option) { return _this.getOptionText(option).toLowerCase().includes(search); });
    };
    /**
     * Включает или отключает контрол в зависимости от входящих данных.
     */
    SingleSelectComponent.prototype._disableOrEnableControl = function () {
        if (this._selectDisabled) {
            this.valueControl.disable();
        }
        else {
            if (this._disableOnEmptyOptions
                && (!this.options || this.options.length === 0)
                && !this.searchFn) {
                this.valueControl.disable();
            }
            else {
                this.valueControl.enable();
            }
        }
    };
    return SingleSelectComponent;
}());
export { SingleSelectComponent };
