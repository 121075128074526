import { Action } from "@ngrx/store";
import { ApiResponse } from "src/app/common/models/api-response.model";
import { DocumentDTO } from "src/app/common/models/document-dto";
import { PageDTO } from "src/app/common/models/page-dto.model";
import { OperatorActivity } from "src/app/operator/models/operator-activity.model";
import { ParseByResult } from "src/app/operator/models/parse-by-result";
import { QueuedDocument } from "src/app/operator/models/queued-document";
import { RecognizedDataDTO } from "src/app/operator/models/recognized-data/recognized-data-dto";
import { DropPagesAfterPageProps } from "src/app/operator/store/actions/props/operator/drop-pages-after-page.action-props";
import { ApiResponsePayloadAction } from "src/app/root/store/actions/api-response-payload-action.model";

/**
 * Типы событий, связанные с работой оператора.
 */
export enum OperatorActionType {

    LOAD_FOR_EDIT = "[Operator] Load document for edit",
    LOAD_FOR_EDIT_SUCCESS = "[Operator] Load document for edit Success",
    LOAD_FOR_EDIT_FAIL = "[Operator] Load document for edit Fail",

    ACQUIRE = "[Operator] Acquire document",
    ACQUIRE_NEW_SUCCESS = "[Operator] Acquire new document Success",
    ACQUIRE_OLD = "[Operator] Acquired document that was previously sent",
    OPEN_SPLITTING = "[Operator] Open splitting",
    DOCUMENT_SPLITTED_SUCCESS = "[Operator] Document splitted success",
    DOCUMENT_SPLITTED_AND_STOP_SUCCESS = "[Operator] Document splitted and stop success",

    PARSE_BY_MARKUP = "[Operator] Parse by markup",
    PARSE_BY_MARKUP_SUCCESS = "[Operator] Parse by markup Success",
    PARSE_BY_MARKUP_FAIL = "[Operator] Parse by markup Fail",

    PARSE_BY_TYPE = "[Operator] Parse by type",
    PARSE_BY_TYPE_SUCCESS = "[Operator] Parse by type Success",
    PARSE_BY_TYPE_FAIL = "[Operator] Parse by type Fail",

    CHANGE_CURRENT_TABLE_PAGE = "[Operator] Change current table page",

    RECOGNIZED_DATA = "[Operator] Recognized data",
    RECOGNIZED_DATA_SUCCESS = "[Operator] Recognized data Success",
    RECOGNIZED_DATA_FAIL = "[Operator] Recognized data Fail",

    SAVE_DATA = "[Operator] Save intermediate state",
    SAVE_DATA_SUCCESS = "[Operator] Save intermediate state Success",
    SAVE_DATA_FAIL = "[Operator] Save intermediate state Fail",
    SAVE_DATA_CLEAR = "[Operator] Save intermediate state Clear",

    FINISH_AND_NEXT = "[Operator] Finish current document and acquire next",
    FINISH_AND_NEXT_SUCCESS = "[Operator] Finish current document and acquire next Success",
    FINISH_AND_STOP = "[Operator] Finish current document and stop",
    FINISH_AND_STOP_SUCCESS = "[Operator] Finish current document and stop Success",
    FINISH_FAILED = "[Operator] Finish current document Failed",
    FINISH_FAILED_NEED_RETRY = "[Operator] Finish current document failed, retrying",

    UPDATE_AFTER_EDIT = "[Operator] Update document after edit",
    UPDATE_AFTER_EDIT_SUCCESS = "[Operator] Update document after edit Success",
    UPDATE_AFTER_EDIT_FAILED = "[Operator] Update document after edit Failed",

    GOT_BLOCKING_DOCUMENT = "[Operator] Got blocking document",
    TAKE_BLOCKING_COUNT_DOWN = "[Operator] Countdown before automatic loading of blocking document",
    LOAD_BLOCKING = "[Operator] Load blocking document",

    CHANGE_PAGES = "[Operator] Change document pages",
    ADD_PAGE_TO_VIEWED_PAGES = "[Operator] Add page to viewed pages",

    BACK_BEFORE_NEWBIE_DATA = "[Operator] Back before newbie data",
    RETURN_NEWBIE_DATA = "[Operator] Return newbie data",

    STOP_LOCAL_LOADING = "[Operator] Stop local loading",

    STOP_WAITING = "[Operator] Stop waiting document acquiring",
    CLEAR_ERROR = "[Operator] Clear error",

    ADD_ACTIVITY = "[Operator] Add UI activity to log",
    REMOVE_ACTIVITIES = "[Operator] Remove UI activities from log",
    SAVE_ACTIVITIES = "[Operator] Save UI activities",
    SAVE_ACTIVITIES_SUCCESS = "[Operator] UI activities saved",
    SAVE_ACTIVITIES_FAILED = "[Operator] UI activities saving failed",

    DROP_PAGES_AFTER_PAGE = "[Operator] Drop page after page",

    CHANGE_PAGE_VIEWER_VISIBILITY = "[Operator] Change page viewer visibility",
}

/**
 * Событие, требующее загрузки документа для редактирования.
 */
export class OperatorLoadForEditAction implements Action {
    readonly type = OperatorActionType.LOAD_FOR_EDIT;
    constructor(public payload: string) { }
}

/**
 * Событие успешной загрузки документа для редактирования.
 */
export class OperatorLoadForEditSuccessAction implements Action {
    readonly type = OperatorActionType.LOAD_FOR_EDIT_SUCCESS;
    constructor(public payload: QueuedDocument) { }
}

/**
 * Событие неудачной загрузки документа для редактирования.
 */
export class OperatorLoadForEditFailAction implements ApiResponsePayloadAction {
    readonly type = OperatorActionType.LOAD_FOR_EDIT_FAIL;
    constructor(public payload: ApiResponse) { }
}

/**
 * Событие, требующее подписаться на получение документов на обработку для оператора.
 */
export class OperatorAcquireAction implements Action {
    readonly type = OperatorActionType.ACQUIRE;
}

/**
 * Событие успешного взятия из очереди нового документа на обработку для оператора.
 */
export class OperatorAcquireNewSuccessAction implements Action {
    readonly type = OperatorActionType.ACQUIRE_NEW_SUCCESS;
    constructor(public payload: QueuedDocument) { }
}

/**
 * Событие когда полученный на обработку документ оказался тем же самым, что оператор пытался отправить ранее.
 */
export class OperatorAcquireOldAction implements Action {
    readonly type = OperatorActionType.ACQUIRE_OLD;
}

/**
 * Событие открытия интерфейса расклейки.
 */
export class OperatorOpenSplittingSuccess implements Action {
    readonly type = OperatorActionType.OPEN_SPLITTING;
    constructor(public payload: QueuedDocument) { }
}

/**
 * Событие успешной расклейки документа.
 */
export class OperatorDocumentSplittedSuccess implements Action {
    readonly  type = OperatorActionType.DOCUMENT_SPLITTED_SUCCESS;
    constructor(public queuedDocumentId: string) { }
}

/**
 * Событие успешной расклейки и остановки документа.
 */
export class OperatorDocumentSplittedAndStopSuccess implements Action {
    readonly  type = OperatorActionType.DOCUMENT_SPLITTED_AND_STOP_SUCCESS;
    constructor(public queuedDocumentId: string) { }
}

/**
 * Событие, требующее выполнения извлечения данных документа по разметке.
 */
export class OperatorParseByMarkupAction implements Action {
    readonly type = OperatorActionType.PARSE_BY_MARKUP;
    constructor(public documentId: string, public markup: DocumentDTO) { }
}

/**
 * Событие успешного выполнения извлечения данных документа по разметке.
 */
export class OperatorParseByMarkupSuccessAction implements Action {
    readonly type = OperatorActionType.PARSE_BY_MARKUP_SUCCESS;
    constructor(public payload: ParseByResult) { }
}

/**
 * Событие неудачного выполнения извлечения данных документа по разметке.
 */
export class OperatorParseByMarkupFailAction implements ApiResponsePayloadAction {
    readonly type = OperatorActionType.PARSE_BY_MARKUP_FAIL;
    constructor(public payload: ApiResponse) { }
}

/**
 * Событие, требующее выполнение извлечения данных документа по типу.
 */
export class OperatorParseByTypeAction implements Action {
    readonly type = OperatorActionType.PARSE_BY_TYPE;
    constructor(public documentId: string, public data: DocumentDTO) { }
}

/**
 * Событие успешного выполнение извлечения данных документа по типу.
 */
export class OperatorParseByTypeSuccessAction implements Action {
    readonly type = OperatorActionType.PARSE_BY_TYPE_SUCCESS;
    constructor(public payload: ParseByResult) { }
}

/**
 * Событие неудачного выполнение извлечения данных документа по типу.
 */
export class OperatorParseByTypeFailAction implements ApiResponsePayloadAction {
    readonly type = OperatorActionType.PARSE_BY_TYPE_FAIL;
    constructor(public payload: ApiResponse) { }
}

/**
 * Событие изменения текущей страницы таблицы.
 */
export class OperatorChangeCurrentTablePage implements Action {
    readonly type = OperatorActionType.CHANGE_CURRENT_TABLE_PAGE;
    constructor(public pageIndex: number) { }
}

/**
 * Событие, требующее выполнения загрузки распознанных данных.
 */
export class OperatorRecognizedDataAction implements Action {
    readonly type = OperatorActionType.RECOGNIZED_DATA;
    constructor(public documentId: string) { }
}

/**
 * Событие успешного выполнения загрузки распознанных данных.
 */
export class OperatorRecognizedDataSuccessAction implements Action {
    readonly type = OperatorActionType.RECOGNIZED_DATA_SUCCESS;
    constructor(public payload: RecognizedDataDTO) { }
}

/**
 * Событие неудачного выполнения загрузки распознанных данных.
 */
export class OperatorRecognizedDataFailAction implements ApiResponsePayloadAction {
    readonly type = OperatorActionType.RECOGNIZED_DATA_FAIL;
    constructor(public payload: ApiResponse) { }
}

/**
 * Событие, требующее сохранения промежуточного результата обработки документа.
 */
export class OperatorSaveDataAction implements Action {
    readonly type = OperatorActionType.SAVE_DATA;
    constructor(public documentId: string, public data: DocumentDTO) { }
}

/**
 * Событие успешного сохранения промежуточного результата обработки документа.
 */
export class OperatorSaveDataSuccessAction implements Action {
    readonly type = OperatorActionType.SAVE_DATA_SUCCESS;
}

/**
 * Событие неудачного сохранения промежуточного результата обработки документа.
 */
export class OperatorSaveDataFailAction implements ApiResponsePayloadAction {
    readonly type = OperatorActionType.SAVE_DATA_FAIL;
    constructor(public payload: ApiResponse) { }
}

/**
 * Событие, требующее очистки состояния о сохранении промежуточного результата обработки документа.
 */
export class OperatorSaveDataClearAction implements Action {
    readonly type = OperatorActionType.SAVE_DATA_CLEAR;
}

/**
 * Событие, требующее завершения обработки документа и взятия из очереди следующего документа.
 */
export class OperatorFinishAndNextAction implements Action {
    readonly type = OperatorActionType.FINISH_AND_NEXT;
    constructor(public payload: QueuedDocument) { }
}

/**
 * Событие успешного завершения обработки документа, после которого нужно взять из очереди следующий документ.
 */
export class OperatorFinishAndNextSuccessAction implements Action {
    readonly type = OperatorActionType.FINISH_AND_NEXT_SUCCESS;
}

/**
 * Событие, требующее завершения обработки документа и приостановки работы оператора.
 */
export class OperatorFinishAndStopAction implements Action {
    readonly type = OperatorActionType.FINISH_AND_STOP;
    constructor(public payload: QueuedDocument) { }
}

/**
 * Событие успешного завершения обработки документа, после которого нужно остановить работу оператора.
 */
export class OperatorFinishAndStopSuccessAction implements Action {
    readonly type = OperatorActionType.FINISH_AND_STOP_SUCCESS;
}

/**
 * Событие неудачного завершения обработки документа.
 */
export class OperatorFinishFailAction implements ApiResponsePayloadAction {
    readonly type = OperatorActionType.FINISH_FAILED;
    constructor(public payload: ApiResponse) { }
}

/**
 * Событие неудачного завершения обработки документа.
 */
export class OperatorFinishFailNeedRetryAction implements Action {
    readonly type = OperatorActionType.FINISH_FAILED_NEED_RETRY;
}

/**
 * Событие, требующее обновление документа после редактирования оператором.
 */
export class OperatorUpdateAfterEditAction implements Action {
    readonly type = OperatorActionType.UPDATE_AFTER_EDIT;
    constructor(public payload: QueuedDocument) { }
}

/**
 * Событие удачного обновления документа после редактирования оператором.
 */
export class OperatorUpdateAfterEditSuccessAction implements Action {
    readonly type = OperatorActionType.UPDATE_AFTER_EDIT_SUCCESS;
    constructor(public payload: string) { }
}

/**
 * Событие неудачного обновления документа после редактирования оператором.
 */
export class OperatorUpdateAfterEditFailAction implements ApiResponsePayloadAction {
    readonly type = OperatorActionType.UPDATE_AFTER_EDIT_FAILED;
    constructor(public payload: ApiResponse) { }
}

/**
 * Событие успешной загрузки информации о блокирующем документе.
 */
export class OperatorGotBlockingDocumentAction implements Action {
    readonly type = OperatorActionType.GOT_BLOCKING_DOCUMENT;
    constructor(public blockingDocument: QueuedDocument) { }
}

/**
 * Событие обратного отсчёта перед автоматической загрузкой блокирующего документа на обработку.
 */
export class OperatorTakeBlockingCountdownAction implements Action {
    readonly type = OperatorActionType.TAKE_BLOCKING_COUNT_DOWN;
    constructor(public count: number) { }
}

/**
 * Событие, требующее взятия загруженного блокирующего документа из очереди.
 *
 * В событие передаётся состояние текущего документа, чтобы его можно было сохранить.
 */
export class OperatorLoadBlockingAction implements Action {
    readonly type = OperatorActionType.LOAD_BLOCKING;
    constructor(public documentId: string, public data: DocumentDTO) { }
}

/**
 * Событие, требующее изменения набора страниц обрабатываемого документа.
 */
export class OperatorChangePagesAction implements Action {
    readonly type = OperatorActionType.CHANGE_PAGES;
    constructor(public processedData: DocumentDTO, public pages: PageDTO[]) { }
}

/**
 * Событие, требующее добавить номер страницы в просмотренные.
 */
export class OperatorAddPageToViewedPagesAction implements Action {
    readonly type = OperatorActionType.ADD_PAGE_TO_VIEWED_PAGES;
    constructor(public pageNumber: number) { }
}

/**
 * Событие в процессе проверки работы новичка, требующее возвращения к данным документа, с которыми он пришёл
 * в очередь.
 */
export class OperatorBackBeforeNewbieDataAction implements Action {
    readonly type = OperatorActionType.BACK_BEFORE_NEWBIE_DATA;
}

/**
 * Событие в процессе проверки работы новичка, требующее возвращения к данным документа, которые получил
 * оператор новичок.
 */
export class OperatorReturnNewbieDataAction implements Action {
    readonly type = OperatorActionType.RETURN_NEWBIE_DATA;
}

/**
 * Событие, требующее сброса флага операции, выполняющейся локально без запросов к серверу.
 */
export class OperatorStopLocalLoadingAction implements Action {
    readonly type = OperatorActionType.STOP_LOCAL_LOADING;
}

/**
 * Событие, требующее остановки ожидания получения документа из очереди.
 */
export class OperatorStopWaitingAction implements Action {
    readonly type = OperatorActionType.STOP_WAITING;
}

/**
 * Событие, требующее очистки ошибки.
 */
export class OperatorClearErrorAction implements Action {
    readonly type = OperatorActionType.CLEAR_ERROR;
}

/**
 * Событие, требующее добавления активности оператора в лог.
 */
export class OperatorAddActivityAction implements Action {
    readonly type = OperatorActionType.ADD_ACTIVITY;
    constructor(public activity: OperatorActivity) { }
}

/**
 * Событие, требующее удаления активностей оператора из лога.
 */
export class OperatorRemoveActivitiesAction implements Action {
    readonly type = OperatorActionType.REMOVE_ACTIVITIES;
    constructor(public activities: OperatorActivity[]) { }
}

/**
 * Событие, требующее сохранения активностей оператора на сервер.
 */
export class OperatorSaveActivitiesAction implements Action {
    readonly type = OperatorActionType.SAVE_ACTIVITIES;
}

/**
 * Событие успешного сохранения активностей оператора на сервере.
 */
export class OperatorSaveActivitiesSuccessAction implements Action {
    readonly type = OperatorActionType.SAVE_ACTIVITIES_SUCCESS;
    constructor(public activities: OperatorActivity[]) { }
}

/**
 * Событие неудачного сохранения активностей оператора на сервере.
 */
export class OperatorSaveActivitiesFailedAction implements ApiResponsePayloadAction {
    readonly type = OperatorActionType.SAVE_ACTIVITIES_FAILED;
    constructor(public payload: ApiResponse) { }
}

/**
 * Событие, требующее удаления всех страниц после указанной.
 */
export class OperatorDropPageAfterPageAction implements Action {
    readonly type = OperatorActionType.DROP_PAGES_AFTER_PAGE;
    constructor(public props: DropPagesAfterPageProps) { }
}

/**
 * Событие, требующее изменения флага видимости компоненты для просмотра обрабатываемых страниц.
 */
export class ChangePageViewerVisibilityAction implements Action {
    readonly type = OperatorActionType.CHANGE_PAGE_VIEWER_VISIBILITY;
    constructor(public props: boolean) { }
}

/**
 * Тип, объединяющий все события, связанные с работой оператора.
 */
export type OperatorAction =

    | OperatorLoadForEditAction
    | OperatorLoadForEditSuccessAction
    | OperatorLoadForEditFailAction

    | OperatorAcquireAction
    | OperatorAcquireNewSuccessAction
    | OperatorAcquireOldAction
    | OperatorOpenSplittingSuccess
    | OperatorDocumentSplittedSuccess
    | OperatorDocumentSplittedAndStopSuccess

    | OperatorParseByMarkupAction
    | OperatorParseByMarkupSuccessAction
    | OperatorParseByMarkupFailAction

    | OperatorParseByTypeAction
    | OperatorParseByTypeSuccessAction
    | OperatorParseByTypeFailAction

    | OperatorChangeCurrentTablePage

    | OperatorRecognizedDataAction
    | OperatorRecognizedDataSuccessAction
    | OperatorRecognizedDataFailAction

    | OperatorSaveDataAction
    | OperatorSaveDataSuccessAction
    | OperatorSaveDataFailAction
    | OperatorSaveDataClearAction

    | OperatorFinishAndNextAction
    | OperatorFinishAndNextSuccessAction
    | OperatorFinishAndStopAction
    | OperatorFinishAndStopSuccessAction
    | OperatorFinishFailAction
    | OperatorFinishFailNeedRetryAction

    | OperatorUpdateAfterEditAction
    | OperatorUpdateAfterEditSuccessAction
    | OperatorUpdateAfterEditFailAction

    | OperatorGotBlockingDocumentAction
    | OperatorTakeBlockingCountdownAction
    | OperatorLoadBlockingAction

    | OperatorChangePagesAction
    | OperatorAddPageToViewedPagesAction

    | OperatorBackBeforeNewbieDataAction
    | OperatorReturnNewbieDataAction

    | OperatorStopLocalLoadingAction
    | OperatorStopWaitingAction

    | OperatorClearErrorAction

    | OperatorAddActivityAction
    | OperatorRemoveActivitiesAction
    | OperatorSaveActivitiesAction
    | OperatorSaveActivitiesSuccessAction
    | OperatorSaveActivitiesFailedAction
    | OperatorDropPageAfterPageAction

    | ChangePageViewerVisibilityAction
    ;
