var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSelector } from "@ngrx/store";
import { queueStateSelector } from "src/app/operator/store/reducers/";
import { documentTypesSelectors } from "src/app/root/store/selectors/document-types.selector";
var ɵ0 = function (queueState) { return queueState && queueState.operatorState; };
/**
 * Селектор состояния работы оператора.
 */
var state = createSelector(queueStateSelector, ɵ0);
var ɵ1 = function (operatorState) { return operatorState.queuedDocument; };
/**
 * Селектор текущего документа в обработке.
 */
var queuedDocument = createSelector(state, ɵ1);
var ɵ2 = function (operatorState) { return operatorState.currentPageIndex; };
/**
 * Селектор индекса текущей страницы таблицы позиций документа.
 */
var currentTablePageIndex = createSelector(state, ɵ2);
/**
 * Селектор документа с исправленным типом.
 */
var fixedTypeQueuedDocument = createSelector(queuedDocument, documentTypesSelectors.foreignTypes, fixDocumentType);
var ɵ3 = function (document) {
    return !!document && !!document.recognitionSettings && document.recognitionSettings.foreign;
};
/**
 * Селектор флага иностранного документа в обработке.
 */
var foreignDocument = createSelector(queuedDocument, ɵ3);
/**
 * Объект селекторов состояния.
 */
var selectors = {
    state: state,
    queuedDocument: queuedDocument,
    fixedTypeQueuedDocument: fixedTypeQueuedDocument,
    foreignDocument: foreignDocument,
    currentTablePageIndex: currentTablePageIndex,
};
/**
 * Объект селекторов состояния для чтения.
 */
export var operatorStateSelectors = selectors;
/**
 * Подменяем тип у иностранного документа, если у типа распознанного документа foreign = false.
 *
 * @param document Документ в очереди на обработку.
 * @param types Типы документов.
 *
 * @return Документ с подмененным типом.
 */
function fixDocumentType(document, types) {
    var result = document;
    if (document && document.originalData && document.recognitionSettings && document.recognitionSettings.foreign) {
        var foreignType = types
            .filter(function (type) { return type.foreign; })
            .find(function (type) { return document.originalData.type === type.id; });
        if (!foreignType) {
            result = __assign({}, result, { originalData: __assign({}, result.originalData, { type: "UNKNOWN" }) });
        }
    }
    return result;
}
export { ɵ0, ɵ1, ɵ2, ɵ3 };
