<ng-container class="simple-dlg-with-iframe" [class.simple-dlg-with-iframe]="loading">

        <h2 *ngIf="hasHeader" class="mat-h2 simple-dlg-with-iframe__header">{{ headerKey | translate:headerParams }}</h2>
        <button
            *ngIf="hasCloseIcon"
            class="simple-dlg-with-iframe__close"
            (click)="closeClickHandler()"
            mat-icon-button
            color="primary"
        >
            <mat-icon class="mat-icon_large">close</mat-icon>
        </button>

    <iframe title="dlg_iframe" class="simple-dlg-with-iframe__iframe" [src]="iframeUrl | safeUrl"></iframe>
    <hr *ngIf="!loading && hasOkButton" class="simple-dlg-with-iframe__divider"/>
    <div *ngIf="!loading && hasOkButton" class="simple-dlg-with-iframe__buttons">
        <button *ngIf="hasOkButton" mat-button (click)="okClickHandler()">
            {{ okBtnKey | translate }}
        </button>
    </div>
    <mat-spinner [diameter]="64" class="simple-dlg-with-iframe__loading" *ngIf="loading"></mat-spinner>
</ng-container>
