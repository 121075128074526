var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OperatorActionType } from '../actions';
/**
 * Начальное состояние лога активности оператора.
 */
var initialState = { activities: [] };
/**
 * Обработчик событий, связанных с активностью оператора.
 *
 * @param state Состояние лога активности оператора.
 * @param action Событие произошедшее в системе.
 *
 * @return Изменённое или тоже самое состояние лога активности оператора.
 */
export function operatorActivityReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var result = state;
    switch (action.type) {
        // На требование добавления активности оператора в лог.
        case OperatorActionType.ADD_ACTIVITY: {
            result = __assign({}, result, { activities: result.activities.concat([
                    action.activity,
                ]) });
            break;
        }
        // На требование удаления активностей оператора из лога.
        case OperatorActionType.REMOVE_ACTIVITIES: {
            var activities = result.activities
                .filter(function (activity) { return action.activities.indexOf(activity) === -1; });
            result = __assign({}, result, { activities: activities });
            break;
        }
    }
    return result;
}
