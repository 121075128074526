import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HttpErrorResponse } from "@angular/common/http";

import { Observable } from "rxjs";
import { throwError } from "rxjs/internal/observable/throwError";
import { catchError } from "rxjs/operators";
import { map } from "rxjs/operators";

import { Credentials } from "../../root/models";
import { CurrentUserResponse } from "../../common/models";
import { ApiResponse } from "../../common/models";
import { ErrorUtils } from "../../common/utils";
import { UrlUtils } from "../../common/utils";

/**
 * Сервис для входа в систему.
 */
@Injectable({
    providedIn: "root"
})
export class LoginService {
    //region Fields

    /**
     * HTTP-клиент.
     */
    private readonly _http: HttpClient;

    //endregion
    //region Ctor

     /**
     * Конструктор сервиса для входа в систему.
     *
     * @param http HTTP-клиент.
     */
    constructor(http: HttpClient) {

        this._http = http;
    }

    //endregion
    //region Public

    /**
     * Выполняет вход в систему.
     *
     * @param credentials Учетные данные для входа в систему.
     *
     * @return Успех выполнения входа.
     */
    signIn(credentials: Credentials): Observable<boolean> {

        return this._http
            .post<CurrentUserResponse>(UrlUtils.loginApiUrl(), credentials)
            .pipe(
                map((response: ApiResponse): boolean => response.result),
                catchError((response: HttpErrorResponse): Observable<never> => throwError(ErrorUtils.from(response))),
            );
    }

    /**
     * Выполняет выход из системы.
     *
     * @return Успех выполнения выхода.
     */
    signOut(): Observable<boolean> {

        return this._http
            .post<void>(UrlUtils.logoutApiUrl(), null)
            .pipe(
                map((): boolean => true),
                catchError((response: HttpErrorResponse) => throwError(ErrorUtils.from(response))),
            );
    }

    //endregion
}

