/**
 * Адреса документов, содержащих конкретную информацию.
 */
export interface NotificationUrls {
    //region Fields

    /**
     * Адрес для доступа к информации о работе с расширенными типами документов.
     */
    readonly extendedTypesInfoUrl: string;

    /**
     * Адрес для доступа к информации о работе с кодом платежного поручения.
     */
    readonly paymentOrderInfoUrl: string;

    /**
     * Адрес для доступа к информации о работе с полем 5А.
     */
    readonly field5AInfoUrl: string;

    /**
     * Адрес для доступа к информации о работе с полем артикула.
     */
    readonly goodCodeInfoUrl: string;

    /**
     * Адрес для доступа к информации о функции "Работа только с входящими
     * документами".
     */
    readonly inboundDocumentsOnly: string;

    /**
     * Адрес для доступа к информации о плашке "Важный".
     */
    readonly importantDocumentUrl: string;

    /**
     * Адрес для доступа к информации о плашке "Иностранный".
     */
    foreignDocument: string;

    /**
     * Адрес для доступа к информации о плашке "Казахстан".
     */
    kazakhDocumentUrl: string;

    /**
     * Адрес для доступа к информации о плашке "Новичок".
     */
    readonly newbieUrl: string;

    /**
     * Адрес для доступа к информации о плашке "XLS".
     */
    readonly xlsUrl: string;

    /**
     * Адрес для доступа к информации о плашке "После расклейки".
     */
    readonly afterGluingUrl: string;

    /**
     * Адрес для доступа к информации о плашке "Возможна склейка".
     */
    readonly possibleGluing: string;

    //endregion
}

/**
 * Стандартные адреса для доступа к информации.
 */
export const defaultNotificationUrls: NotificationUrls = {
    extendedTypesInfoUrl: "https://docs.google.com/document/d/1cRIgSy0mq5aR_T7ZWa2XF12sX9OAaP8qQHroneMTir4/edit",
    paymentOrderInfoUrl: "https://docs.google.com/document/d/1LsDK2nH1aExHJ1IMGQ-aWk_wSJFWojAFWuj4CIz27r0/edit",
    field5AInfoUrl: "https://docs.google.com/document/d/1YnvxjOqybmX6HWUxvhLmsxsIeObIupDDSZrwBvLVxt4/edit",
    goodCodeInfoUrl: "https://docs.google.com/document/d/1dR7iWAyhBH9Dae4gGpYguJqFE0lGgtxlAW7dsZ7o2qE/edit",
    inboundDocumentsOnly: "https://docs.google.com/document/d/1p8LaYIXuE6wHd6eDXurnkKxV-2p4lsG4qHlprZx-jvY/edit",
    importantDocumentUrl: "https://docs.google.com/document/d/1sbwJ75eUIafJ7NtF8WMONeI4Q2aCEs4xQnChxnFf6NA/edit",
    foreignDocument: "https://docs.google.com/document/d/17sQUoVLsAXF98nEnGS8vC2KxVB65FIPXDDUtrj_azbY/edit",
    kazakhDocumentUrl: "https://docs.google.com/document/d/18C7TmTh-qeXKAH7CrOnL9FaJ4qn2Xt-ljQ0cYteIrYc/edit",
    newbieUrl: "https://docs.google.com/document/d/1_PsVhUZGujRDo0Usiwu2RrBarfJ6mori7pUOiqT4z8M/edit?usp=sharing",
    xlsUrl: "https://docs.google.com/document/d/1OCTcjW5So0kGdqzEd-FBgoUwolPOsyl9x3n50cHtssc/edit?usp=sharing",
    afterGluingUrl: "https://docs.google.com/document/d/1tozdC-WmDHN1wgl6xK5ju0a0q0O-Bc-_dbC3Q_FWw_M/edit?usp=sharing",
    possibleGluing: "https://docs.google.com/document/d/1F5ZddUDrbpo8kn3OBkNqbSK8k6gJNdhfyKwj93orL2Y/edit?usp=sharing",
};
