import { props } from "@ngrx/store";
import { createAction } from "@ngrx/store";
import { ApiResponse } from "src/app/common/models/index";
import { CurrenciesActionProps } from "src/app/root/store/actions/props/currencies.action-props";

/**
 * Типы событий, связанные с валютами
 */
export enum CurrenciesActionType {
    LOAD = "[ROOT] Load currencies",
    LOAD_SUCCESS = "[ROOT] Load currencies Success",
    LOAD_FAIL = "[ROOT] Load currencies Fail",
}

/**
 * Событие, требующее загрузки списка валют.
 */
const currenciesLoad = createAction(
    CurrenciesActionType.LOAD
);

/**
 * Событие успешной загрузки списка валют.
 */
const currenciesLoadSuccess = createAction(
    CurrenciesActionType.LOAD_SUCCESS,
    props<CurrenciesActionProps>(),
);

/**
 * Событие неудачной загрузки списка валют.
 */
const currenciesLoadFail = createAction(
    CurrenciesActionType.LOAD_FAIL,
    props<ApiResponse>(),
);

/**
 * Тип, объединяющий все события, связанные со списком валют.
 */
export type CurrenciesAction =
    | ReturnType<typeof currenciesLoad>
    | ReturnType<typeof currenciesLoadSuccess>
    | ReturnType<typeof currenciesLoadFail>;

/**
 * Все события связанные с валютами.
 */
export const currenciesActions = {
    currenciesLoad: currenciesLoad,
    currenciesLoadSuccess: currenciesLoadSuccess,
    currenciesLoadFail: currenciesLoadFail,
};
