import { EventEmitter } from '@angular/core';
/**
 * Компонент навигации по страницам.
 *
 * Навигация начинается 0, но отображение идет с 1 для удобства читаемости.
 */
var PageNavigationComponent = /** @class */ (function () {
    function PageNavigationComponent() {
        //region Inputs
        //endregion
        //region Outputs
        /**
         * Исходящее событие - текущая страница изменилась.
         */
        this.pageChange = new EventEmitter();
        //endregion
        //region Private fields
        /**
         * Текущий индекс навигации.
         */
        this._index = 0;
        /**
         * Общее количетво для навигации.
         */
        this._count = 0;
        /**
         * Общее количетво видимых номеров слева и справа.
         */
        this._visible = 3;
        //endregion
        //region Public fields
        /**
         * Список страниц слева от текущей страницы (страниы до текущей).
         */
        this.left = [];
        /**
         * Список страниц справа от текущей страницы (страниы после текущей).
         */
        this.right = [];
        //endregion
    }
    Object.defineProperty(PageNavigationComponent.prototype, "index", {
        //endregion
        //region Getters and Setters
        /**
         * Текущий индекс навигации.
         */
        get: function () {
            return this._index;
        },
        /**
         * Входящие данные: текущий индекс навигации.
         */
        set: function (value) {
            this._index = value;
            this._update();
        },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(PageNavigationComponent.prototype, "count", {
        /**
         * Общее количетво для навигации.
         */
        get: function () {
            return this._count;
        },
        /**
         * Входящие данные: общее количетво для навигации.
         */
        set: function (value) {
            this._count = value;
            this._update();
        },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(PageNavigationComponent.prototype, "visible", {
        /**
         * Общее количетво видимых номеров слева и справа.
         */
        get: function () {
            return this._visible;
        },
        /**
         * Входящие данные: общее количетво видимых номеров слева и справа.
         */
        set: function (value) {
            this._visible = value;
            this._update();
        },
        enumerable: true,
        configurable: true
    });
    ;
    //endregion
    //region Events
    /**
     * Обработчик клика по номеру страницы.
     *
     * @param pageNumber Номер страницы.
     */
    PageNavigationComponent.prototype.pageClickHandler = function (pageNumber) {
        if (pageNumber !== -1) {
            this.pageChange.emit(pageNumber);
        }
    };
    /**
     * Обработчик клика по кнопке перехода к первой странице.
     */
    PageNavigationComponent.prototype.firstPageClickHandler = function () {
        this.pageChange.emit(0);
    };
    /**
     * Обработчик клика по кнопке перехода к предыдущей странице.
     */
    PageNavigationComponent.prototype.prevPageClickHandler = function () {
        if (this.index > 0) {
            this.pageChange.emit(this.index - 1);
        }
    };
    /**
     * Обработчик клика по кнопке перехода к следующей странице.
     */
    PageNavigationComponent.prototype.nextPageClickHandler = function () {
        if (this.index < this.count - 1)
            this.pageChange.emit(this.index + 1);
    };
    /**
     * Обработчик клика по кнопке перехода к последней странице.
     */
    PageNavigationComponent.prototype.lastPageClickHandler = function () {
        this.pageChange.emit(this.count - 1);
    };
    //endregion
    //region Private
    /**
     * Выполняет обновление состояния компонента на основе изменений.
     */
    PageNavigationComponent.prototype._update = function () {
        this.left = [];
        var leftMinPage = Math.max(0, this._index - this._visible);
        for (var i = leftMinPage; i < this._index; i++) {
            this.left.push(i);
        }
        // Оставшиеся места, если они есть, заполняем -1, чтобы просто занять место на странице, чтобы контрол
        // всегда стоял ровно на своём месте.
        var delta = this._visible - this.left.length;
        for (var i = 0; i < delta; i++) {
            this.left.unshift(-1);
        }
        this.right = [];
        var rightMaxPage = Math.min(this._count - 1, this._index + this._visible);
        for (var i = this._index + 1; i <= rightMaxPage; i++) {
            this.right.push(i);
        }
        // Оставшиеся места, если они есть, заполняем -1, чтобы просто занять место на странице, чтобы контрол
        // всегда стоял ровно на своём месте.
        delta = this._visible - this.right.length;
        for (var i = 0; i < delta; i++) {
            this.right.push(-1);
        }
    };
    return PageNavigationComponent;
}());
export { PageNavigationComponent };
