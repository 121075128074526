import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { select } from "@ngrx/store";
import { of } from "rxjs";
import { filter } from "rxjs/operators";
import { map } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { userAndLoginStatesSelector } from "rootStore";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "@angular/router";
/**
 * Route guard, который проверяет возможность входа на URL в зависимости от наличия аутентифицированного пользователя.
 *
 * Если текущего пользователя нет, то переход по URL будет возможен, если текущий пользователь есть, то невозможен.
 */
var NoUserGuard = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор route guard, который проверяет возможность входа на URL в зависимости от наличия
     * аутентифицированного пользователя.
     *
     * @param store Сервис для управления и доступа к состоянию приложения.
     * @param router Сервис для работы с навигацией по приложению.
     */
    function NoUserGuard(store, router) {
        this._store = store;
        this._router = router;
    }
    //endregion
    //region Public
    /**
     * Проверяет, можно ли осуществлять переход по текущему состоянию навигации и, если нельзя, то перенаправляет на
     * главную страницу.
     *
     * Если текущего пользователя нет, то переход по URL будет возможен, если текущий пользователь есть, то невозможен.
     *
     * @param route Состояние текущего активированного URL'а.
     * @param state Состояние навигации.
     *
     * @return Да, если можно осуществить переход по текущему состоянию навигации и, если нельзя, то перенаправляет на
     * главную страницу.
     */
    NoUserGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        return this._getCurrentUserState()
            .pipe(map(function (userState) { return userState.currentUserInfo; }), switchMap(function (user) {
            if (user) {
                return of(_this._router.parseUrl("/"));
            }
            else {
                return of(true);
            }
        }));
    };
    /**
     * Проверяет, можно ли осуществлять переход по текущему состоянию навигации и, если нельзя, то перенаправляет на
     * главную страницу.
     *
     * Если текущего пользователя нет, то переход по URL будет возможен, если текущий пользователь есть, то невозможен.
     *
     * @param route Состояние текущего активированного URL'а.
     * @param state Состояние навигации.
     *
     * @return Да, если можно осуществить переход по текущему состоянию навигации и, если нельзя, то перенаправляет на
     * главную страницу.
     */
    NoUserGuard.prototype.canActivateChild = function (route, state) {
        return this.canActivate(route, state);
    };
    //endregion
    //region Private
    /**
     * Возвращает состояние данных текущего аутентифицированного пользователя.
     *
     * Либо дожидается, когда данные текущего аутентифицированного пользователя будут загружены, либо если
     * пользователь выполнил выход из системы.
     *
     * @return Состояние данных текущего аутентифицированного пользователя.
     */
    NoUserGuard.prototype._getCurrentUserState = function () {
        return this._store
            .pipe(select(userAndLoginStatesSelector), filter(function (states) {
            return (states.first.loaded || states.first.failed || !states.second.loggedIn);
        }), map(function (states) { return states.first; }));
    };
    NoUserGuard.ngInjectableDef = i0.defineInjectable({ factory: function NoUserGuard_Factory() { return new NoUserGuard(i0.inject(i1.Store), i0.inject(i2.Router)); }, token: NoUserGuard, providedIn: "root" });
    return NoUserGuard;
}());
export { NoUserGuard };
