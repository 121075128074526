import { props } from "@ngrx/store";
import { createAction } from "@ngrx/store";
import { WebsocketEventType } from "src/app/common/models/websocket/websocket-event-type";
import { WebsocketUtils } from "src/app/common/utils/websocket.utils";
import { ProcessDocumentProps } from "src/app/root/store/actions/props/queued-document.action-props";
import { WebsocketEventTypeProps } from "src/app/root/store/actions/props/websocket-event-type.action-props";

/**
 * Типы событий, связанные с веб сокетом.
 */
export enum WebsocketActionType {
    //region Enum values

    /**
     * Запрос соединение по веб сокету.
     */
    CONNECT = "[Websocket] Connecting over web socket",

    /**
     * Соединение по веб сокету установлено.
     */
    CONNECTED = "[Websocket] Web socket connected",

    /**
     * Разрыв соединения по веб сокету.
     */
    DISCONNECTED = "[Websocket] Web socket disconnected",

    /**
     * Подписка на событие по веб сокету.
     */
    SUBSCRIBE = "[Websocket] WebSocket subscribe to event",

    /**
     * Подписка на событие по веб сокету.
     */
    UNSUBSCRIBE = "[Websocket] WebSocket unsubscribe from event",

    /**
     * Добавление подписки в список подписок.
     */
    ADD_SUBSCRIPTION = "[Websocket] WebSocket add subscription to list",

    //endregion
}

/**
 * Событие запроса соединения по веб сокету.
 */
const connect = createAction(WebsocketActionType.CONNECT);

/**
 * Событие запроса соединения по веб сокету.
 */
const connected = createAction(WebsocketActionType.CONNECTED);

/**
 * Событие отсоединения по веб сокету.
 */
const disconnected = createAction(WebsocketActionType.DISCONNECTED);

/**
 * Событие подписки на события, передаваемое по веб сокету.
 */
const subscribe = createAction(WebsocketActionType.SUBSCRIBE, props<WebsocketEventTypeProps>());

/**
 * Событие отписки на события, передаваемое по веб сокету.
 */
const unsubscribe = createAction(WebsocketActionType.UNSUBSCRIBE, props<WebsocketEventTypeProps>());

/**
 * Событие получения документа для обработки оператором.
 */
const processDocument = createAction(
    WebsocketUtils.getEventActionType(WebsocketEventType.PROCESS_DOCUMENT),
    props<ProcessDocumentProps>(),
);

/**
 * Событие добавление подписки в список подписок.
 *
 * Не посылает запрос на сервер.
 */
const addSubscription = createAction(WebsocketActionType.ADD_SUBSCRIPTION, props<WebsocketEventTypeProps>());

/**
 * Тип, объединяющий все события, связанные с веб сокетом.
 */
export type WebsocketAction =
    | ReturnType<typeof connect>
    | ReturnType<typeof connected>
    | ReturnType<typeof disconnected>
    | ReturnType<typeof subscribe>
    | ReturnType<typeof unsubscribe>
    | ReturnType<typeof addSubscription>
;

/**
 * Все события веб сокета.
 */
export const websocketActions = {
    connect: connect,
    connected: connected,
    disconnected: disconnected,
    subscribe: subscribe,
    unsubscribe: unsubscribe,
    processDocument: processDocument,
    addSubscription: addSubscription,
};
