var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Effect } from "@ngrx/effects";
import { ofType } from "@ngrx/effects";
import { Actions } from "@ngrx/effects";
import { select } from "@ngrx/store";
import { Store } from "@ngrx/store";
import { withLatestFrom } from "rxjs/operators";
import { map } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { WebsocketEventType } from "src/app/common/models/websocket/websocket-event-type";
import { OperatorWebsocketService } from "src/app/common/services/operator-websocket.service";
import { WebsocketUtils } from "src/app/common/utils/websocket.utils";
import { OperatorAcquireOldAction } from "src/app/operator/store/actions/operator.action";
import { OperatorGotBlockingDocumentAction } from "src/app/operator/store/actions/operator.action";
import { OperatorAcquireNewSuccessAction } from "src/app/operator/store/actions/operator.action";
import { operatorStateSelectors } from "src/app/operator/store/selectors/operator.selectors";
import { websocketActions } from "src/app/root/store/actions/websocket.actions";
import { WebsocketActionType } from "src/app/root/store/actions/websocket.actions";
import { environment } from "src/environments/environment";
/**
 * Side-эффекты на события, связанные с веб сокетом.
 */
var WebsocketEffects = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор класса, содержащего в себе side-эффекты на события, связанные с веб сокетом.
     *
     * @param _actions$ Поток событий, происходящих в системе.
     * @param _store$ Хранилище текущего состояния приложения.
     * @param websocketService Сервис веб сокета сервера оператора.
     */
    function WebsocketEffects(_actions$, _store$, websocketService) {
        var _this = this;
        this._actions$ = _actions$;
        this._store$ = _store$;
        //endregion
        //region Effects
        /**
         * Обработка события требования соединения по веб сокету.
         */
        this.connect$ = this._actions$
            .pipe(ofType(WebsocketActionType.CONNECT), tap(function (_) { return _this._websocketService.connect(); }));
        /**
         * Обработка события подписки на событие, передаваемое по веб сокету.
         */
        this.subscribe$ = this._actions$
            .pipe(ofType(WebsocketActionType.SUBSCRIBE), tap(function (eventTypeProps) {
            return _this._websocketService.subscribe(eventTypeProps.eventType);
        }));
        /**
         * Обработка события подписки на событие, передаваемое по веб сокету.
         */
        this.unsubscribe$ = this._actions$
            .pipe(ofType(WebsocketActionType.UNSUBSCRIBE), tap(function (eventTypeProps) {
            return _this._websocketService.unsubscribe(eventTypeProps.eventType);
        }));
        /**
         * Обработка события требования обработки документа, пришедшего по веб сокету.
         */
        this.getDocumentToProcessSuccess$ = this._actions$
            .pipe(ofType(WebsocketUtils.getEventActionType(WebsocketEventType.PROCESS_DOCUMENT)), withLatestFrom(this._store$.pipe(select(operatorStateSelectors.state))), map(function (_a) {
            var action = _a[0], state = _a[1];
            if (action.blocking) {
                return new OperatorGotBlockingDocumentAction(action.document);
            }
            if (action.document
                && state.lastSentDocumentAction
                && action.document.id === state.lastSentDocumentAction.document.id) {
                if (state.failedSendingAttempts < environment.failedSentDocumentRetryAttempts) {
                    return new OperatorAcquireOldAction();
                }
                else {
                    _this._websocketService.close();
                    return websocketActions.unsubscribe({ eventType: WebsocketEventType.PROCESS_DOCUMENT });
                }
            }
            else {
                return new OperatorAcquireNewSuccessAction(action.document);
            }
        }));
        this._websocketService = websocketService;
    }
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], WebsocketEffects.prototype, "connect$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], WebsocketEffects.prototype, "subscribe$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], WebsocketEffects.prototype, "unsubscribe$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], WebsocketEffects.prototype, "getDocumentToProcessSuccess$", void 0);
    return WebsocketEffects;
}());
export { WebsocketEffects };
