import { Injectable } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MatDialog } from "@angular/material/dialog";
import { Constants } from "src/app/common/models/constants.model";
import { ComplaintOnDocumentDlgComponent } from "src/app/root/containers/complaint-on-document-dlg/complaint-on-document-dlg.component";

/**
 * Сервис для работы с диалогом жалобы оператора на документ.
 */
@Injectable({
    providedIn: "root"
})
export class ComplaintOnDocumentDlgService {
    //region Fields

    /**
     * Сервис для работы с диалогами.
     */
    private readonly _dialog: MatDialog;

    //endregion
    //region Ctor

    /**
     * Конструктор для работы с диалогом жалобы оператора на документ.
     *
     * @param dialog Сервис для работы с диалогами.
     */
    constructor(dialog: MatDialog) {

        this._dialog = dialog;
    }

    //endregion
    //region Public

    /**
     * Открывает диалог жалобы оператора на документ.
     *
     * @param documentId ID документа.
     *
     * @return Объект для управления диалогом и компонентом, который находится в диалоге.
     */
    open(documentId: string): MatDialogRef<ComplaintOnDocumentDlgComponent> {

        this._dialog.closeAll();

        return this._dialog.open(ComplaintOnDocumentDlgComponent, {
            panelClass: [Constants.DIALOG_PANEL_CLASS],
            data: { documentId },
            autoFocus: true,
            disableClose: true,
        });
    }

    //endregion
}
