import { Injectable } from "@angular/core";
import { UrlTree } from "@angular/router";
import { RouterStateSnapshot } from "@angular/router";
import { ActivatedRouteSnapshot } from "@angular/router";
import { CanActivateChild } from "@angular/router";
import { CanActivate } from "@angular/router";
import { select } from "@ngrx/store";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { RouteService } from "src/app/root/services/route.service";
import { DocumentTypesState } from "src/app/root/store/reducers/document-types.reducers";
import { documentTypesSelectors } from "src/app/root/store/selectors/document-types.selector";

import { RootState } from "src/app/root/store/states/root.state";

/**
 * Route guard, который проверяет возможность входа на URL в зависимости от загрузки типов документов.
 *
 * Если типы документов есть, то переход по URL возможен, иначе невозможен.
 */
@Injectable({
    providedIn: "root"
})
export class DocumentTypesGuard implements CanActivate, CanActivateChild {
    //region Fields

    /**
     * Сервис для управления и доступа к состоянию приложения.
     */
    private readonly _store: Store<RootState>;

    /**
     * Сервис для выполнения каких-либо переходов по URL'ам.
     */
    private readonly _routeService: RouteService;

    //endregion
    //region Ctor

    /**
     * Конструктор route guard, который проверяет возможность входа на URL в зависимости от загрузки типов документов.
     *
     * @param store Сервис для управления и доступа к состоянию приложения.
     * @param routeService Сервис для выполнения каких-либо переходов по URL'ам.
     */
    constructor(store: Store<RootState>, routeService: RouteService) {

        this._store = store;
        this._routeService = routeService;
    }

    //endregion
    //region Public

    /**
     * Проверяет, можно ли осуществлять переход по текущему состоянию навигации.
     *
     * Если типы документов есть, то переход по URL возможен, иначе невозможен.
     *
     * @param route Состояние текущего активированного URL'а.
     * @param state Состояние навигации.
     *
     * @return Да/нет.
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {

        return this._store
            .pipe(
                select(documentTypesSelectors.state),
                filter((documentTypesState: DocumentTypesState): boolean =>
                    !!documentTypesState.error || !!documentTypesState.documentTypes.length
                ),
                switchMap((documentTypesState: DocumentTypesState): Observable<boolean | UrlTree> => {

                    if (documentTypesState.error) {

                        return of(false);
                    }
                    else {

                        return of(true);
                    }
                }),
                catchError((): Observable<boolean> => {

                    return of(false);
                }),
            );
    }

    /**
     * Проверяет, можно ли осуществлять переход по текущему состоянию навигации.
     *
     * Если типы документов, то переход по URL возможен, иначе невозможен.
     *
     * @param route Состояние текущего активированного URL'а.
     * @param state Состояние навигации.
     *
     * @return Да/нет.
     */
    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {

        return this.canActivate(route, state);
    }

    //endregion
}
