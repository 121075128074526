import { ComplaintOnDocumentDlgComponent } from "src/app/root/containers/complaint-on-document-dlg/complaint-on-document-dlg.component";
import { RootComponent } from "./root/root.component";
import { VersionPageComponent } from "./version-page/version-page.component";

export { RootComponent } from "./root/root.component";
export { VersionPageComponent } from "./version-page/version-page.component";

/**
 * Умные компоненты, которые создаются в runtime'е.
 */
export const entryContainers = [
    ComplaintOnDocumentDlgComponent,
];

/**
 * Все умные компоненты корневого модуля.
 */
export const containers = [
    RootComponent,
    VersionPageComponent,
    ...entryContainers
];
