import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Observable } from "rxjs";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { ApiResponse } from "src/app/common/models/api-response.model";
import { Country } from "src/app/common/models/country.model";
import { CountriesService } from "src/app/root/services/countries.service";
import { countriesActions } from "src/app/root/store/actions/countries.action";
import { CountriesAction } from "src/app/root/store/actions/countries.action";
import { CountriesActionType } from "src/app/root/store/actions/countries.action";

/**
 * Side-эффекты на события, связанные со странами.
 */
@Injectable()
export class CountriesEffects {
    //region Fields

    /**
     * Сервис для работы со списком стран.
     */
    private readonly _countriesService: CountriesService;

    //endregion
    //region Ctor

    /**
     * Конструктор эффектов на события, относящиеся к странам.
     *
     * @param _actions$ События.
     * @param countriesService Сервис для работы со списком стран.
     */
    constructor(
        private _actions$: Actions,
        countriesService: CountriesService,
    ) {
        this._countriesService = countriesService;
    }

    //endregion
    //region Public

    /**
     * Выполнение загрузки списка стран.
     */
    @Effect()
    loadCountries$ = this._actions$
        .pipe(
            ofType(CountriesActionType.LOAD),
            switchMap(() => this._countriesService.get()
                .pipe(
                    map((countries: Country[]): CountriesAction =>
                        countriesActions.countriesLoadSuccess({ countries })
                    ),
                    catchError((apiResponse: ApiResponse): Observable<CountriesAction> =>
                        of(countriesActions.countriesLoadFail(apiResponse))
                    ),
                ),
            )
        );

    //endregion
}
