var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CustomerEditActionType } from "src/app/operator/store/actions/company/customer-edit";
import { SupplierEditActionType } from "src/app/operator/store/actions/company/supplier-edit";
import { CompanySubmissionState } from "src/app/operator/store/reducers/company-submission.state";
/**
 * Начальное состояние по данным редактирования компании.
 */
var initialState = new CompanySubmissionState();
/**
 * Обработка состояния редактирования компании поставщика.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 *
 * @return Состояние редактирования компании поставщика.
 */
export function supplierEditReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var result = state;
    switch (action.type) {
        case SupplierEditActionType.EDIT: {
            result = __assign({}, state, { company: action.payload, loading: true, loaded: false, failed: false, errorData: null });
            break;
        }
        case SupplierEditActionType.EDIT_SUCCESS: {
            result = __assign({}, state, { company: action.payload, loaded: true, loading: false });
            break;
        }
        case SupplierEditActionType.EDIT_FAIL: {
            result = __assign({}, state, { loaded: false, loading: false, failed: true, errorData: action.payload });
            break;
        }
    }
    return result;
}
/**
 * Обработка состояния редактирования компании покупателя.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 *
 * @return Состояние редактирования компании покупателя.
 */
export function customerEditReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var result = state;
    switch (action.type) {
        case CustomerEditActionType.EDIT: {
            result = __assign({}, state, { company: __assign({}, action.payload), loading: true, loaded: false, failed: false, errorData: null });
            break;
        }
        case CustomerEditActionType.EDIT_SUCCESS: {
            result = __assign({}, state, { company: __assign({}, action.payload), loaded: true, loading: false });
            break;
        }
        case CustomerEditActionType.EDIT_FAIL: {
            result = __assign({}, state, { loaded: false, loading: false, failed: true, errorData: __assign({}, action.payload) });
            break;
        }
    }
    return result;
}
/**
 * Возвращает данные компании.
 *
 * @param state Состояние данных редактируемой компании.
 */
export var getCompany = function (state) { return state.company; };
/**
 * Возвращает флаг об успешном редактировании компании.
 *
 * @param state Состояние данных редактируемой компании.
 */
export var getCompanyEdited = function (state) { return state.loaded; };
/**
 * Возвращает флаг выполнения загрузки для создания компании.
 *
 * @param state Состояние данных редактируемой компании.
 */
export var getCompanyEditLoading = function (state) { return state.loading; };
/**
 * Редактирование компании завершилось ошибкой.
 *
 * @param state Состояние данных редактируемой компании.
 */
export var getCompanyCreatedFailed = function (state) { return ({ failed: state.failed, errorData: state.errorData }); };
