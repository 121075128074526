import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ApiResponse } from "src/app/common/models/api-response.model";
import { DocumentTypesResponse } from "src/app/common/models/api-responses/document-types-response";
import { OperatorDocumentType } from "src/app/common/models/document-type";


/**
 * Сервис для работы с типами документов.
 */
@Injectable({
    providedIn: "root"
})
export class DocumentTypesService {
    // region Fields

    /**
     * HTTP-клиент.
     */
    private readonly _httpClient: HttpClient;

    //endregion
    //region Ctor

    /**
     * Конструктор сервиса для работы с типами документов.
     *
     * @param httpClient HTTP клиент.
     */
    constructor(httpClient: HttpClient) {

        this._httpClient = httpClient;
    }

    //endregion
    //region Public

    /**
     * Загрузка типов документов с сервера.
     */
    getDocumentTypes(): Observable<OperatorDocumentType[]> {

        return this._httpClient.get("/api/v1/processing/documentTypes")
            .pipe(
                map((response: DocumentTypesResponse) => response.documentTypes),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse))
            );
    }

    //endregion
}
