/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./simple-dlg-with-iframe.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i9 from "@angular/material/icon";
import * as i10 from "@angular/common";
import * as i11 from "../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i12 from "@angular/material/progress-spinner";
import * as i13 from "../../pipes/safe-url.pipe";
import * as i14 from "@angular/platform-browser";
import * as i15 from "./simple-dlg-with-iframe.component";
import * as i16 from "@angular/material/dialog";
var styles_SimpleDlgWithIframeComponent = [i0.styles];
var RenderType_SimpleDlgWithIframeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SimpleDlgWithIframeComponent, data: {} });
export { RenderType_SimpleDlgWithIframeComponent as RenderType_SimpleDlgWithIframeComponent };
function View_SimpleDlgWithIframeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [["class", "mat-h2 simple-dlg-with-iframe__header"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.headerKey, _co.headerParams)); _ck(_v, 1, 0, currVal_0); }); }
function View_SimpleDlgWithIframeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "simple-dlg-with-iframe__close"], ["color", "primary"], ["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeClickHandler() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "mat-icon", [["class", "mat-icon_large mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i8.View_MatIcon_0, i8.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i9.MatIcon, [i1.ElementRef, i9.MatIconRegistry, [8, null], [2, i9.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"]))], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 1, 0, currVal_2); _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 3).inline; var currVal_4 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_3, currVal_4); }); }
function View_SimpleDlgWithIframeComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "hr", [["class", "simple-dlg-with-iframe__divider"]], null, null, null, null, null))], null, null); }
function View_SimpleDlgWithIframeComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.okClickHandler() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(2, 0, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.okBtnKey)); _ck(_v, 2, 0, currVal_2); }); }
function View_SimpleDlgWithIframeComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "simple-dlg-with-iframe__buttons"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimpleDlgWithIframeComponent_5)), i1.ɵdid(2, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasOkButton; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SimpleDlgWithIframeComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "simple-dlg-with-iframe__loading mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i11.View_MatSpinner_0, i11.RenderType_MatSpinner)), i1.ɵdid(1, 49152, null, 0, i12.MatSpinner, [i1.ElementRef, i5.Platform, [2, i10.DOCUMENT], [2, i7.ANIMATION_MODULE_TYPE], i12.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null)], function (_ck, _v) { var currVal_3 = 64; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_SimpleDlgWithIframeComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i13.SafeUrlPipe, [i14.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimpleDlgWithIframeComponent_1)), i1.ɵdid(3, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimpleDlgWithIframeComponent_2)), i1.ɵdid(5, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "iframe", [["class", "simple-dlg-with-iframe__iframe"], ["title", "dlg_iframe"]], [[8, "src", 5]], null, null, null, null)), i1.ɵppd(7, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimpleDlgWithIframeComponent_3)), i1.ɵdid(9, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimpleDlgWithIframeComponent_4)), i1.ɵdid(11, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimpleDlgWithIframeComponent_6)), i1.ɵdid(13, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasHeader; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.hasCloseIcon; _ck(_v, 5, 0, currVal_1); var currVal_3 = (!_co.loading && _co.hasOkButton); _ck(_v, 9, 0, currVal_3); var currVal_4 = (!_co.loading && _co.hasOkButton); _ck(_v, 11, 0, currVal_4); var currVal_5 = _co.loading; _ck(_v, 13, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v, 0), _co.iframeUrl)); _ck(_v, 6, 0, currVal_2); }); }
export function View_SimpleDlgWithIframeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "simple-dlg-with-iframe", [], null, null, null, View_SimpleDlgWithIframeComponent_0, RenderType_SimpleDlgWithIframeComponent)), i1.ɵdid(1, 49152, null, 0, i15.SimpleDlgWithIframeComponent, [i16.MatDialogRef, i16.MAT_DIALOG_DATA, i2.TranslateService], null, null)], null, null); }
var SimpleDlgWithIframeComponentNgFactory = i1.ɵccf("simple-dlg-with-iframe", i15.SimpleDlgWithIframeComponent, View_SimpleDlgWithIframeComponent_Host_0, {}, {}, []);
export { SimpleDlgWithIframeComponentNgFactory as SimpleDlgWithIframeComponentNgFactory };
