import { registerLocaleData } from "@angular/common";
import localeRu from "@angular/common/locales/ru";
import { NgModule } from "@angular/core";
import { Store } from "@ngrx/store";
import { select } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { filter } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { LangService } from "src/app/common/services";
import { components } from "src/app/root/components";
import { entryComponents } from "src/app/root/components";
import { entryContainers } from "src/app/root/containers";
import { containers } from "src/app/root/containers";
import { RootComponent } from "src/app/root/containers";
import { guards } from "src/app/root/guards";
import { langs } from "src/app/root/i18n";
import { modules } from "src/app/root/modules";
import { providers } from "src/app/root/providers";
import { CurrentUserLoadAction } from "src/app/root/store";
import { LangChangeAction } from "src/app/root/store";
import { currentUserLangSelector } from "src/app/root/store";
import { RootState } from "src/app/root/store/states/root.state";

@NgModule({
    imports: [
        ...modules,
    ],
    declarations: [
        ...containers,
        ...components,
    ],
    providers: [
        ...providers,
        ...guards,
    ],
    bootstrap: [
        RootComponent,
    ],
    entryComponents: [
        ...entryComponents,
        ...entryContainers,
    ],
})
export class RootModule {
    //region Ctor

    constructor(
        private store: Store<RootState>,
        private translateService: TranslateService,
        private langService: LangService
    ) {
        registerLocaleData(localeRu);

        // На старте модуля загружаем его i18n-сообщения.
        this.langService.loadTranslations(this.translateService, langs);

        // Язык по умолчанию.
        // TODO Нужно в будущем ещё обращать внимание на куку OBRABOT4IK_LANG.
        this.store.dispatch(new LangChangeAction("ru"));

        // Вызываем загрузку пользователя.
        this.store.dispatch(new CurrentUserLoadAction());

        // Подписываемся на изменение языка пользователя, чтобы сигнализировать его изменение.
        this.store
            .pipe(
                select(currentUserLangSelector),
                filter(lang => !!lang),
                tap(lang => this.store.dispatch(new LangChangeAction(lang)))
            )
            .subscribe();
    }

    //endregion
}
