import { CustomerCreationActionType } from "../actions/company";
import { CustomerCreationAction } from "../actions/company";
import { SupplierCreationActionType } from "../actions/company";
import { SupplierCreationAction } from "../actions/company";
import { CompanySubmissionState } from "src/app/operator/store/reducers/company-submission.state";

/**
 * Начальное состояние по данным создания компании.
 */
const initialState = new CompanySubmissionState();

/**
 * Обработка состояния создания компании постащика.
 * 
 * @param state Текущее состояние.
 * @param action Событие.
 */
export function supplierCreationReducer(state = initialState, action: SupplierCreationAction): CompanySubmissionState {
    
    let result = state;

    switch (action.type) {

        // На событие требования загрузки данных.
        case SupplierCreationActionType.CREATE: {

            result = {
                ...state,
                company: action.payload,
                loading: true,
                loaded: false,
                failed: false,
                errorData: null,
            };
            break;
        }

        // На событие успешной загрузки данных.
        case SupplierCreationActionType.CREATE_SUCCESS: {

            result = {
                ...state,
                company: action.payload,
                loaded: true,
                loading: false,
            };
            break;
        }

        // На событие неудачной загрузки данных.
        case SupplierCreationActionType.CREATE_FAIL: {

            result = {
                ...state,
                loaded: false,
                loading: false,
                failed: true,
                errorData: action.payload
            };
            break;
        }

    }

    return result;
}

/**
 * Обработка состояния создания компании постащика.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 */
export function customerCreationReducer(state = initialState, action: CustomerCreationAction): CompanySubmissionState {

    let result = state;

    switch (action.type) {

        // На событие требования загрузки данных.
        case CustomerCreationActionType.CREATE: {

            result = {
                ...state,
                company: {...action.payload},
                loading: true,
                loaded: false,
                failed: false,
                errorData: null,
            };
            break;
        }

        // На событие успешной загрузки данных.
        case CustomerCreationActionType.CREATE_SUCCESS: {

            result = {
                ...state,
                company: {...action.payload},
                loaded: true,
                loading: false,
            };
            break;
        }

        // На событие неудачной загрузки данных.
        case CustomerCreationActionType.CREATE_FAIL: {

            result = {
                ...state,
                loaded: false,
                loading: false,
                failed: true,
                errorData: {...action.payload}
            };
            break;
        }

    }

    return result;
}

/**
 * Возвращает данные компании.
 * 
 * @param state Состояние данных о создании компании.
 */
export const getCompany = (state: CompanySubmissionState) => state.company;

/**
 * Возвращает флаг о успешном создании компании.
 * 
 * @param state Состояние данных о создании компании.
 */
export const getCompanyCreated = (state: CompanySubmissionState) => state.loaded;

/**
 * Возвращает флаг выполнения загрузки для создания компании.
 * 
 * @param state Состояние данных о создании компании.
 */
export const getCompanyCreateLoading = (state: CompanySubmissionState) => state.loading;

/**
 * Создание компании завершилосьб не удачей.
 * 
 * @param state Состояние данных о создании компании.
 */
export const getCompanyCreatedFailed =
    (state: CompanySubmissionState) => ({failed: state.failed, errorData: state.errorData});
