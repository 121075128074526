/**
 * Типы запросов веб сокета.
 */
export enum WebsocketRequestType {
    //region Enum values

    /**
     * Подписка на событие.
     */
    SUBSCRIPTION = "SUBSCRIPTION",

    /**
     * Отписка от события.
     */
    UNSUBSCRIPTION = "UNSUBSCRIPTION",

    /**
     * Подтверждение связи по WebSocket.
     */
    KEEP_ALIVE = "KEEP_ALIVE",

    //endregion
}
