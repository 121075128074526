/**
 * Состояние интерфейса расклейки страниц.
 */
import { OperatorDocumentType } from "src/app/common/models";
import { SplitterPage } from "src/app/operator/models";
import { SplitterDocument } from "src/app/operator/models";
import { ApiResponse } from "src/app/common/models";

export class PageSplitterState {

    /**
     * ID документа из очереди который на расклейке.
     */
    readonly queueDocumentId: string = null;

    /**
     * Набор размеченных документов.
     */
    readonly documents: SplitterDocument[] = [
        {
            id: 0,
            pages: [],
            type: {
                name: "Не определён",
                id: "NOT_EXIST",
                extended: false,
                foreign: null,
                visibleOperatorHelpBadges: [],
            },
        },
    ];

    /**
     * Набор свободных страниц.
     */
    readonly freePages: SplitterPage[] = [];

    /**
     * Индекс свободной страницы которая показывается в данный момент.
     */
    readonly freePageIndex: number = 0;

    /**
     * Набор активных страниц в документе, которые взяли в документ.
     */
    readonly activePages: SplitterPage[] = [];

    /**
     * Индекс активной страницы которая показывается в данный момент.
     */
    readonly activePageIndex: number = -1;

    /**
     * Тип активного документа.
     */
    readonly activeType: OperatorDocumentType = {
        name: "Не определён",
        id: "NOT_EXIST",
        extended: false,
        foreign: null,
        visibleOperatorHelpBadges: [],
    };

    /**
     * Индекс текущего документа из расклеенных.
     */
    readonly activeDocumentIndex: number = 0;

    /**
     * Состояние текущей отправки документов на сервер.
     */
    readonly sending: boolean = false;

    /**
     * Отправка документов на сервер успешна.
     */
    readonly sent: boolean = false;

    /**
     * Ошибка при отправке документов на сервер.
     */
    readonly error: ApiResponse = null;
}
