import { OperatorDocumentType } from "src/app/common/models/document-type";
import { ApiResponse } from "src/app/common/models/index";
import { DocumentTypesAction, DocumentTypesActionType } from "src/app/root/store/actions/document-types.action";

/**
 * Состояние типов документов.
 */
export interface DocumentTypesState {

    /**
     * Типы документов загружаются?
     */
    loading: boolean;

    /**
     * Ошибка загрузки типов документов
     */
    error: ApiResponse;

    /**
     * Типы документов.
     */
    documentTypes: OperatorDocumentType[];
}

/**
 * Начальное состояние типов документов.
 */
const initialState: DocumentTypesState = {

    /**
     * Типы документов не загружаются.
     */
    loading: false,

    /**
     * Загрузка типов документов ошибкой не завершалась.
     */
    error: null,

    /**
     * Типов документов нет.
     */
    documentTypes: [],
};

/**
 * Обработчик событий, связанных с типами документов.
 * 
 * @param state Состояние типов документов.
 * @param action Событие произошедшее в системе.
 */
export function documentTypesReducer(state = initialState, action: DocumentTypesAction): DocumentTypesState {

    switch (action.type) {

        case DocumentTypesActionType.LOAD: {

            return {
                ...state,
                loading: true,
                error: null,
            };
        }

        case DocumentTypesActionType.LOAD_SUCCESS: {

            const documentTypes = action.payload;
            return {
                ...state,
                loading: false,
                documentTypes,
            };
        }

        case DocumentTypesActionType.LOAD_FAIL: {

            return {
                ...state,
                loading: false,
                error: action.payload,
                documentTypes: [],
            };
        }
    }

    return state;
}
