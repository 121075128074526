import { ChangeDetectorRef } from "@angular/core";
import { ElementRef } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
/**
 * Обобщённый компонент для просмотра заданного списка страниц.
 */
var PagesViewerComponent = /** @class */ (function () {
    //endregion
    //endregion
    //region Ctor
    /**
     * Конструктор обобщённого компонента для просмотра заданного списка страниц.
     *
     * @param cd Сервис для управления запуском определения angular'ом изменений данных, произошедших в компоненте.
     * @param sanitizer Сервис для обхода механизма безопасности HTML/CSS/JS в Angular'е.
     */
    function PagesViewerComponent(cd, sanitizer) {
        /**
         * Входящие данные - список URL-ов всех страниц задачи на распознавание.
         */
        this.pageUrls = [];
        /**
         * Входящие данные - показать интерфейс вообще.
         */
        this.showInterface = true;
        /**
         * Входящие данные - показать кнопку поворта.
         */
        this.showRotate = true;
        //endregion
        //region Outputs
        /**
         * Исходящее событие - изменение текущей страницы.
         */
        this.currentPageChange = new EventEmitter();
        /**
         * Исходящее событие - изменение масштаба изображения страницы.
         */
        this.scaleChange = new EventEmitter();
        /**
         * Исходящее событие - изменение поворота изображения страницы.
         */
        this.rotateAngleChange = new EventEmitter();
        /**
         * Исходящее событие - изменение смещения изображения страницы вверх.
         */
        this.pageTopChange = new EventEmitter();
        /**
         * Исходящее событие - изменение смещения изображения страницы влево.
         */
        this.pageLeftChange = new EventEmitter();
        //endregion
        //region Fields
        //region Public
        /**
         * Флаг того, что выполняется загрузка изображения.
         */
        this.imageLoading = true;
        //endregion
        //region Private
        /**
         * Индекс текущей отображаемой страницы.
         */
        this._currentPage = 1;
        /**
         * Смещение изображения страницы влево. Координата смещения X.
         */
        this._pageLeft = 0;
        /**
         * Смещение изображения страницы вверх. Координата смещения Y.
         */
        this._pageTop = 0;
        /**
         * Масштаб просмотра.
         */
        this._scale = 0.4;
        /**
         * Угол поворота изображения страницы (0, 90, 180, 270).
         */
        this._rotateAngle = 0;
        /**
         * Флаг выполнения перемещения изображения страницы внутри viewport'а.
         */
        this._pageMoving = false;
        this._cd = cd;
        this._sanitizer = sanitizer;
    }
    Object.defineProperty(PagesViewerComponent.prototype, "scale", {
        //endregion
        //region Getters and Setters
        /**
         * Масштаб просмотра.
         */
        get: function () {
            return this._scale;
        },
        //endregion
        //region Inputs
        /**
         * Входящие данные - масштаб изображения страницы.
         */
        set: function (value) {
            if (this._scale !== value) {
                this._scale = value;
                this.scaleChange.emit(this._scale);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PagesViewerComponent.prototype, "rotateAngle", {
        /**
         * Угол поворота изображения страницы.
         */
        get: function () {
            return this._rotateAngle;
        },
        /**
         * Входящие данные - угол поворота изображения страницы.
         */
        set: function (value) {
            if (this._rotateAngle !== value) {
                this._rotateAngle = value;
                this.rotateAngleChange.emit(this._rotateAngle);
                this._correctPositionAfterRotate(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PagesViewerComponent.prototype, "pageLeft", {
        /**
         * Смещение изображения страницы влево. Координата смещения X.
         */
        get: function () {
            return this._pageLeft;
        },
        /**
         * Входящие данные - смещение изображения страницы влево.
         */
        set: function (value) {
            if (this._pageLeft !== value) {
                this._pageLeft = value;
                this.pageLeftChange.emit(this._pageLeft);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PagesViewerComponent.prototype, "pageTop", {
        /**
         * Смещение изображения страницы вверх. Координата смещения Y.
         */
        get: function () {
            return this._pageTop;
        },
        /**
         * Входящие данные - смещение изображения страницы вверх.
         */
        set: function (value) {
            if (this._pageTop !== value) {
                this._pageTop = value;
                this.pageTopChange.emit(this._pageTop);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PagesViewerComponent.prototype, "currentPage", {
        /**
         * Индекс текущей отображаемой страницы.
         */
        get: function () {
            return this._currentPage;
        },
        /**
         * Входящие данные - индекс текущей отображаемой страницы.
         */
        set: function (value) {
            if (value >= 1 && (!this.pageNumbers || value <= this.pageNumbers.length)) {
                this._currentPage = value;
                this.currentPageChange.emit(this.currentPage);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PagesViewerComponent.prototype, "viewport", {
        /**
         * DOM-элемент, который является viewport'ом для изображения страницы.
         */
        get: function () {
            return (this._viewportRef ? this._viewportRef.nativeElement : null);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PagesViewerComponent.prototype, "viewportPageX", {
        /**
         * Отступ viewport'а от левой границы страницы.
         */
        get: function () {
            return (this.viewport ? this.viewport.getBoundingClientRect().left : 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PagesViewerComponent.prototype, "viewportPageY", {
        /**
         * Отступ viewport'а от верхней границы страницы.
         */
        get: function () {
            return (this.viewport ? this.viewport.getBoundingClientRect().top : 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PagesViewerComponent.prototype, "viewportWidth", {
        /**
         * Ширина viewport'а страницы.
         */
        get: function () {
            return (this.viewport ? this.viewport.clientWidth : 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PagesViewerComponent.prototype, "viewportHeight", {
        /**
         * Высота viewport'а страницы.
         */
        get: function () {
            return (this.viewport ? this.viewport.clientHeight : 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PagesViewerComponent.prototype, "currentImage", {
        /**
         * DOM-элемент изображения текущей страницы.
         */
        get: function () {
            return (this._imageRef ? this._imageRef.nativeElement : null);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PagesViewerComponent.prototype, "imageWidth", {
        /**
         * Ширина изображения текущей страницы с учётом масштаба.
         */
        get: function () {
            return this.scale * (this.currentImage ? this.currentImage.clientWidth : 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PagesViewerComponent.prototype, "imageRealWidth", {
        /**
         * Реальная ширина изображения текущей страницы.
         */
        get: function () {
            return (this.currentImage ? this.currentImage.clientWidth : 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PagesViewerComponent.prototype, "imageHeight", {
        /**
         * Высота изображения текущей страницы с учётом масштаба.
         */
        get: function () {
            return this.scale * (this.currentImage ? this.currentImage.clientHeight : 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PagesViewerComponent.prototype, "imageRealHeight", {
        /**
         * Реальная высота изображения текущей страницы.
         */
        get: function () {
            return (this.currentImage ? this.currentImage.clientHeight : 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PagesViewerComponent.prototype, "minLeft", {
        /**
         * Минимальная координата смещения изображения по X (т.е. смещения влево).
         */
        get: function () {
            var minLeft;
            switch (this._rotateAngle) {
                case 0:
                    minLeft = Math.min(this.viewportWidth - this.imageWidth, 0);
                    break;
                case 90:
                    minLeft = this.imageHeight - Math.max(this.imageHeight - this.viewportWidth, 0);
                    break;
                case 180:
                    minLeft = this.imageWidth - Math.max(this.imageWidth - this.viewportWidth, 0);
                    break;
                case 270:
                    minLeft = Math.min(this.viewportWidth - this.imageHeight, 0);
                    break;
            }
            return minLeft;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PagesViewerComponent.prototype, "maxLeft", {
        /**
         * Максимальная координата смещения изображения по X (т.е. смещения вправо).
         */
        get: function () {
            var maxLeft;
            switch (this._rotateAngle) {
                case 0:
                    maxLeft = 0;
                    break;
                case 90:
                    maxLeft = this.imageHeight;
                    break;
                case 180:
                    maxLeft = this.imageWidth;
                    break;
                case 270:
                    maxLeft = 0;
                    break;
            }
            return maxLeft;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PagesViewerComponent.prototype, "minTop", {
        /**
         * Минимальная координата смещения изображения по Y (т.е. смещения вверх).
         */
        get: function () {
            var minTop;
            switch (this._rotateAngle) {
                case 0:
                    minTop = Math.min(this.viewportHeight - this.imageHeight, 0);
                    break;
                case 90:
                    minTop = Math.min(this.viewportHeight - this.imageWidth, 0);
                    break;
                case 180:
                    minTop = this.imageHeight - Math.max(this.imageHeight - this.viewportHeight, 0);
                    break;
                case 270:
                    minTop = this.imageWidth - Math.max(this.imageWidth - this.viewportHeight, 0);
                    break;
            }
            return minTop;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PagesViewerComponent.prototype, "maxTop", {
        /**
         * Максимальная координата смещения изображения по Y (т.е. смещения вниз).
         */
        get: function () {
            var maxTop;
            switch (this._rotateAngle) {
                case 0:
                    maxTop = 0;
                    break;
                case 90:
                    maxTop = 0;
                    break;
                case 180:
                    maxTop = this.imageHeight;
                    break;
                case 270:
                    maxTop = this.imageWidth;
                    break;
            }
            return maxTop;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PagesViewerComponent.prototype, "currentPageNumber", {
        /**
         * Номер текущей отображаемой страницы.
         */
        get: function () {
            return this.pageNumbers[this.currentPage - 1];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PagesViewerComponent.prototype, "documentPageIndex", {
        /**
         * Индекс текущей страницы в рамках документа.
         */
        get: function () {
            var documentPageIndex = this.documentPageNumbers.indexOf(this.currentPageNumber) + 1;
            return (documentPageIndex || null);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PagesViewerComponent.prototype, "documentPageNumber", {
        /**
         * Номер текущей страницы в рамках документа.
         */
        get: function () {
            var documentPageNumber = -1;
            var documentPageIndex = this.documentPageNumbers.indexOf(this.currentPageNumber);
            if (documentPageIndex !== -1) {
                documentPageNumber = this.documentPageNumbers[documentPageIndex];
            }
            return documentPageNumber;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PagesViewerComponent.prototype, "currentPageBelongsToDocument", {
        /**
         * Текущая страница относится к документу?
         */
        get: function () {
            return (this.documentPageNumber !== -1);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PagesViewerComponent.prototype, "pagesAlignedWithDocumentPages", {
        /**
         * Отображаемые страницы по составу и порядку совпадают со страницами документа?
         */
        get: function () {
            var result = (this.pageNumbers.length === this.documentPageNumbers.length);
            if (result) {
                for (var i = 0; i < this.pageNumbers.length && result; i++) {
                    result = (this.pageNumbers[i] === this.documentPageNumbers[i]);
                }
            }
            return result;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PagesViewerComponent.prototype, "scaleDelta", {
        /**
         * Шаг увеличения/уменьшения масштаба страницы.
         */
        get: function () {
            var result = PagesViewerComponent.SCALE_DELTA;
            if (this.scale >= 1.5) {
                result = PagesViewerComponent.BIG_SCALE_DELTA;
            }
            return result;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PagesViewerComponent.prototype, "currentPageUrl", {
        /**
         * URL изображения текущей страницы.
         */
        get: function () {
            return this.pageUrls[this.currentPageNumber - 1];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PagesViewerComponent.prototype, "imageTransformStyle", {
        /**
         * CSS-стиль изображения текущей страницы для её масштаба и поворота.
         *
         * Подобный стиль нельзя разместить прямо в шаблоне, т.к. Angular ругается на него как на небезопасный. Поэтому
         * требуется вручную указывать, что этот стиль безопасный.
         */
        get: function () {
            return this._sanitizer.bypassSecurityTrustStyle("scale(" + this.scale + ") rotate(" + this.rotateAngle + "deg)");
        },
        enumerable: true,
        configurable: true
    });
    //endregion
    //region Public
    /**
     * Выполняет поворот изображения страницы.
     */
    PagesViewerComponent.prototype.rotate = function () {
        this.rotateAngle = (this._rotateAngle + 90) % 360;
    };
    /**
     * Увеличивает масштаб изображения относительно заданной точки на странице.
     *
     * @param pagePointX Координата X точки.
     * @param pagePointY Координата Y точки.
     */
    PagesViewerComponent.prototype.increaseScale = function (pagePointX, pagePointY) {
        if (pagePointX === void 0) { pagePointX = -1; }
        if (pagePointY === void 0) { pagePointY = -1; }
        if (this.scale < PagesViewerComponent.MAX_SCALE) {
            if (pagePointX !== -1) {
                var zoomPointX = pagePointX - this.viewportPageX - this.pageLeft;
                var scaledZoomPointX = zoomPointX * (1 + this.scaleDelta / this.scale);
                var viewportOffset = zoomPointX - scaledZoomPointX;
                this._changeX(viewportOffset);
            }
            if (pagePointY !== -1) {
                var zoomPointY = pagePointY - this.viewportPageY - this.pageTop;
                var scaledZoomPointY = zoomPointY * (1 + this.scaleDelta / this.scale);
                var viewportOffset = zoomPointY - scaledZoomPointY;
                this._changeY(viewportOffset);
            }
            this.scale += this.scaleDelta;
            this.correctImagePosition();
        }
    };
    /**
     * Уменьшает масштаб изображения относительно заданной точки на странице.
     *
     * @param pagePointX Координата X точки.
     * @param pagePointY Координата Y точки.
     */
    PagesViewerComponent.prototype.decreaseScale = function (pagePointX, pagePointY) {
        if (pagePointX === void 0) { pagePointX = -1; }
        if (pagePointY === void 0) { pagePointY = -1; }
        if (this.scale > PagesViewerComponent.MIN_SCALE) {
            if (pagePointX !== -1) {
                var zoomPointX = pagePointX - this.viewportPageX - this.pageLeft;
                var scaledZoomPointX = zoomPointX * (1 - this.scaleDelta / this.scale);
                var viewportOffset = zoomPointX - scaledZoomPointX;
                this._changeX(viewportOffset);
            }
            if (pagePointY !== -1) {
                var zoomPointY = pagePointY - this.viewportPageY - this.pageTop;
                var scaledZoomPointY = zoomPointY * (1 - this.scaleDelta / this.scale);
                var viewportOffset = zoomPointY - scaledZoomPointY;
                this._changeY(viewportOffset);
            }
            this.scale -= this.scaleDelta;
            this.correctImagePosition();
        }
    };
    /**
     * Показывает изображение следующей страницы.
     */
    PagesViewerComponent.prototype.nextPage = function () {
        if (this.currentPage < this.pageNumbers.length) {
            this.imageLoading = true;
            this.currentPage++;
            // При перемещении по страницам отображаем левый верхний угол.
            this.pageLeft = this.maxLeft;
            this.pageTop = this.maxTop;
            this.correctImagePosition();
        }
    };
    /**
     * Показывает изображение предыдущей страницы.
     */
    PagesViewerComponent.prototype.prevPage = function () {
        if (this.currentPage > 1) {
            this.imageLoading = true;
            this.currentPage--;
            // При перемещении по страницам отображаем левый верхний угол.
            this.pageLeft = this.maxLeft;
            this.pageTop = this.maxTop;
            this.correctImagePosition();
        }
    };
    /**
     * Защитная логика, чтобы позиция изображения страницы не вышла за границы viewport'а.
     */
    PagesViewerComponent.prototype.correctImagePosition = function () {
        var _this = this;
        setTimeout(function () {
            _this._changeX();
            _this._changeY();
            _this._cd.markForCheck();
        });
    };
    //endregion
    //region Events
    /**
     * Обработчик события выполнения scroll'а колесом прокрутки.
     *
     * В ответ на это событие выполняется увеличение или уменьшение масштаба относительно точки, в которой
     * расположен курсор мыши.
     *
     * @param event Событие scroll'а колесом прокрутки.
     */
    PagesViewerComponent.prototype.onWheelScroll = function (event) {
        event.preventDefault();
        var delta = event.deltaY || event["wheelDelta"];
        if (delta < 0) {
            this.increaseScale(event.pageX, event.pageY);
        }
        else {
            this.decreaseScale(event.pageX, event.pageY);
        }
    };
    /**
     * Обработка события нажатия левой кнопкой мыши на изображение страницы для начала её перемещения.
     */
    PagesViewerComponent.prototype.onMousedown = function () {
        this._pageMoving = true;
    };
    /**
     * Обработка события отпускания левой кнопки мыши.
     *
     * Останавливает перемещение изображение страницы.
     */
    PagesViewerComponent.prototype.onMouseUp = function () {
        this._pageMoving = false;
    };
    /**
     * Обработка события перемещения курсора мыши.
     *
     * Если левая кнопка мыши зажата, то выполняется перемещение изображения страницы внутри viewport'а.
     */
    PagesViewerComponent.prototype.onMouseMove = function ($event) {
        if (this._pageMoving) {
            this._changeX($event.movementX);
            this._changeY($event.movementY);
        }
    };
    /**
     * Обработчик события успешной загрузки изображения страницы.
     */
    PagesViewerComponent.prototype.imageLoadHandler = function () {
        this.imageLoading = false;
        this._correctPositionAfterRotate(this.rotateAngle);
    };
    //endregion
    //region Private
    /**
     * Измение координаты X изображения страницы с ограничением по отрвыу от краев viewport'а.
     *
     * @param delta Смещение.
     */
    PagesViewerComponent.prototype._changeX = function (delta) {
        if (delta === void 0) { delta = 0; }
        var newX = this.pageLeft + delta;
        if (newX < this.minLeft) {
            this.pageLeft = this.minLeft;
        }
        else if (newX > this.maxLeft) {
            this.pageLeft = this.maxLeft;
        }
        else {
            this.pageLeft = newX;
        }
    };
    /**
     * Измение координаты Y изображения страницы с ограничением по отрвыу от краев viewport'а.
     *
     * @param delta Смещение.
     */
    PagesViewerComponent.prototype._changeY = function (delta) {
        if (delta === void 0) { delta = 0; }
        var newY = this.pageTop + delta;
        if (newY < this.minTop) {
            this.pageTop = this.minTop;
        }
        else if (newY > this.maxTop) {
            this.pageTop = this.maxTop;
        }
        else {
            this.pageTop = newY;
        }
    };
    /**
     * Корректировка позиции изображения после поворота.
     *
     * @param angle Угол поворота.
     */
    PagesViewerComponent.prototype._correctPositionAfterRotate = function (angle) {
        switch (angle) {
            case 0:
                this.pageTop = 0;
                this.pageLeft = 0;
                break;
            case 90:
                this.pageTop = 0;
                this.pageLeft = this.imageHeight;
                break;
            case 180:
                this.pageTop = this.imageHeight;
                this.pageLeft = this.imageWidth;
                break;
            case 270:
                this.pageTop = this.imageWidth;
                this.pageLeft = 0;
                break;
        }
    };
    //region Constant
    /**
     * Минимальный масштаб изображения страницы.
     *
     * Добавлена 1 сотая, чтобы избежать случая, когда масштаб станет 0.1 с какой-то очень маленькой делтой и будет
     * больше, чем 0.1.
     */
    PagesViewerComponent.MIN_SCALE = 0.11;
    /**
     * Максимальный масштаб изображения страницы.
     */
    PagesViewerComponent.MAX_SCALE = 4;
    /**
     * Шаг увеличения/уменьшения масштаба изображения.
     */
    PagesViewerComponent.SCALE_DELTA = 0.1;
    /**
     * Увеличенный шаг увеличения/уменьшения масштаба изображения, когда масштаб стал большим.
     */
    PagesViewerComponent.BIG_SCALE_DELTA = 0.5;
    return PagesViewerComponent;
}());
export { PagesViewerComponent };
