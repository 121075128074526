import { Action } from '@ngrx/store';

import { ApiResponse } from '../../../common/models';
import { Company } from '../../../common/models';

/**
 * Типы событий, связанные с состоянием блоков выбора контрагентов обрабатываемого документа.
 */
export enum SpaceCounterpartiesActionType {
    SPACE_CUSTOMERS_LOAD = '[Operator] Loading space customers',
    SPACE_CUSTOMERS_LOADED = '[Operator] Space customers loaded',
    SPACE_CUSTOMERS_FAILED = '[Operator] Space customers loading failed',

    SPACE_SUPPLIERS_LOAD = '[Operator] Loading space suppliers',
    SPACE_SUPPLIERS_LOADED = '[Operator] Space suppliers loaded',
    SPACE_SUPPLIERS_FAILED = '[Operator] Space suppliers loading failed',
}

/**
 * Событие, требующее загрузки списка уникальных компаний-покупателей, привязанных к документам в заданном пространстве
 * документов.
 */
export class SpaceCustomersLoadAction implements Action {
    readonly type = SpaceCounterpartiesActionType.SPACE_CUSTOMERS_LOAD;
    constructor(
        public recognitionResultId: string,
        public foreign: boolean,
    ) { }
}

/**
 * Событие успешной загрузки списка уникальных компаний-покупателей, привязанных к документам в заданном пространстве
 * документов.
 */
export class SpaceCustomersLoadedAction implements Action {
    readonly type = SpaceCounterpartiesActionType.SPACE_CUSTOMERS_LOADED;
    constructor(
        public spaceCustomers: Company[],
    ) { }
}

/**
 * Событие неудачной загрузки списка уникальных компаний-покупателей, привязанных к документам в заданном пространстве
 * документов.
 */
export class SpaceCustomersFailedAction implements Action {
    readonly type = SpaceCounterpartiesActionType.SPACE_CUSTOMERS_FAILED;
    constructor(
        public error: ApiResponse,
    ) { }
}

/**
 * Событие, требующее загрузки списка уникальных компаний-поставщиков, привязанных к документам в заданном пространстве
 * документов.
 */
export class SpaceSuppliersLoadAction implements Action {
    readonly type = SpaceCounterpartiesActionType.SPACE_SUPPLIERS_LOAD;
    constructor(
        public recognitionResultId: string,
        public foreign: boolean,
    ) { }
}

/**
 * Событие успешной загрузки списка уникальных компаний-поставщиков, привязанных к документам в заданном пространстве
 * документов.
 */
export class SpaceSuppliersLoadedAction implements Action {
    readonly type = SpaceCounterpartiesActionType.SPACE_SUPPLIERS_LOADED;
    constructor(
        public spaceSuppliers: Company[],
    ) { }
}

/**
 * Событие неудачной загрузки списка уникальных компаний-поставщиков, привязанных к документам в заданном пространстве
 * документов.
 */
export class SpaceSuppliersFailedAction implements Action {
    readonly type = SpaceCounterpartiesActionType.SPACE_SUPPLIERS_FAILED;
    constructor(
        public error: ApiResponse,
    ) { }
}

/**
 * Тип, объединяющий все события, связанные с состоянием блоков выбора контрагентов обрабатываемого документа.
 */
export type SpaceCounterpartiesAction =
    | SpaceCustomersLoadAction
    | SpaceCustomersLoadedAction
    | SpaceCustomersFailedAction

    | SpaceSuppliersLoadedAction
    | SpaceSuppliersLoadAction
    | SpaceSuppliersFailedAction
    ;
