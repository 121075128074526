import { HttpClient } from "@angular/common/http";
import { throwError } from "rxjs/internal/observable/throwError";
import { catchError } from "rxjs/operators";
import { map } from "rxjs/operators";
import { ErrorUtils } from "../../common/utils";
import { UrlUtils } from "../../common/utils";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
/**
 * Сервис для входа в систему.
 */
var LoginService = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
    * Конструктор сервиса для входа в систему.
    *
    * @param http HTTP-клиент.
    */
    function LoginService(http) {
        this._http = http;
    }
    //endregion
    //region Public
    /**
     * Выполняет вход в систему.
     *
     * @param credentials Учетные данные для входа в систему.
     *
     * @return Успех выполнения входа.
     */
    LoginService.prototype.signIn = function (credentials) {
        return this._http
            .post(UrlUtils.loginApiUrl(), credentials)
            .pipe(map(function (response) { return response.result; }), catchError(function (response) { return throwError(ErrorUtils.from(response)); }));
    };
    /**
     * Выполняет выход из системы.
     *
     * @return Успех выполнения выхода.
     */
    LoginService.prototype.signOut = function () {
        return this._http
            .post(UrlUtils.logoutApiUrl(), null)
            .pipe(map(function () { return true; }), catchError(function (response) { return throwError(ErrorUtils.from(response)); }));
    };
    LoginService.ngInjectableDef = i0.defineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.inject(i1.HttpClient)); }, token: LoginService, providedIn: "root" });
    return LoginService;
}());
export { LoginService };
