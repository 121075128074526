import { HttpClient } from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
/**
 * Сервис для работы с типами документов.
 */
var DocumentTypesService = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор сервиса для работы с типами документов.
     *
     * @param httpClient HTTP клиент.
     */
    function DocumentTypesService(httpClient) {
        this._httpClient = httpClient;
    }
    //endregion
    //region Public
    /**
     * Загрузка типов документов с сервера.
     */
    DocumentTypesService.prototype.getDocumentTypes = function () {
        return this._httpClient.get("/api/v1/processing/documentTypes")
            .pipe(map(function (response) { return response.documentTypes; }), catchError(function (response) { return throwError(response.error); }));
    };
    DocumentTypesService.ngInjectableDef = i0.defineInjectable({ factory: function DocumentTypesService_Factory() { return new DocumentTypesService(i0.inject(i1.HttpClient)); }, token: DocumentTypesService, providedIn: "root" });
    return DocumentTypesService;
}());
export { DocumentTypesService };
