import { CustomerEditActionType } from "src/app/operator/store/actions/company/customer-edit";
import { CustomerEditAction } from "src/app/operator/store/actions/company/customer-edit";
import { SupplierEditAction } from "src/app/operator/store/actions/company/supplier-edit";
import { SupplierEditActionType } from "src/app/operator/store/actions/company/supplier-edit";
import { CompanySubmissionState } from "src/app/operator/store/reducers/company-submission.state";

/**
 * Начальное состояние по данным редактирования компании.
 */
const initialState = new CompanySubmissionState();

/**
 * Обработка состояния редактирования компании поставщика.
 * 
 * @param state Текущее состояние.
 * @param action Событие.
 *
 * @return Состояние редактирования компании поставщика.
 */
export function supplierEditReducer(state = initialState, action: SupplierEditAction): CompanySubmissionState {
    
    let result = state;

    switch (action.type) {

        case SupplierEditActionType.EDIT: {

            result = {
                ...state,
                company: action.payload,
                loading: true,
                loaded: false,
                failed: false,
                errorData: null,
            };
            break;
        }

        case SupplierEditActionType.EDIT_SUCCESS: {

            result = {
                ...state,
                company: action.payload,
                loaded: true,
                loading: false,
            };
            break;
        }

        case SupplierEditActionType.EDIT_FAIL: {

            result = {
                ...state,
                loaded: false,
                loading: false,
                failed: true,
                errorData: action.payload,
            };
            break;
        }

    }

    return result;
}

/**
 * Обработка состояния редактирования компании покупателя.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 *
 * @return Состояние редактирования компании покупателя.
 */
export function customerEditReducer(state = initialState, action: CustomerEditAction): CompanySubmissionState {

    let result = state;

    switch (action.type) {

        case CustomerEditActionType.EDIT: {

            result = {
                ...state,
                company: {...action.payload},
                loading: true,
                loaded: false,
                failed: false,
                errorData: null,
            };
            break;
        }

        case CustomerEditActionType.EDIT_SUCCESS: {

            result = {
                ...state,
                company: {...action.payload},
                loaded: true,
                loading: false,
            };
            break;
        }

        case CustomerEditActionType.EDIT_FAIL: {

            result = {
                ...state,
                loaded: false,
                loading: false,
                failed: true,
                errorData: {...action.payload},
            };
            break;
        }

    }

    return result;
}

/**
 * Возвращает данные компании.
 * 
 * @param state Состояние данных редактируемой компании.
 */
export const getCompany = (state: CompanySubmissionState) => state.company;

/**
 * Возвращает флаг об успешном редактировании компании.
 * 
 * @param state Состояние данных редактируемой компании.
 */
export const getCompanyEdited = (state: CompanySubmissionState) => state.loaded;

/**
 * Возвращает флаг выполнения загрузки для создания компании.
 * 
 * @param state Состояние данных редактируемой компании.
 */
export const getCompanyEditLoading = (state: CompanySubmissionState) => state.loading;

/**
 * Редактирование компании завершилось ошибкой.
 * 
 * @param state Состояние данных редактируемой компании.
 */
export const getCompanyCreatedFailed =
    (state: CompanySubmissionState) => ({failed: state.failed, errorData: state.errorData});
