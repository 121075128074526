import { MatDialog } from "@angular/material";
import { SimpleDlgWithIframeComponent } from "src/app/common/components/simple-dlg-with-iframe/simple-dlg-with-iframe.component";
import { SimpleAlertDlgComponent } from "../components/simple-alert-dlg/simple-alert-dlg.component";
import { SimplePromptDlgComponent } from "../components/simple-prompt-dlg/simple-prompt-dlg.component";
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
/**
 * Сервис для работы с диалогами.
 */
var DlgService = /** @class */ (function () {
    //region Ctor
    function DlgService(_dialog) {
        this._dialog = _dialog;
    }
    //endregion
    //region Public
    /**
     * Открывает диалог с заданным текстом.
     *
     * @param data I18n-ключ текста для отображения в диалоге.
     */
    DlgService.prototype.openSimpleDlg = function (data) {
        return this._dialog.open(SimpleAlertDlgComponent, {
            data: data,
            autoFocus: false,
            disableClose: data.disableClose,
            panelClass: ['operator-dlg', 'operator-simple-dlg']
        });
    };
    /**
     * Открывает диалог с iframe.
     *
     * @param data Данные для отображения в диалоге.
     */
    DlgService.prototype.openSimpleDlgWithIFrame = function (data) {
        return this._dialog.open(SimpleDlgWithIframeComponent, {
            data: data,
            autoFocus: false,
            disableClose: data.disableClose,
            width: "80%",
            height: "100%",
            minWidth: "80%",
            minHeight: "100%",
        });
    };
    /**
     * Открывает диалог с заданным текстом и пользовательским вводом.
     *
     * @param data I18n-ключ текста для отображения в диалоге.
     */
    DlgService.prototype.openSimplePromptDialog = function (data) {
        return this._dialog.open(SimplePromptDlgComponent, {
            data: data,
            autoFocus: true,
            disableClose: data.disableClose,
            panelClass: ['operator-dlg', 'operator-simple-dlg']
        });
    };
    DlgService.ngInjectableDef = i0.defineInjectable({ factory: function DlgService_Factory() { return new DlgService(i0.inject(i1.MatDialog)); }, token: DlgService, providedIn: "root" });
    return DlgService;
}());
export { DlgService };
