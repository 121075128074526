import { Component } from "@angular/core";
import { OnInit } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { Store } from "@ngrx/store";
import { select } from "@ngrx/store";

import { Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { CurrentUserInfo } from "src/app/common/models";
import { UserService } from "src/app/common/services";

import { CurrentUserLogoutAction } from "src/app/root/store";
import { currentUserInfoSelector } from "src/app/root/store";

import { RootState } from "src/app/root/store/states/root.state";

/**
 * Корневой компонент, с которого начинается приложение.
 */
@Component({
    selector: "root",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ["./root.component.scss"],
    templateUrl: "./root.component.html"
})
export class RootComponent implements OnInit {
    //region Fields

    /**
     * Данные текущего пользователя.
     */
    user$: Observable<CurrentUserInfo>;

    /**
     * Состояние приложения.
     */
    private readonly _store: Store<RootState>;

    /**
     * Сервис для работы с пользователями.
     */
    private readonly _userService: UserService;

    //endregion
    //region Ctor

    /**
     * Конструктор корневой компонент, с которого начинается приложение.
     *
     * @param store Состояние приложения.
     * @param userService Сервис для работы с пользователями.
     */
    constructor(store: Store<RootState>, userService: UserService) {

        this._store = store;
        this._userService = userService;
    }

    //endregion
    //region Hooks

    /**
     * Логика при инициализации компонента.
     */
    ngOnInit() {

        // Подписываемся на события появления/изменения данных пользователя.
        this.user$ = this._store
            .pipe(
                select(currentUserInfoSelector),
                filter(currentUser => !!currentUser)
            );
    }

    //endregion
    //region Events

    /**
     * Обработчик требования перейти в личный кабинет пользователя.
     */
    userAccountHandler() {

        // TODO Сделать переход в личный кабинет.
        console.log("TODO: Go to user account");
    }

    /**
     * Обработчик события хэдера - выход пользователя из системы
     */
    logoutHandler() {

        this._store.dispatch(new CurrentUserLogoutAction());
    }

    //endregion
}
