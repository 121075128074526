import { HttpClient } from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { map } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
/**
 * Сервис для работы со списком стран.
 */
var CountriesService = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор сервиса для работы со списком стран.
     *
     * @param httpClient HTTP клиент.
     */
    function CountriesService(httpClient) {
        this._httpClient = httpClient;
    }
    //endregion
    //region Public
    /**
     * Загрузка списка стран.
     *
     * @return Страны.
     */
    CountriesService.prototype.get = function () {
        var _this = this;
        return this._httpClient.get("/api/v1/processing/countries")
            .pipe(map(function (response) { return _this._sortPopularCountriesInFront(response.countries); }), catchError(function (response) { return throwError(response.error); }));
    };
    //endregion
    //region Private
    /**
     * Передвигает вверх списка основные страны - (SAU, ARE, IND).
     *
     * @param countries Список стран.
     *
     * @return Список стран.
     */
    CountriesService.prototype._sortPopularCountriesInFront = function (countries) {
        CountriesService.PREFERRED_COUNTRIES.forEach(function (alfa3Code) {
            var findIndex = countries.indexOf(countries.find(function (country) { return country.alpha3Code === alfa3Code; }));
            countries.unshift(countries.splice(findIndex, 1)[0]);
        });
        return countries;
    };
    //region Constants
    /**
     * Основные страны.
     */
    CountriesService.PREFERRED_COUNTRIES = ["SAU", "ARE", "IND"];
    CountriesService.ngInjectableDef = i0.defineInjectable({ factory: function CountriesService_Factory() { return new CountriesService(i0.inject(i1.HttpClient)); }, token: CountriesService, providedIn: "root" });
    return CountriesService;
}());
export { CountriesService };
