import { Store } from "@ngrx/store";
import { select } from "@ngrx/store";
import { currentUserStateSelector } from "rootStore";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { map } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { RouteService } from "../services";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "../services/route.service";
/**
 * Route guard, который проверяет возможность входа на URL в зависимости от наличия аутентифицированного пользователя.
 *
 * Если текущий пользователь есть, то переход по URL возможен, если текущего пользователя нет, то невозможен.
 */
var UserGuard = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор route guard, который проверяет возможность входа на URL в зависимости от наличия
     * аутентифицированного пользователя.
     *
     * @param store Сервис для управления и доступа к состоянию приложения.
     * @param routeService Сервис для выполнения каких-либо переходов по URL'ам.
     */
    function UserGuard(store, routeService) {
        this._store = store;
        this._routeService = routeService;
    }
    //endregion
    //region Public
    /**
     * Проверяет, можно ли осуществлять переход по текущему состоянию навигации и, если нельзя, то перенаправляет на
     * страницу входа в систему.
     *
     * Если текущий пользователь есть, то переход по URL возможен, если текущего пользователя нет, то невозможен.
     *
     * @param route Состояние текущего активированного URL'а.
     * @param state Состояние навигации.
     *
     * @return Да, если можно осуществить переход по текущему состоянию навигации и, если нельзя, то перенаправялет на
     * страницу входа в систему.
     */
    UserGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        return this._getCurrentUserState()
            .pipe(map(function (userState) { return userState.currentUserInfo; }), switchMap(function (user) {
            if (user) {
                return of(true);
            }
            else {
                return of(_this._routeService.goToLogin(state.url));
            }
        }), catchError(function () { return of(_this._routeService.goToLogin(state.url)); }));
    };
    /**
     * Проверяет, можно ли осуществлять переход по текущему состоянию навигации и, если нельзя, то перенаправляет на
     * страницу входа в систему.
     *
     * Если текущий пользователь есть, то переход по URL возможен, если текущего пользователя нет, то невозможен.
     *
     * @param route Состояние текущего активированного URL'а.
     * @param state Состояние навигации.
     *
     * @return Да, если можно осуществить переход по текущему состоянию навигации и, если нельзя, то перенаправялет на
     * страницу входа в систему.
     */
    UserGuard.prototype.canActivateChild = function (route, state) {
        return this.canActivate(route, state);
    };
    //endregion
    //region Private
    /**
     * Возвращает состояние данных текущего аутентифицированного пользователя.
     *
     * Дожидается, когда данные текущего аутентифицированного пользователя будут загружены.
     *
     * @return Состояние данных текущего аутентифицированного пользователя
     */
    UserGuard.prototype._getCurrentUserState = function () {
        return this._store
            .pipe(select(currentUserStateSelector), filter(function (userState) { return userState.loaded || userState.failed; }));
    };
    UserGuard.ngInjectableDef = i0.defineInjectable({ factory: function UserGuard_Factory() { return new UserGuard(i0.inject(i1.Store), i0.inject(i2.RouteService)); }, token: UserGuard, providedIn: "root" });
    return UserGuard;
}());
export { UserGuard };
