import { Injectable } from "@angular/core";

import { Actions } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { ofType } from "@ngrx/effects";
import { TranslateService } from "@ngx-translate/core";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { tap } from "rxjs/operators";

import { LangActionType } from "../actions";
import { LangChangeAction } from "../actions";

/**
 * Side-эффекты на события, связанные с языком интерфейса.
 */
@Injectable()
export class LangEffects {
    //region Fields

    /**
     * Сервис для получения текстов на текущем языке по их ключам.
     */
    private readonly _translateService: TranslateService;

    //endregion
    //region Ctor

    /**
     * Конструктор эффектов на события, связанные с языком интерфейса.
     *
     * @param _actions$ Поток событий, происходящих в системе.
     * @param translateService Сервис для получения текстов на текущем языке по их ключам.
     */
    constructor(
        private _actions$: Actions,
        translateService: TranslateService,
    ) {
        this._translateService = translateService;
    }

    //endregion
    //region Effects

    /**
     * Обработка события изменения языка.
     */
    @Effect({ dispatch: false })
    changeLangEffect$ = this._actions$
        .pipe(
            ofType(LangActionType.CHANGE),
            map((action: LangChangeAction): string => action.payload),
            tap((lang: string): Observable<any> => this._translateService.use(lang))
        );

    //endregion
}
