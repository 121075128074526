var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { tap } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { map } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { UrlUtils } from "../../../common/utils";
import { LoginService } from "../../../login/services";
import { ErrorHandlerService } from "../../services";
import { CurrentUserLoadAction } from "../actions";
import { LoginActionType } from "../actions";
import { RouterGoAction } from "../actions";
import { SignInFailedAction } from "../actions";
import { SignInSucceededAction } from "../actions";
import { SignOutSucceededAction } from "../actions";
import { SignOutFailedAction } from "../actions";
/**
 * Side-эффекты на события, связанные со входом в систему и выходом из неё.
 */
var LoginEffects = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор класса с side-эффектами на события, связанными со входом в систему и выходом из неё.
     *
     * @param _actions$ Поток событий, происходящих в системе.
     * @param loginService Сервис для входа в систему.
     * @param store Состояние системы.
     * @param errorHandlerService Сервис с общей логикой обработки ошибочных ответов от API.
     */
    function LoginEffects(_actions$, loginService, store, errorHandlerService) {
        var _this = this;
        this._actions$ = _actions$;
        //endregion
        //region Effects
        /**
         * Обработка события с требованием выполнить вход в систему.
         */
        this.signIn$ = this._actions$
            .pipe(ofType(LoginActionType.SIGN_IN), map(function (signInAction) { return signInAction.props; }), switchMap(function (signInProps) {
            return _this._loginService.signIn(signInProps.credentials)
                .pipe(map(function () {
                return new SignInSucceededAction({ redirectTo: signInProps.redirectTo });
            }), catchError(function (error) { return of(new SignInFailedAction(error)); }));
        }));
        /**
         * Обработка события успешного входа в систему.
         *
         * Создаёт событие, требующее загрузки данных текущего пользователя.
         */
        this.loadCurrentUser$ = this._actions$
            .pipe(ofType(LoginActionType.SIGN_IN_SUCCEEDED), map(function () { return new CurrentUserLoadAction(); }));
        /**
         * Обработка события успешного входа в систему.
         *
         * Создаёт событие, требующее перехода по перенаправляющему пути после успешного входа в систему.
         */
        this.routeToRedirectUrl$ = this._actions$
            .pipe(ofType(LoginActionType.SIGN_IN_SUCCEEDED), tap(function (action) {
            if (action.props.redirectTo && action.props.redirectTo.toLowerCase().startsWith("http")) {
                window.location.href = action.props.redirectTo;
            }
        }), map(function (signInSucceededAction) {
            var routerGoProps = { path: [""] };
            if (signInSucceededAction.props.redirectTo) {
                routerGoProps = UrlUtils.parse(signInSucceededAction.props.redirectTo);
            }
            return routerGoProps;
        }), map(function (redirectTo) { return new RouterGoAction(redirectTo); }));
        /**
         * Обработка события с требованием выполнить выход из системы.
         */
        this.logout$ = this._actions$
            .pipe(ofType(LoginActionType.SIGN_OUT), map(function (signOutAction) { return signOutAction.props.expired; }), filter(function (expired) { return !expired; }), switchMap(function () {
            return _this._loginService.signOut()
                .pipe(map(function () { return new SignOutSucceededAction(); }), catchError(function (error) {
                return _this._errorHandlerService.handle(error, new SignOutFailedAction(error));
            }));
        }));
        /**
         * Обработка события с требованием выполнить выход из системы в случае, когда сессия истекла и запрос к серверу
         * выполнять не нужно.
         */
        this.expiredLogout$ = this._actions$
            .pipe(ofType(LoginActionType.SIGN_OUT), map(function (signOutAction) { return signOutAction.props.expired; }), filter(function (expired) { return expired; }), map(function () { return new SignOutSucceededAction(); }));
        /**
         * Обработка события успешного выхода из системы.
         *
         * Создаёт событие с для перехода на форму входа.
         */
        this.logoutSucceeded$ = this._actions$
            .pipe(ofType(LoginActionType.SIGN_OUT_SUCCEEDED), map(function () { return new RouterGoAction({ path: UrlUtils.loginPageRoute() }); }));
        this._loginService = loginService;
        this._store = store;
        this._errorHandlerService = errorHandlerService;
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], LoginEffects.prototype, "signIn$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], LoginEffects.prototype, "loadCurrentUser$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], LoginEffects.prototype, "routeToRedirectUrl$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], LoginEffects.prototype, "logout$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], LoginEffects.prototype, "expiredLogout$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], LoginEffects.prototype, "logoutSucceeded$", void 0);
    return LoginEffects;
}());
export { LoginEffects };
