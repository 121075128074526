import { Action } from "@ngrx/store";
import { ApiResponse } from "src/app/common/models/api-response.model";
import { CurrentUserInfo } from "src/app/common/models/current-user-info";
import { ApiResponsePayloadAction } from "src/app/root/store/actions/api-response-payload-action.model";

/**
 * Типы событий, связанные с текущим пользователем.
 */
export enum CurrentUserActionType {
    LOAD = '[Root] Load CurrentUser',
    LOAD_SUCCESS = '[Root] Load CurrentUser Success',
    LOAD_FAIL = '[Root] Load CurrentUser Fail',
    LOGOUT = '[Root] Logout CurrentUser',
    LOGOUT_SUCCESS = '[Root] Logout CurrentUser Success',
    LOGOUT_FAIL = '[Root] Logout CurrentUser Fail',
}

/**
 * Событие, требующее загрузки данных текущего пользователя.
 */
export class CurrentUserLoadAction implements Action {
    readonly type = CurrentUserActionType.LOAD;
}

/**
 * Событие успешной загрузки текущего пользователя.
 */
export class CurrentUserLoadSuccessAction implements Action {
    readonly type = CurrentUserActionType.LOAD_SUCCESS;
    constructor(public payload: CurrentUserInfo) { }
}

/**
 * Событие неудачной загрузки текущего пользователя.
 */
export class CurrentUserLoadFailAction implements ApiResponsePayloadAction {
    readonly type = CurrentUserActionType.LOAD_FAIL;
    constructor(public payload: ApiResponse) { }
}

/**
 * Событие, требующее выполнение выхода из системы текущего пользователя.
 */
export class CurrentUserLogoutAction implements Action {
    readonly type = CurrentUserActionType.LOGOUT;
}

/**
 * Событие успешного выполнение выхода из системы текущего пользователя.
 */
export class CurrentUserLogoutSuccessAction implements Action {
    readonly type = CurrentUserActionType.LOGOUT_SUCCESS;
}

/**
 * Событие неудачного выполнение выхода из системы текущего пользователя.
 */
export class CurrentUserLogoutFailAction implements ApiResponsePayloadAction {
    readonly type = CurrentUserActionType.LOGOUT_FAIL;
    constructor(public payload: ApiResponse) { }
}

/**
 * Тип, объединяющий все события, связанные с текущим пользователем.
 */
export type CurrentUserAction =
    | CurrentUserLoadAction
    | CurrentUserLoadFailAction
    | CurrentUserLoadSuccessAction
    | CurrentUserLogoutAction
    | CurrentUserLogoutSuccessAction;
