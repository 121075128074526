/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./simple-alert-dlg.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "@ngx-translate/core";
import * as i9 from "../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i10 from "@angular/material/progress-spinner";
import * as i11 from "./simple-alert-dlg.component";
import * as i12 from "@angular/material/dialog";
var styles_SimpleAlertDlgComponent = [i0.styles];
var RenderType_SimpleAlertDlgComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SimpleAlertDlgComponent, data: {} });
export { RenderType_SimpleAlertDlgComponent as RenderType_SimpleAlertDlgComponent };
function View_SimpleAlertDlgComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["class", "simple-alert-dlg__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.text)); _ck(_v, 1, 0, currVal_0); }); }
function View_SimpleAlertDlgComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "hr", [["class", "simple-alert-dlg__divider"]], null, null, null, null, null))], null, null); }
function View_SimpleAlertDlgComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.okClickHandler() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(2, 0, [" ", " "])), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.okBtnKey)); _ck(_v, 2, 0, currVal_2); }); }
function View_SimpleAlertDlgComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "simple-alert-dlg__buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeClickHandler() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(3, 0, [" ", " "])), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimpleAlertDlgComponent_4)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.hasOkButton; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.closeBtnKey)); _ck(_v, 3, 0, currVal_2); }); }
function View_SimpleAlertDlgComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "simple-alert-dlg__loading mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i9.View_MatSpinner_0, i9.RenderType_MatSpinner)), i1.ɵdid(1, 49152, null, 0, i10.MatSpinner, [i1.ElementRef, i5.Platform, [2, i2.DOCUMENT], [2, i7.ANIMATION_MODULE_TYPE], i10.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null)], function (_ck, _v) { var currVal_3 = 64; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_SimpleAlertDlgComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "simple-alert-dlg"]], [[2, "simple-alert-dlg_loading", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [["class", "mat-h2 simple-alert-dlg__header"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimpleAlertDlgComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimpleAlertDlgComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimpleAlertDlgComponent_3)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimpleAlertDlgComponent_5)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.loading; _ck(_v, 5, 0, currVal_2); var currVal_3 = !_co.loading; _ck(_v, 7, 0, currVal_3); var currVal_4 = !_co.loading; _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.loading; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.headerKey, _co.headerParams)); _ck(_v, 2, 0, currVal_1); }); }
export function View_SimpleAlertDlgComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "simple-alert-dlg", [], null, null, null, View_SimpleAlertDlgComponent_0, RenderType_SimpleAlertDlgComponent)), i1.ɵdid(1, 114688, null, 0, i11.SimpleAlertDlgComponent, [i12.MatDialogRef, i1.ChangeDetectorRef, i12.MAT_DIALOG_DATA, i8.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SimpleAlertDlgComponentNgFactory = i1.ɵccf("simple-alert-dlg", i11.SimpleAlertDlgComponent, View_SimpleAlertDlgComponent_Host_0, {}, {}, []);
export { SimpleAlertDlgComponentNgFactory as SimpleAlertDlgComponentNgFactory };
