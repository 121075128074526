import { OnInit } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { Component } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { Input } from '@angular/core';
import { HostBinding } from '@angular/core';
import { Self } from '@angular/core';
import { Optional } from '@angular/core';

import { NgControl } from '@angular/forms';
import { ControlValueAccessor } from '@angular/forms';
import { FormControl } from '@angular/forms';

import { MatFormFieldControl } from '@angular/material';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { Subject } from 'rxjs';

import { UtilsService } from '../../services';

/**
 * Результат попытки приведения строки к числу.
 */
class ParseResult {
    //region Fields

    /**
     * Получившийся результат.
     */
    value: number = null;

    /**
     * Флаг, что строка не являлась корректным числом.
     */
    isInvalidNumber: boolean = false;

    //endregion
}

/**
 * Компонент контрола (элемента формы) для ввода числа.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: NumberInputComponent
        }
    ],
    selector: 'number-input',
    styleUrls: ['number-input.component.scss'],
    templateUrl: 'number-input.component.html'
})
export class NumberInputComponent implements OnInit, OnDestroy, ControlValueAccessor, MatFormFieldControl<number> {
    //region Inputs

    /**
     * Входящие данные - значение для контрола.
     *
     * @param value Значение для контрола.
     */
    @Input()
    set value(value: number) {

        this.writeValue(value);
    }

    /**
     * Входящие данные - значение, если поле для ввода будет пустым.
     */
    @Input()
    valueOnEmpty: number;

    /**
     * Входящие данные - кол-во знаков после запятой.
     */
    @Input()
    precision: number = 2;

    /**
     * Входящие данные - placeholder поля.
     */
    @Input()
    set placeholder(value: string) {

        this._placeholder = value;
        this.stateChanges.next();
    }

    /**
     * Входящие данные - обязательность заполнения поля.
     */
    @Input()
    set required(required: boolean) {

        this._required = coerceBooleanProperty(required);
        this.stateChanges.next();
    }

    /**
     * Входящие данные - флаг выключения поля.
     */
    @Input()
    set disabled(disabled: boolean) {

        this.setDisabledState(coerceBooleanProperty(disabled));
    }

    /**
     * Входящие данные - включение/отключение логирования внутренней работы контрола. По умолчанию логирование
     * выключено.
     */
    @Input()
    logEnabled: boolean = false;

    /**
     * Входящие данные - включение/отключение возможности присвоения отрицательного значения при снятии фокуса.
     */
    @Input()
    negativeAllowed: boolean = true;

    //endregion
    //region Public fields

    /**
     * Внутренний контрол, привязанный к текстовому полю.
     */
    valueControl: FormControl = new FormControl();

    /**
     * Поток, который уведомляет подписчиков о том, что состояние контрола изменилось.
     */
    stateChanges: Subject<void> = new Subject<void>();

    /**
     * Счётчик для создания уникальных ID экземпляров контрола.
     */
    static nextId = 0;

    /**
     * Управление ID-ком host-элемента.
     */
    @HostBinding()
    id = `number-input-${NumberInputComponent.nextId++}`;

    /**
     * Контрол модели для взаимодействия со значением контрола.
     */
    ngControl: NgControl;

    /**
     * Флаг того, что фокус находится в поле для ввода.
     */
    focused: boolean;

    /**
     * Управление атрибутом aria-describedby host-элемента.
     */
    @HostBinding('attr.aria-describedby')
    describedBy: string = '';

    //endregion
    //region Private fields

    /**
     * Текущее значение контрола.
     *
     * @private
     */
    private _value: number = null;

    /**
     * Callback, когда введённое значение изменилось.
     *
     * @private
     */
    private _changeCallback: Function = () => {};

    /**
     * Callback, когда пользователь начал взаимодействовать с полем для ввода.
     *
     * @private
     */
    private _touchCallback: Function = () => {};

    /**
     * Утилиты.
     *
     * @private
     */
    private _utilService: UtilsService;

    /**
     * Placeholder поля ввода.
     *
     * @private
     */
    private _placeholder: string;

    /**
     * Поле обязательно для заполнения?
     *
     * @private
     */
    private _required = false;

    /**
     * Поле отключено?
     *
     * @private
     */
    private _disabled = false;

    /**
     * Пользователь прикасался к контролу?
     *
     * @private
     */
    private _touched = false;

    //endregion
    //region Ctor

    constructor(
        @Optional() @Self() ngControl: NgControl,
        utilService: UtilsService
    ) {
        this._utilService = utilService;

        // Эта логика добавлена вместо
        // {
        //     provide: NG_VALUE_ACCESSOR,
        //     useExisting: forwardRef(() => NumberInputComponent),
        //     multi: true
        // }
        // В противном случае возникает ошибка. Это решение описано на официальном сайте Angular Material:
        // https://v6.material.angular.io/guide/creating-a-custom-form-field-control#-code-ngcontrol-code-
        this.ngControl = ngControl;
        if (this.ngControl != null) {

            this.ngControl.valueAccessor = this;
        }
    }

    //endregion
    //region Hooks

    ngOnInit(): void {

        this._log('Number input control initiated');
    }

    ngOnDestroy(): void {

        this.stateChanges.complete();
        this._log('Number input control destroyed');
    }

    //endregion
    //region ControlValueAccessor

    /**
     * Установка значения в контрол (model -> view).
     *
     * @param value Значение для установки в контрол.
     */
    writeValue(value: any): void {

        this._log('model -> view: initial value ', value);

        const parseResult = this._parseNumber(value);
        this._log('model -> view: parsed value ', parseResult);

        this._log('model -> view: current control value ', this._value);

        if (this._value !== parseResult.value) {

            this._log('model -> view: change inner value');

            this._value = parseResult.value;
            this.valueControl.setValue(this._formatNumber(this._value));

            this._changeCallback(this._value);
            this.stateChanges.next();
        }
    }

    /**
     * Регистрация callback'а для события, когда значение в контроле изменилось (view -> model).
     *
     * @param fn Callback для события, когда значение в контроле изменилось.
     */
    registerOnChange(fn: Function): void {

        this._changeCallback = fn;
    }

    /**
     * Регистрация callback'а для события, когда пользователь начал взаимодействовать с контролом.
     *
     * @param fn Callback для события, когда пользователь начал взаимодействовать с контролом.
     */
    registerOnTouched(fn: Function): void {

        this._touchCallback = fn;
    }

    /**
     * Включение/отключение контрола.
     *
     * @param isDisabled Флаг включённости/отключённости контрола.
     */
    setDisabledState(isDisabled: boolean): void {

        if (isDisabled && this.valueControl.enabled) {

            this.valueControl.disable();
        }
        else if (!isDisabled && this.valueControl.disabled) {

            this.valueControl.enable();
        }

        this._disabled = isDisabled;
        this.stateChanges.next();
    }

    //endregion
    //region MatFormFieldControl

    /**
     * Значение в контроле.
     */
    get value(): number {

        return this._value;
    }

    /**
     * Placeholder поля ввода.
     */
    get placeholder(): string {

        return this._placeholder;
    }

    /**
     * Значение в контроле пустое?
     */
    get empty(): boolean {

        return (typeof this._value !== 'number' && !this._value);
    }

    /**
     * Label контрола должен находиться в верхней части?
     *
     * Например, для matInput, когда ставишь фокус в поле, placeholder перемещается наверх. Здесь аналоничная логика,
     * если фокус в поле ввода и есть какое-то значение, то placeholder должен перемещаться наверх.
     */
    get shouldLabelFloat(): boolean {

        return this.focused || !this.empty;
    }

    /**
     * Поле обязательно для заполнения?
     */
    get required(): boolean {

        return this._required;
    }

    /**
     * Поле отключено?
     */
    get disabled(): boolean {

        return this._disabled;
    }

    /**
     * Есть ошибки ввода в контрол?
     */
    get errorState(): any {

        let errorState: any = false;
        if (this._required && this._value === null) {

            errorState = {
                required: true,
            };
        }

        if (this.ngControl.errors) {

            errorState = {
                ...this.ngControl.errors,
                ...errorState,
            }
        }

        return errorState;
    }

    /**
     * Тип контрола.
     *
     * Эта строка используется для формирования css-класса, который добавляется к mat-form-field.
     */
    get controlType(): string {

        return 'number-input';
    }

    /**
     * Устанавливает заданный массив ID-ков в атрибут aria-describedby.
     *
     * @param ids Массив ID-ков.
     */
    setDescribedByIds(ids: string[]): void {

        this.describedBy = ids.join(' ');
    }

    /**
     * Обработчик клика по контролу.
     *
     * @param event Событие клика.
     */
    onContainerClick(event: MouseEvent): void { }

    //endregion
    //region Events

    /**
     * Обработчик события получения фокуса поля для ввода.
     */
    focusEventHandler(): void {

        this._log('Touch event: control value', this._value);
        this.focused = true;
        this._touched = true;
        this._touchCallback();
        this.stateChanges.next();
    }

    /**
     * Обработчик событий потери фокуса поля для ввода.
     */
    blurEventHandler(): void {

        this._log('Blur event: previous control value', this._value);

        const viewValue = this.valueControl.value;
        this._log('Blur event: view value', viewValue);

        const parseResult = this._parseNumber(viewValue);
        this._log('Blur event: parsed control value', parseResult);

        if (!parseResult.isInvalidNumber && this._value !== parseResult.value) {

            this._value = parseResult.value;
            this._log('Blur event: new control value', this._value);

            if (!this.negativeAllowed && this._value < 0) {

                this._value = this._value * (-1);
            }

            this._changeCallback(this._value);
            this.stateChanges.next();
        }

        const formattedValue = this._formatNumber(this._value);
        if (parseResult.isInvalidNumber) {

            this._log('Blur event: restore previous view value', formattedValue);
        }

        this.valueControl.setValue(formattedValue);

        this.focused = false;
        this.stateChanges.next();
    }

    //endregion
    //region Private

    /**
     * Выполняет попытку приведения заданного значения к числу.
     *
     * @param value Какое-то значение.
     *
     * @return Результат приведения значения к числу.
     *
     * @private
     */
    private _parseNumber(value: any): ParseResult {

        let result = new ParseResult();

        if (value === null || value === undefined) {

            value = '';
        }

        // Если и так число задано, то его и берём.
        if (typeof value === 'number' && isFinite(value) && !isNaN(value)) {

            result.value = value;
        }
        // Если строка, то пытаемся её парсить.
        else if (typeof value === 'string') {

            // Возможное отделение дробной части запятой заменяем на точку.
            value = value.trim().replace(/,/g, '.');

            // Если точки в строке нет, то обрабатываем случай, когда в качестве разделителя дробной части
            // выступает пробел.
            if (value.indexOf('.') === -1) {

                // Удаляем все пробелы, кроме последнего и зменяем его на точку.
                value = this._utilService.removeAllButLast(value, ' ').replace(' ', '.');
            }
            // Если точка есть, то все пробелы удаляем.
            else {

                value = value.replace(/\s+/g, '');
            }

            // Если преобразованная строка является числом, то парсим его и округляем.
            if (this._utilService.isNumber(value)) {

                result.value = parseFloat(parseFloat(value).toFixed(this.precision));
            }
            else if (value === '') {

                result.value = (this.valueOnEmpty !== undefined && this._touched
                    ? this.valueOnEmpty
                    : null
                );
            }
            else {

                result.isInvalidNumber = true;
            }
        }

        return result;
    }

    /**
     * Выполняет форматирование заданного числа.
     *
     * @param value Число.
     *
     * @return Форматированное число.
     *
     * @private
     */
    private _formatNumber(value: number): string {

        let result: string = '';
        if (typeof value === 'number') {

            result = this._utilService.formatNumber(value, this.precision);
        }

        return result;
    }

    /**
     * Выполняет логирование заданной информации в зависимости от того, включено ли логирование внутренней работы
     * контрола или нет.
     *
     * @param params Данные для логирования.
     *
     * @private
     */
    private _log(...params: any[]): void {

        if (this.logEnabled) {

            params.unshift(`[${this.id}]`);
            console.log.apply(console, params);
        }
    }

    //endregion
}
