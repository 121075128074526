import { HostListener } from "@angular/core";
import { Directive } from "@angular/core";

/**
 * Директива-декоратор для замены поведения Enter.
 */
@Directive({
    selector: "[tabOnEnter]",
})
export class TabOnEnterDirective {
    //region Constants

    /**
     * Ключ события нажатия по кнопке ввода.
     */
    private static readonly ENTER_KEY: string = "Enter";

    /**
     * Типы тэгов HTML-элементов, среди которых можем переключаться.
     */
    private static readonly TABBABLE_TAGS: string = "input, checkbox, mat-input, mat-select, mat-checkbox";

    //endregion
    //region Events

    /**
     * Обработчик события нажатия клавиши.
     *
     * При нажатии Enter отменяет стандартное поведение.
     *
     * Ищет следующий элемент и фокусируется на него. Снимает фокус с текущего элемента.
     * Если есть matSelectToNotOpen, то закрывает его, так как для кастомных mat-select-ов сложно добиться логики
     * разфокусировки, мы застреваем в поисковике, а к нему доступа нет чтобы его закрыть или разфокусироваться.
     *
     * @param e Событие нажатия клавиши.
     */
    @HostListener("keydown", ["$event"])
    onKeyDownHandler(e: KeyboardEvent) {

        if (e.key === TabOnEnterDirective.ENTER_KEY) {
            e.preventDefault();
            e.stopPropagation();

            const inputs = document.querySelectorAll(TabOnEnterDirective.TABBABLE_TAGS);
            const currentElement: any = document.activeElement;

            for (let i = 0; i < inputs.length; i++) {

                if (inputs[i] === currentElement) {

                    const next: any = i === inputs.length - 1 ? inputs[0] : inputs[i + 1];

                    if (next.focus) {

                        currentElement.blur();
                        next.focus();
                    }
                    break;
                }
            }
        }
    }

    //endregion
}
