/**
 * Параметры фона интерфейса оператора.
 */
export interface OperatorBackground {

    /**
     * День декабря, с которого начинается новогодний период.
     */
    readonly newYearStyleFromDecemberDate: number;

    /**
     * День января, в котором заканчивается новогодний период.
     */
    readonly newYearStyleToJanuaryDate: number;

    /**
     * URL изображения для установки на фон интерфейса оператора в новогодний период.
     */
    readonly newYearStyleBackgroundFileUrl: string;

    /**
     * URL изображения для установки на фон интерфейса оператора в зимний сезон.
     */
    readonly winterStyleBackgroundFileUrl: string;

    /**
     * URL изображения для установки на фон интерфейса оператора в весенний сезон.
     */
    readonly springStyleBackgroundFileUrl: string;

    /**
     * URL изображения для установки на фон интерфейса оператора в летний сезон.
     */
    readonly summerStyleBackgroundFileUrl: string;

    /**
     * URL изображения для установки на фон интерфейса оператора в осенний сезон.
     */
    readonly autumnStyleBackgroundFileUrl: string;
}

/**
 * Стандартные параметры фона интерфейса оператора.
 */
export const defaultOperatorBackgroundEnvironment: OperatorBackground = {
    newYearStyleFromDecemberDate: 15,
    newYearStyleToJanuaryDate: 15,
    newYearStyleBackgroundFileUrl: "./assets/images/new-year-operator-background.jpg",
    winterStyleBackgroundFileUrl: "./assets/images/default-operator-background.jpg",
    springStyleBackgroundFileUrl: "./assets/images/default-operator-background.jpg",
    summerStyleBackgroundFileUrl: "./assets/images/default-operator-background.jpg",
    autumnStyleBackgroundFileUrl: "./assets/images/default-operator-background.jpg",
};
