import { ChangeDetectionStrategy } from "@angular/core";
import { Component } from "@angular/core";

/**
 * Компонент иконки txt файла.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "txt-file-icon-svg",
    styleUrls: ["./txt-file-icon-svg.component.scss"],
    templateUrl: "./txt-file-icon-svg.component.html",
})
export class TxtFileIconSvgComponent { }
