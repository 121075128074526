var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Router } from "@angular/router";
import { Actions } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { select } from "@ngrx/store";
import { of } from "rxjs";
import { filter } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { map } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { currenciesActions } from "src/app/root/store/actions/currencies.action";
import { countriesActions } from "src/app/root/store/actions/countries.action";
import { websocketActions } from "src/app/root/store/actions/websocket.actions";
import { DlgService } from "../../../common/services";
import { UserService } from "../../../common/services";
import { ErrorHandlerService } from "../../services";
import { DocumentTypesLoadAction } from "../actions";
import { CurrentUserActionType } from "../actions";
import { CurrentUserLoadFailAction } from "../actions";
import { CurrentUserLoadSuccessAction } from "../actions";
import { CurrentUserLogoutFailAction } from "../actions";
import { CurrentUserLogoutSuccessAction } from "../actions";
import { currentUserInfoSelector } from "../selectors";
import { CurrentUserLogoutAction } from "../actions";
/**
 * Side-эффекты на события, связанные с текущим пользователем.
 */
var CurrentUserEffects = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор эффектов на события, связанные с текущим пользователем.
     *
     * @param _actions$ Поток событий, происходящих в системе.
     * @param _document Сервис для работы с DOM.
     * @param userService Сервис с логикой для работы с пользователем.
     * @param store Состояние приложения.
     * @param router Сервис для работы с навигацией по приложению.
     * @param errorHandlerService Сервис с общей логикой обработки ошибочных ответов от API.
     * @param dlgService Сервис для создания диалогов.
     */
    function CurrentUserEffects(_actions$, _document, userService, store, router, errorHandlerService, dlgService) {
        var _this = this;
        this._actions$ = _actions$;
        this._document = _document;
        //endregion
        //region Effects
        /**
         * Обработка события требования загрузки данных текущего пользователя.
         *
         * Если аутентифицированного пользователя нет, то будет редирект на форму логина.
         */
        this.loadCurrentUserInfo$ = this._actions$
            .pipe(ofType(CurrentUserActionType.LOAD), switchMap(function () {
            return _this._userService.getCurrentUser()
                .pipe(map(function (currentUserInfo) {
                return new CurrentUserLoadSuccessAction(currentUserInfo);
            }), catchError(function (response) {
                return of(new CurrentUserLoadFailAction(response));
            }));
        }));
        /**
         * Обработка события удачной загрузки данных текущего пользователя.
         *
         * Если есть загруженный пользователь, диспатчит событие, требующее загрузки типов документов.
         */
        this.loadCurrentUserSuccess$ = this._actions$
            .pipe(ofType(CurrentUserActionType.LOAD_SUCCESS), map(function (action) { return action.payload; }), filter(Boolean), map(function () { return new DocumentTypesLoadAction(); }));
        /**
         * Обработка события удачной загрузки данных текущего пользователя.
         *
         * Если есть загруженный пользователь, диспатчит событие, требующее загрузки списка стран.
         */
        this.loadCountries$ = this._actions$
            .pipe(ofType(CurrentUserActionType.LOAD_SUCCESS), map(function (action) { return action.payload; }), filter(Boolean), map(function () { return countriesActions.countriesLoad(); }));
        /**
         * Обработка события удачной загрузки данных текущего пользователя.
         *
         * Если есть загруженные пользователь, диспатчит событие, требующее загрузки списка валют.
         */
        this.loadCurrencies$ = this._actions$
            .pipe(ofType(CurrentUserActionType.LOAD_SUCCESS), map(function (action) { return action.payload; }), filter(Boolean), map(function () { return currenciesActions.currenciesLoad(); }));
        /**
         * Обработка события требования выхода текущего пользователя из системы.
         */
        this.currentUserLogout$ = this._actions$
            .pipe(ofType(CurrentUserActionType.LOGOUT), switchMap(function () {
            return _this._userService.logout()
                .pipe(map(function () { return new CurrentUserLogoutSuccessAction(); }), catchError(function (response) {
                return _this._errorHandlerService.handle(response, new CurrentUserLogoutFailAction(response));
            }));
        }));
        /**
         * Обработка события загрузки данных пользователя.
         *
         * Сравнение локального времени и времени на сервере. Если различие слишком велико, то выводится диалог с
         * предупреждением.
         */
        this.compareLocalAndServerDate$ = this._actions$
            .pipe(ofType(CurrentUserActionType.LOAD_SUCCESS), map(function (action) { return action.payload; }), tap(function (userInfo) {
            if (!!userInfo && !!userInfo.serverDate) {
                var serverDate = new Date(userInfo.serverDate);
                var localDateDifference = Math.abs(((new Date()).getTime() - serverDate.getTime()));
                if (localDateDifference > CurrentUserEffects.ONE_MINUTE) {
                    _this._dlgService.openSimpleDlg({
                        headerKey: "common.attention",
                        textKey: "dialogs.server-to-local-time-error.text",
                        okCallback: function () { return _this._store.dispatch(new CurrentUserLogoutAction()); },
                        cancelCallback: function () { return _this._store.dispatch(new CurrentUserLogoutAction()); },
                        disableClose: true,
                    });
                }
            }
        }));
        this.currentUserLogoutWebsocket$ = this._actions$
            .pipe(ofType(CurrentUserActionType.LOGOUT), map(function () { return websocketActions.disconnected(); }));
        /**
         * Обработка успешного выхода текущего пользователя из системы.
         */
        this.currentUserLogoutSuccess$ = this._actions$
            .pipe(ofType(CurrentUserActionType.LOGOUT_SUCCESS), tap(function () {
            // Переходим на корневой путь,
            //TODO редирект на логин
            _this._document.location.href = window.location.origin;
        }));
        this._userService = userService;
        this._store = store;
        this._router = router;
        this._errorHandlerService = errorHandlerService;
        this._dlgService = dlgService;
        // Подписываемся на данные текущего пользователя.
        this._store
            .pipe(select(currentUserInfoSelector))
            .subscribe(function (currentUser) { return _this._currentUser = currentUser; });
    }
    //region Constants
    CurrentUserEffects.ONE_MINUTE = 60 * 1000;
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CurrentUserEffects.prototype, "loadCurrentUserInfo$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CurrentUserEffects.prototype, "loadCurrentUserSuccess$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CurrentUserEffects.prototype, "loadCountries$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CurrentUserEffects.prototype, "loadCurrencies$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CurrentUserEffects.prototype, "currentUserLogout$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], CurrentUserEffects.prototype, "compareLocalAndServerDate$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CurrentUserEffects.prototype, "currentUserLogoutWebsocket$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], CurrentUserEffects.prototype, "currentUserLogoutSuccess$", void 0);
    return CurrentUserEffects;
}());
export { CurrentUserEffects };
