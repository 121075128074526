import { Injectable } from "@angular/core";

import { ofType } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { Actions } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Store } from "@ngrx/store";
import { RootState } from "src/app/root/store/states/root.state";
import { Observable } from "rxjs";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";
import { map } from "rxjs/operators";
import { switchMap } from "rxjs/operators";

import { ApiResponse } from "src/app/common/models";
import { ComplaintOnDocumentRequest } from "src/app/root/models/complaint-on-document-request.model";
import { DocumentComplaint } from "src/app/root/models/document-complaint.model";
import { ComplaintOnDocumentDlgService } from "src/app/root/services/complaint-on-document-dlg.service";
import { DocumentService } from "src/app/root/services/document.service";
import { ErrorHandlerService } from "src/app/root/services/error-handler.service";
import { ComplaintOnDocumentFailAction } from "src/app/root/store/actions/complaint-on-documnet-dlg.action";
import { ComplaintOnDocumentSuccessAction } from "src/app/root/store/actions/complaint-on-documnet-dlg.action";
import { ComplaintOnDocumentRequestAction } from "src/app/root/store/actions/complaint-on-documnet-dlg.action";
import { ComplaintOnDocumentActionType } from "src/app/root/store/actions/complaint-on-documnet-dlg.action";

/**
 * Side-эффекты на события, связанные с запросом жалобы оператора на документ.
 */
@Injectable()
export class ComplaintOnDocumentDlgEffects {
    //region Fields

    /**
     * Сервис для работы с диалогом жалобы оператора на документ.
     */
    private readonly _complaintOnDocumentDlgService: ComplaintOnDocumentDlgService;

    /**
     * Сервис для работы с документами.
     */
    private readonly _documentService: DocumentService;

    /**
     * Сервис обработки ошибочных ответов от API.
     */
    private readonly _errorHandleService: ErrorHandlerService;

    //endregion
    //region Ctor

    /**
     * Конструктор объекта обработки side-эффектов на события, связанные со скачиванием файла реестра.
     *
     * @param _actions$ Поток событий в системе.
     * @param _store Хранилище.
     * @param complaintOnDocumentDlgService Сервис для работы с диалогом жалобы оператора на документ.
     * @param documentService Сервис для работы с документами.
     * @param errorHandleService Сервис обработки ошибочных ответов от API.
     */
    constructor(
        private _actions$: Actions,
        private _store: Store<RootState>,
        complaintOnDocumentDlgService: ComplaintOnDocumentDlgService,
        documentService: DocumentService,
        errorHandleService: ErrorHandlerService
    ) {
        this._complaintOnDocumentDlgService = complaintOnDocumentDlgService;
        this._documentService = documentService;
        this._errorHandleService= errorHandleService
    }

    //endregion
    //region Ctor

    /**
     * Обработка события с требованием пожаловаться на документ.
     */
    @Effect()
    complaint$ = this._actions$
        .pipe(
            ofType(ComplaintOnDocumentActionType.COMPLAINT_ON_DOCUMENT_REQUEST),
            map((action: ComplaintOnDocumentRequestAction) => action.payload),
            switchMap((request: ComplaintOnDocumentRequest): Observable<Action> =>
                this._documentService.complaint(request)
                    .pipe(
                        map((): Action => new ComplaintOnDocumentSuccessAction()),
                        catchError((response: ApiResponse): Observable<Action> =>
                            this._errorHandleService.handle(response, new ComplaintOnDocumentFailAction((response)))
                        ),
                    )
            )
        );

    //endregion
}
