var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CustomerCreationActionType } from "../actions/company";
import { SupplierCreationActionType } from "../actions/company";
import { CompanySubmissionState } from "src/app/operator/store/reducers/company-submission.state";
/**
 * Начальное состояние по данным создания компании.
 */
var initialState = new CompanySubmissionState();
/**
 * Обработка состояния создания компании постащика.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 */
export function supplierCreationReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var result = state;
    switch (action.type) {
        // На событие требования загрузки данных.
        case SupplierCreationActionType.CREATE: {
            result = __assign({}, state, { company: action.payload, loading: true, loaded: false, failed: false, errorData: null });
            break;
        }
        // На событие успешной загрузки данных.
        case SupplierCreationActionType.CREATE_SUCCESS: {
            result = __assign({}, state, { company: action.payload, loaded: true, loading: false });
            break;
        }
        // На событие неудачной загрузки данных.
        case SupplierCreationActionType.CREATE_FAIL: {
            result = __assign({}, state, { loaded: false, loading: false, failed: true, errorData: action.payload });
            break;
        }
    }
    return result;
}
/**
 * Обработка состояния создания компании постащика.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 */
export function customerCreationReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var result = state;
    switch (action.type) {
        // На событие требования загрузки данных.
        case CustomerCreationActionType.CREATE: {
            result = __assign({}, state, { company: __assign({}, action.payload), loading: true, loaded: false, failed: false, errorData: null });
            break;
        }
        // На событие успешной загрузки данных.
        case CustomerCreationActionType.CREATE_SUCCESS: {
            result = __assign({}, state, { company: __assign({}, action.payload), loaded: true, loading: false });
            break;
        }
        // На событие неудачной загрузки данных.
        case CustomerCreationActionType.CREATE_FAIL: {
            result = __assign({}, state, { loaded: false, loading: false, failed: true, errorData: __assign({}, action.payload) });
            break;
        }
    }
    return result;
}
/**
 * Возвращает данные компании.
 *
 * @param state Состояние данных о создании компании.
 */
export var getCompany = function (state) { return state.company; };
/**
 * Возвращает флаг о успешном создании компании.
 *
 * @param state Состояние данных о создании компании.
 */
export var getCompanyCreated = function (state) { return state.loaded; };
/**
 * Возвращает флаг выполнения загрузки для создания компании.
 *
 * @param state Состояние данных о создании компании.
 */
export var getCompanyCreateLoading = function (state) { return state.loading; };
/**
 * Создание компании завершилосьб не удачей.
 *
 * @param state Состояние данных о создании компании.
 */
export var getCompanyCreatedFailed = function (state) { return ({ failed: state.failed, errorData: state.errorData }); };
