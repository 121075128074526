import { props } from "@ngrx/store";
import { createAction } from "@ngrx/store";
import { ApiResponse } from "src/app/common/models/api-response.model";
import { CountriesActionProps } from "src/app/root/store/actions/props/countries.action-props";

/**
 * Типы событий, связанные со странами.
 */
export enum CountriesActionType {
    LOAD = "[Root] Load countries",
    LOAD_SUCCESS = "[Root] Load countries Success",
    LOAD_FAIL = "[Root] Load countries Fail",
}

/**
 * Событие, требующее загрузки списка стран.
 */
const countriesLoad = createAction(CountriesActionType.LOAD);

/**
 * Событие успешной загрузки списка стран.
 */
const countriesLoadSuccess = createAction(
    CountriesActionType.LOAD_SUCCESS,
    props<CountriesActionProps>(),
);

/**
 * Событие неудачной загрузки списка стран.
 */
const countriesLoadFail = createAction(
    CountriesActionType.LOAD_FAIL,
    props<ApiResponse>(),
);

/**
 * Тип, объединяющий все события, связанные со странами.
 */
export type CountriesAction =
    | ReturnType<typeof countriesLoad>
    | ReturnType<typeof countriesLoadSuccess>
    | ReturnType<typeof countriesLoadFail>;

/**
 * Все события, связанные со странами.
 */
export const countriesActions = {
    countriesLoad: countriesLoad,
    countriesLoadSuccess: countriesLoadSuccess,
    countriesLoadFail: countriesLoadFail,
};
