<div class="operator-shortcuts-help-dialog">
    <h1 mat-dialog-title>{{ "dialogs.operator-shortcuts-help-dialog.header" | translate }}</h1>
    <mat-dialog-content>
        <div class="operator-shortcuts-help-dialog__operator-shortcut-info">
            <b>A</b> – {{ "dialogs.operator-shortcuts-help-dialog.text.aKey" | translate }}
        </div>
        <div class="operator-shortcuts-help-dialog__operator-shortcut-info">
            <b>{{ "dialogs.operator-shortcuts-help-dialog.key.space" | translate }}</b> – {{ "dialogs.operator-shortcuts-help-dialog.text.spaceKey" | translate }}
        </div>
        <div class="operator-shortcuts-help-dialog__operator-shortcut-info">
            <b>{{ "dialogs.operator-shortcuts-help-dialog.key.space" | translate }} + Shift</b> – {{ "dialogs.operator-shortcuts-help-dialog.text.spaceKeyWithShift" | translate }}
        </div>
        <div class="operator-shortcuts-help-dialog__operator-shortcut-info">
            <b>D</b> – {{ "dialogs.operator-shortcuts-help-dialog.text.dKey" | translate }}
        </div>
        <div class="operator-shortcuts-help-dialog__operator-shortcut-info">
            <b>R</b> – {{ "dialogs.operator-shortcuts-help-dialog.text.rKey" | translate }}
        </div>
        <div class="operator-shortcuts-help-dialog__operator-shortcut-info">
            <b>W</b> – {{ "dialogs.operator-shortcuts-help-dialog.text.wKey" | translate }}
        </div>
        <div class="operator-shortcuts-help-dialog__operator-shortcut-info">
            <b>Q</b> – {{ "dialogs.operator-shortcuts-help-dialog.text.qKey" | translate }}
        </div>
        <div class="operator-shortcuts-help-dialog__operator-shortcut-info">
            <b>Ctrl + E</b> – {{ "dialogs.operator-shortcuts-help-dialog.text.eKeyWithCtrl" | translate }}
        </div>
        <div>
            <b>Ctrl + Shift + E</b> – {{ "dialogs.operator-shortcuts-help-dialog.text.eKeyWithCtrlAndShift" | translate }}
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div>
            <div class="operator-shortcuts-help-dialog__buttons">
                <button
                    mat-raised-button
                    mat-dialog-close
                >
                    {{ 'dialogs.close' | translate }}
                </button>
            </div>
        </div>
    </mat-dialog-actions>
</div>
<button
    class="mat-dialog-cross-close-btn"
    mat-icon-button
    mat-dialog-close
>
    <mat-icon>close</mat-icon>
</button>
