import { HttpErrorResponse } from "@angular/common/http";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { map } from "rxjs/operators";
import { ApiResponse } from "src/app/common/models/api-response.model";
import { CountriesResponse } from "src/app/common/models/api-responses/countries-response";
import { Country } from "src/app/common/models/country.model";

/**
 * Сервис для работы со списком стран.
 */
@Injectable({
    providedIn: "root"
})
export class CountriesService {
    //region Constants

    /**
     * Основные страны.
     */
    private static readonly PREFERRED_COUNTRIES: string[] =  ["SAU", "ARE", "IND"];

    //endregion
    //region Fields

    /**
     * HTTP-клиент.
     */
    private readonly _httpClient: HttpClient;

    //endregion
    //region Ctor

    /**
     * Конструктор сервиса для работы со списком стран.
     *
     * @param httpClient HTTP клиент.
     */
    constructor(httpClient: HttpClient) {

        this._httpClient = httpClient;
    }

    //endregion
    //region Public

    /**
     * Загрузка списка стран.
     *
     * @return Страны.
     */
    public get(): Observable<Country[]> {

        return this._httpClient.get("/api/v1/processing/countries")
            .pipe(
                map((response: CountriesResponse) => this._sortPopularCountriesInFront(response.countries)),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse))
            );
    }

    //endregion
    //region Private

    /**
     * Передвигает вверх списка основные страны - (SAU, ARE, IND).
     *
     * @param countries Список стран.
     *
     * @return Список стран.
     */
    private _sortPopularCountriesInFront(countries: Country[]): Country[] {

        CountriesService.PREFERRED_COUNTRIES.forEach((alfa3Code: string) => {
            const findIndex: number = countries.indexOf(
                countries.find((country: Country) => country.alpha3Code === alfa3Code)
            );
            countries.unshift(countries.splice(findIndex, 1)[0]);
        });

        return countries;
    }

    //endregion
}
