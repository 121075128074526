import { Injectable } from "@angular/core";
import { Effect } from "@ngrx/effects";
import { ofType } from "@ngrx/effects";
import { Actions } from "@ngrx/effects";
import { select } from "@ngrx/store";
import { Store } from "@ngrx/store";
import { Action } from "@ngrx/store";
import { withLatestFrom } from "rxjs/operators";
import { map } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { WebsocketEventType } from "src/app/common/models/websocket/websocket-event-type";
import { OperatorWebsocketService } from "src/app/common/services/operator-websocket.service";
import { WebsocketUtils } from "src/app/common/utils/websocket.utils";
import { OperatorAcquireOldAction } from "src/app/operator/store/actions/operator.action";
import { OperatorGotBlockingDocumentAction } from "src/app/operator/store/actions/operator.action";
import { OperatorAcquireNewSuccessAction } from "src/app/operator/store/actions/operator.action";
import { OperatorState } from "src/app/operator/store/reducers/operator.state";
import { operatorStateSelectors } from "src/app/operator/store/selectors/operator.selectors";
import { ProcessDocumentProps } from "src/app/root/store/actions/props/queued-document.action-props";
import { WebsocketEventTypeProps } from "src/app/root/store/actions/props/websocket-event-type.action-props";
import { websocketActions } from "src/app/root/store/actions/websocket.actions";
import { WebsocketActionType } from "src/app/root/store/actions/websocket.actions";
import { RootState } from "src/app/root/store/states/root.state";
import { environment } from "src/environments/environment";

/**
 * Side-эффекты на события, связанные с веб сокетом.
 */
@Injectable()
export class WebsocketEffects {
    //region Fields

    /**
     * Сервис веб сокета сервера оператора.
     */
    private readonly _websocketService: OperatorWebsocketService;

    //endregion
    //region Ctor

    /**
     * Конструктор класса, содержащего в себе side-эффекты на события, связанные с веб сокетом.
     *
     * @param _actions$ Поток событий, происходящих в системе.
     * @param _store$ Хранилище текущего состояния приложения.
     * @param websocketService Сервис веб сокета сервера оператора.
     */
    constructor(
        private _actions$: Actions,
        private _store$: Store<RootState>,
        websocketService: OperatorWebsocketService
    ) {
        this._websocketService = websocketService;
    }

    //endregion
    //region Effects

    /**
     * Обработка события требования соединения по веб сокету.
     */
    @Effect({ dispatch: false })
    connect$ = this._actions$
        .pipe(
            ofType(WebsocketActionType.CONNECT),
            tap((_: any) => this._websocketService.connect()),
        );

    /**
     * Обработка события подписки на событие, передаваемое по веб сокету.
     */
    @Effect({ dispatch: false })
    subscribe$ = this._actions$
        .pipe(
            ofType(WebsocketActionType.SUBSCRIBE),
            tap((eventTypeProps: WebsocketEventTypeProps) =>
                this._websocketService.subscribe(eventTypeProps.eventType)
            ),
        );

    /**
     * Обработка события подписки на событие, передаваемое по веб сокету.
     */
    @Effect({ dispatch: false })
    unsubscribe$ = this._actions$
        .pipe(
            ofType(WebsocketActionType.UNSUBSCRIBE),
            tap((eventTypeProps: WebsocketEventTypeProps) =>
                this._websocketService.unsubscribe(eventTypeProps.eventType)
            ),
        );

    /**
     * Обработка события требования обработки документа, пришедшего по веб сокету.
     */
    @Effect()
    getDocumentToProcessSuccess$ = this._actions$
        .pipe(
            ofType(WebsocketUtils.getEventActionType(WebsocketEventType.PROCESS_DOCUMENT)),
            withLatestFrom(this._store$.pipe(select(operatorStateSelectors.state))),
            map(([action, state]: [Action & ProcessDocumentProps, OperatorState]) => {
                if (action.blocking) {

                    return new OperatorGotBlockingDocumentAction(action.document);
                }
                if (
                    action.document
                    && state.lastSentDocumentAction
                    && action.document.id === state.lastSentDocumentAction.document.id
                ) {

                    if (state.failedSendingAttempts < environment.failedSentDocumentRetryAttempts) {

                        return new OperatorAcquireOldAction();
                    }
                    else {

                        this._websocketService.close();
                        return websocketActions.unsubscribe({eventType: WebsocketEventType.PROCESS_DOCUMENT});
                    }
                }
                else {

                    return new OperatorAcquireNewSuccessAction(action.document);
                }
            }),
        );

    //endregion
}
