import { Action } from "@ngrx/store";
import { ApiResponsePayloadAction } from "rootStore";
import { Company } from "../../../../common/models";
import { ApiResponse } from "../../../../common/models";


/**
 * Типы событий, связанные с редактированием компании поставщика.
 */
export enum SupplierEditActionType {
    EDIT = "[Operator] Edit supplier company",
    EDIT_SUCCESS = "[Operator] Supplier company update success",
    EDIT_FAIL = "[Operator] Supplier company update fail",
}

/**
 * Событие запроса редактирования компании поставщика.
 */
export class SupplierEditRequestAction implements Action {
    readonly type = SupplierEditActionType.EDIT;
    constructor(public payload: Company) { }
}

/**
 * Событие удачного редактирования компании поставщика.
 */
export class SupplierEditSuccessAction implements Action {
    readonly type = SupplierEditActionType.EDIT_SUCCESS;
    constructor(public payload: Company) { }
}

/**
 * Событие неудачного редактирования компании поставщика.
 */
export class SupplierEditFailAction implements ApiResponsePayloadAction {
    readonly type = SupplierEditActionType.EDIT_FAIL;
    constructor(public payload: ApiResponse) { }
}

/**
 * Тип, объединяющий все события.
 */
export type SupplierEditAction =
    | SupplierEditRequestAction
    | SupplierEditFailAction
    | SupplierEditSuccessAction
;