import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
import * as i2 from "@ngx-translate/core";
/**
 * Сервис для выставления заголовка страницы в браузере.
 */
var TitleService = /** @class */ (function () {
    //endregion
    //region Ctor
    function TitleService(_title, _translateService) {
        this._title = _title;
        this._translateService = _translateService;
        //region Private fields
        /**
         * Подписка на изменение текста i18n-ключа.
         */
        this._titleSubscription = null;
    }
    //endregion
    //region Public
    /**
     * Выполняет подписку на i18n-ключ текста заголовка страницы и меняет заголовок.
     *
     * @param titleData Данные для заголовка страницы браузера.
     */
    TitleService.prototype.setTitle = function (titleData) {
        var _this = this;
        if (this._titleSubscription !== null) {
            this._titleSubscription.unsubscribe();
        }
        this._titleSubscription = this._translateService
            .get(titleData.titleKey)
            .subscribe(function (title) {
            if (!titleData.withoutPrefix) {
                title = 'Obrabot4ik - ' + title;
            }
            _this._title.setTitle(title);
        });
    };
    /**
     * Сбрасывает заголовок страницы на значение по умолчанию.
     */
    TitleService.prototype.clearTitle = function () {
        if (this._titleSubscription !== null) {
            this._titleSubscription.unsubscribe();
            this._titleSubscription = null;
        }
        this._title.setTitle('Obrabot4ik');
    };
    TitleService.ngInjectableDef = i0.defineInjectable({ factory: function TitleService_Factory() { return new TitleService(i0.inject(i1.Title), i0.inject(i2.TranslateService)); }, token: TitleService, providedIn: "root" });
    return TitleService;
}());
export { TitleService };
