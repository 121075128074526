import { select } from "@ngrx/store";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { RouteService } from "src/app/root/services/route.service";
import { documentTypesSelectors } from "src/app/root/store/selectors/document-types.selector";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "../services/route.service";
/**
 * Route guard, который проверяет возможность входа на URL в зависимости от загрузки типов документов.
 *
 * Если типы документов есть, то переход по URL возможен, иначе невозможен.
 */
var DocumentTypesGuard = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор route guard, который проверяет возможность входа на URL в зависимости от загрузки типов документов.
     *
     * @param store Сервис для управления и доступа к состоянию приложения.
     * @param routeService Сервис для выполнения каких-либо переходов по URL'ам.
     */
    function DocumentTypesGuard(store, routeService) {
        this._store = store;
        this._routeService = routeService;
    }
    //endregion
    //region Public
    /**
     * Проверяет, можно ли осуществлять переход по текущему состоянию навигации.
     *
     * Если типы документов есть, то переход по URL возможен, иначе невозможен.
     *
     * @param route Состояние текущего активированного URL'а.
     * @param state Состояние навигации.
     *
     * @return Да/нет.
     */
    DocumentTypesGuard.prototype.canActivate = function (route, state) {
        return this._store
            .pipe(select(documentTypesSelectors.state), filter(function (documentTypesState) {
            return !!documentTypesState.error || !!documentTypesState.documentTypes.length;
        }), switchMap(function (documentTypesState) {
            if (documentTypesState.error) {
                return of(false);
            }
            else {
                return of(true);
            }
        }), catchError(function () {
            return of(false);
        }));
    };
    /**
     * Проверяет, можно ли осуществлять переход по текущему состоянию навигации.
     *
     * Если типы документов, то переход по URL возможен, иначе невозможен.
     *
     * @param route Состояние текущего активированного URL'а.
     * @param state Состояние навигации.
     *
     * @return Да/нет.
     */
    DocumentTypesGuard.prototype.canActivateChild = function (route, state) {
        return this.canActivate(route, state);
    };
    DocumentTypesGuard.ngInjectableDef = i0.defineInjectable({ factory: function DocumentTypesGuard_Factory() { return new DocumentTypesGuard(i0.inject(i1.Store), i0.inject(i2.RouteService)); }, token: DocumentTypesGuard, providedIn: "root" });
    return DocumentTypesGuard;
}());
export { DocumentTypesGuard };
