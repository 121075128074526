import { SpaceCounterpartiesState } from '../states/space-counterparties.state';
import { SpaceCounterpartiesAction } from '../actions/space-counterparties.action';
import { SpaceCounterpartiesActionType } from '../actions/space-counterparties.action';

/**
 * Начальное состояние списка уникальных контрагентов, привязанных к документам в заданном пространстве документов.
 */
export const initialState: SpaceCounterpartiesState = {
    spaceCustomers: undefined,
    spaceCustomersLoading: false,
    spaceCustomersError: null,

    spaceSuppliers: undefined,
    spaceSuppliersLoading: false,
    spaceSuppliersError: null,
};

/**
 * Логика обработчика событий, связанных со списком уникальных контрагентов, привязанных к документам в заданном
 * пространстве документов.
 */
export function spaceCounterpartiesReducer(
    state = initialState,
    action: SpaceCounterpartiesAction,
): SpaceCounterpartiesState {

    let result: SpaceCounterpartiesState = state;

    switch (action.type) {

        case SpaceCounterpartiesActionType.SPACE_CUSTOMERS_LOAD: {

            result = {
                ...state,
                spaceCustomersLoading: true,
                spaceCustomersError: null,
                spaceCustomers: undefined,
            };
            break;
        }

        case SpaceCounterpartiesActionType.SPACE_CUSTOMERS_LOADED: {

            result = {
                ...state,
                spaceCustomersLoading: false,
                spaceCustomers: action.spaceCustomers,
            };
            break;
        }

        case SpaceCounterpartiesActionType.SPACE_CUSTOMERS_FAILED: {

            result = {
                ...state,
                spaceCustomersLoading: false,
                spaceCustomersError: action.error,
            };
            break;
        }

        case SpaceCounterpartiesActionType.SPACE_SUPPLIERS_LOAD: {

            result = {
                ...state,
                spaceSuppliersLoading: true,
                spaceSuppliersError: null,
                spaceSuppliers: undefined,
            };
            break;
        }

        case SpaceCounterpartiesActionType.SPACE_SUPPLIERS_LOADED: {

            result = {
                ...state,
                spaceSuppliersLoading: false,
                spaceSuppliers: action.spaceSuppliers,
            };
            break;
        }

        case SpaceCounterpartiesActionType.SPACE_SUPPLIERS_FAILED: {

            result = {
                ...state,
                spaceSuppliersLoading: false,
                spaceSuppliersError: action.error,
            };
            break;
        }
    }

    return result;
}
