/**
 * Типы событий, связанные с создание компании поставщика.
 */
import { Action } from "@ngrx/store";
import { ApiResponsePayloadAction } from "rootStore";
import { Company } from "../../../../common/models";
import { ApiResponse } from "../../../../common/models";

export enum SupplierCreationActionType {
    CREATE = '[Operator] Create new supplier company',
    CREATE_SUCCESS = '[Operator] Supplier company created success',
    CREATE_FAIL = '[Operator] Supplier company created fail',
}

/**
 * Событие запроса создания компании.
 */
export class SupplierCreationRequestAction implements Action {
    readonly type = SupplierCreationActionType.CREATE;
    constructor(public payload: Company) { }
}

/**
 * Событие удачного создания компании.
 */
export class SupplierCreationSuccessAction implements Action {
    readonly type = SupplierCreationActionType.CREATE_SUCCESS;
    constructor(public payload: Company) { }
}

/**
 * Событие неудачного создания компании.
 */
export class SupplierCreationFailAction implements ApiResponsePayloadAction {
    readonly type = SupplierCreationActionType.CREATE_FAIL;
    constructor(public payload: ApiResponse) { }
}

/**
 * Тип, объединяющий все события.
 */
export type SupplierCreationAction =
    | SupplierCreationRequestAction
    | SupplierCreationFailAction
    | SupplierCreationSuccessAction