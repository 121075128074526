import { Injectable } from "@angular/core";
import { Actions } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { ofType } from "@ngrx/effects";
import { map } from "rxjs/operators";
import { ApiResponse } from "src/app/common/models/api-response.model";
import { UrlUtils } from "src/app/common/utils/url.utils";
import { serverSideErrors } from "src/app/root/store/actions";
import { ApiResponsePayloadAction } from "src/app/root/store/actions/api-response-payload-action.model";
import { RouterGoAction } from "src/app/root/store/actions/router.action";
import { ServerSideErrorActionType } from "src/app/root/store/actions/server-side-error.action";
import { ServerSideErrorOccurredAction } from "src/app/root/store/actions/server-side-error.action";

/**
 * Side-эффекты, связанные с ошибками, произошедшими на сервере, которые ведут на отдельную страницу об ошибке.
 *
 * Эти ошибки явно показывают, что продолжать работу невозможно, при этом пользователь ещё ничего не сделал,
 * и показывать их через всплывашку не имеет смысла.
 */
@Injectable()
export class ServerSideErrorsEffects {
    //region Ctor

    /**
     * Конструктор эффектов, связанных с ошибками, произошедшими на сервере, которые ведут на отдельную страницу об
     * ошибке.
     *
     * @param _actions$ Поток событий, происходящих в системе.
     */
    constructor(
        private _actions$: Actions,
    ) { }

    //endregion
    //region Public

    /**
     * Обработка всех событий в модуле, сигнализирующих ошибку на сервере, и превращение их в единое событие о
     * произошедшей ошибке на сервере, которое вызовет обновление данных об ошибке в состоянии приложения и
     * приведёт к переходу на странцу об ошибке.
     */
    @Effect()
    serverSideErrors$ = this._actions$
        .pipe(
            ofType(... serverSideErrors),
            map((action: ApiResponsePayloadAction): ApiResponse => action.payload),
            map((response: ApiResponse) => new ServerSideErrorOccurredAction(response))
        );

    /**
     * Обработка события, что произошла ошибка на сервере. Переход на страницу об ошибке.
     */
    @Effect()
    goToErrorPage$ = this._actions$
        .pipe(
            ofType(ServerSideErrorActionType.OCCURRED),
            // TODO Хардкод URL
            map(() => new RouterGoAction({ path: [ UrlUtils.somethingGoesWrongPagePath() ]}))
        );

    //endregion
}
