import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { CanActivate } from "@angular/router";
import { CanActivateChild } from "@angular/router";
import { RouterStateSnapshot } from "@angular/router";
import { UrlTree } from "@angular/router";
import { Store } from "@ngrx/store";
import { select } from "@ngrx/store";
import { currentUserStateSelector } from "rootStore";

import { Observable } from "rxjs";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { map } from "rxjs/operators";
import { switchMap } from "rxjs/operators";

import { RootState } from "src/app/root/store/states/root.state";
import { CurrentUserInfo } from "../../common/models";
import { RouteService } from "../services";
import { CurrentUserState } from "../store/reducers/current-user.reducer";

/**
 * Route guard, который проверяет возможность входа на URL в зависимости от наличия аутентифицированного пользователя.
 *
 * Если текущий пользователь есть, то переход по URL возможен, если текущего пользователя нет, то невозможен.
 */
@Injectable({
    providedIn: "root"
})
export class UserGuard implements CanActivate, CanActivateChild {
    //region Fields

    /**
     * Сервис для управления и доступа к состоянию приложения.
     */
    private readonly _store: Store<RootState>;

    /**
     * Сервис для выполнения каких-либо переходов по URL'ам.
     */
    private readonly _routeService: RouteService;

    //endregion
    //region Ctor

    /**
     * Конструктор route guard, который проверяет возможность входа на URL в зависимости от наличия
     * аутентифицированного пользователя.
     *
     * @param store Сервис для управления и доступа к состоянию приложения.
     * @param routeService Сервис для выполнения каких-либо переходов по URL'ам.
     */
    constructor(store: Store<RootState>, routeService: RouteService) {

        this._store = store;
        this._routeService = routeService;
    }

    //endregion
    //region Public

    /**
     * Проверяет, можно ли осуществлять переход по текущему состоянию навигации и, если нельзя, то перенаправляет на
     * страницу входа в систему.
     *
     * Если текущий пользователь есть, то переход по URL возможен, если текущего пользователя нет, то невозможен.
     *
     * @param route Состояние текущего активированного URL'а.
     * @param state Состояние навигации.
     *
     * @return Да, если можно осуществить переход по текущему состоянию навигации и, если нельзя, то перенаправялет на
     * страницу входа в систему.
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {

        return this._getCurrentUserState()
            .pipe(
                map((userState: CurrentUserState): CurrentUserInfo => userState.currentUserInfo),
                switchMap((user: CurrentUserInfo): Observable<boolean | UrlTree> => {

                    if (user) {

                        return of(true);
                    }
                    else {

                        return of(this._routeService.goToLogin(state.url));
                    }
                }),
                catchError((): Observable<UrlTree> => of(this._routeService.goToLogin(state.url))),
            );
    }

    /**
     * Проверяет, можно ли осуществлять переход по текущему состоянию навигации и, если нельзя, то перенаправляет на
     * страницу входа в систему.
     *
     * Если текущий пользователь есть, то переход по URL возможен, если текущего пользователя нет, то невозможен.
     *
     * @param route Состояние текущего активированного URL'а.
     * @param state Состояние навигации.
     *
     * @return Да, если можно осуществить переход по текущему состоянию навигации и, если нельзя, то перенаправялет на
     * страницу входа в систему.
     */
    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {

        return this.canActivate(route, state);
    }

    //endregion
    //region Private

    /**
     * Возвращает состояние данных текущего аутентифицированного пользователя.
     *
     * Дожидается, когда данные текущего аутентифицированного пользователя будут загружены.
     *
     * @return Состояние данных текущего аутентифицированного пользователя
     */
    private _getCurrentUserState(): Observable<CurrentUserState> {

        return this._store
            .pipe(
                select(currentUserStateSelector),
                filter((userState: CurrentUserState): boolean => userState.loaded || userState.failed),
            );
    }

    //endregion
}
