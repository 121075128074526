/**
 * Глобальные константы.
 */
export class Constants {
    //region Constants

    /**
     * Имя css-класса для всех диалогов.
     */
    public static readonly DIALOG_PANEL_CLASS = "dlg-panel";

    //endregion
}
