import { CurrentUserAction } from "../actions";
import { CurrentUserActionType } from "../actions";
import { LoginAction } from "../actions";
import { LoginActionType } from "../actions";
import { LoginState } from "../states";
import { CurrentUserInfo } from "../../../common/models";

/**
 * Начальное состояние входа в систему.
 */
export const initialState: LoginState = {
    pending: false,
    loggedIn: null,
    expired: false,
    error: null,
};

/**
 * Логика обработчика событий, связанных со входом в систему.
 *
 * @param state Начальное состояние входа в систему.
 * @param action Событие, связанное со входом в систему и выходом из неё.
 */
export function loginReducer(state = initialState, action: LoginAction | CurrentUserAction): LoginState {

    switch (action.type) {
        //region На событие, требующее выполнения входа в систему

        case LoginActionType.SIGN_IN: {

            return {
                ...state,
                pending: true,
                error: null,
            };
        }

        //endregion
        //region На событие успешного входа в систему

        case LoginActionType.SIGN_IN_SUCCEEDED: {

            return {
                ...state,
                loggedIn: true,
                pending: false,
            };
        }

        //endregion
        //region На событие при ошибке во время входа в систему

        case LoginActionType.SIGN_IN_FAILED: {

            return {
                ...state,
                pending: false,
                error: action.response,
            };
        }

        //endregion
        //region На событие выхода из системы

        case LoginActionType.SIGN_OUT: {

            return {
                ...state,
                expired: action.props.expired,
            };
        }

        //endregion
        //region На событие успешного выхода из системы

        case LoginActionType.SIGN_OUT_SUCCEEDED: {

            return {
                ...state,
                loggedIn: false,
            };
        }

        //endregion
        //region На событие успешной загрузки текущего пользователя

        case CurrentUserActionType.LOAD_SUCCESS: {

            const user: CurrentUserInfo = action.payload;
            if (state.loggedIn !== !!user) {

                state = {
                    ...state,
                    loggedIn: !!user,
                };
            }

            return state;
        }

        //endregion
    }

    return state;
}
