import { HttpErrorResponse } from "@angular/common/http";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { CurrencyListResponse } from "src/app/common/models/api-responses/currency-list-response";
import { Currency } from "src/app/common/models/currency.model";
import { ApiResponse } from "src/app/common/models/index";

/**
 * Сервис для работы со списком валют.
 */
@Injectable({
    providedIn: "root"
})
export class CurrencyService {
    // region Fields

    /**
     * HTTP-клиент.
     */
    private readonly _httpClient: HttpClient;

    /**
     * Основные валюты.
     */
    private readonly popularCurrencies: string[] =  ["GBP", "INR", "AED", "EUR", "USD"];

    //endregion
    //region Ctor

    /**
     * Конструктор класса для работы со списком валют.
     *
     * @param httpClient HTTP клиент.
     */
    constructor(httpClient: HttpClient) {

        this._httpClient = httpClient;
    }

    //endregion
    //region Public

    /**
     * Загрузка списка валют.
     *
     * @return Валюты.
     */
    public getAll(): Observable<Currency[]> {

        return this._httpClient.get("/api/v1/processing/documentCurrencies")
            .pipe(
                map((response: CurrencyListResponse) =>
                    this.movePopularCurrencyInFront(response.currencies)
                ),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse)),
            );
    }

    //endregion
    //region private

    /**
     * Передвигает вверх списка основные валюты - (USD, EUR, GBP, AED, INR).
     *
     * @param currencies Список валют.
     *
     * @return Список волют.
     */
    private movePopularCurrencyInFront(currencies: Currency[]): Currency[] {

        this.popularCurrencies.forEach((alfa3Code: string) => {
            const index: number = currencies.indexOf(
                currencies.find((currency: Currency) => currency.alpha3Code === alfa3Code)
            );
            currencies.unshift(currencies.splice(index, 1)[0]);
        });

        return currencies;
    }

    //endregion
}
