var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpHeaders } from "@angular/common/http";
import { HttpClient } from "@angular/common/http";
import { of } from "rxjs";
import { tap } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { map } from "rxjs/operators";
import { shareReplay } from "rxjs/operators";
import { ObjectUtils } from "src/app/common/utils/object.utils";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
/**
 * Сервис по работе с конфигурациями окружения приложения.
 */
var ConfigurationService = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор сервиса по работе с конфигурациями окружения приложения.
     *
     * @param http HTTP-клиент.
     */
    function ConfigurationService(http) {
        this._http = http;
    }
    //endregion
    //region Public
    /**
     * Загружает конфигурацию окружения приложения.
     *
     * @return Конфигурация окружения приложения.
     */
    ConfigurationService.prototype.loadConfigurations = function () {
        if (!this.configuration$) {
            var headers = new HttpHeaders({ "Cache-Control": "no-cache" });
            this.configuration$ = this._http.get(ConfigurationService.CONFIG_URL, { headers: headers })
                .pipe(catchError(function (error) {
                if (!environment.production) {
                    console.log("Can't get configuration", error);
                }
                return of({});
            }), map(function (config) { return (__assign({}, environment, config)); }), map(function (conf) { return ObjectUtils.freezeInDeep(conf); }), tap(function (frozenConfig) {
                Object.assign(environment, frozenConfig);
                ObjectUtils.freezeInDeep(environment, false);
            }), shareReplay(1));
        }
        return this.configuration$;
    };
    //region Constants
    /**
     * URL конфигурации окружения приложения.
     */
    ConfigurationService.CONFIG_URL = "assets/config/config.json";
    ConfigurationService.ngInjectableDef = i0.defineInjectable({ factory: function ConfigurationService_Factory() { return new ConfigurationService(i0.inject(i1.HttpClient)); }, token: ConfigurationService, providedIn: "root" });
    return ConfigurationService;
}());
export { ConfigurationService };
