import { Injectable } from "@angular/core";
import { ofType } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { Actions } from "@ngrx/effects";
import { of } from "rxjs";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { map } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { Currency } from "src/app/common/models/currency.model";
import { ApiResponse } from "src/app/common/models/index";
import { CurrencyService } from "src/app/root/services/currency.service";
import { currenciesActions } from "src/app/root/store/actions/currencies.action";
import { CurrenciesAction } from "src/app/root/store/actions/currencies.action";
import { CurrentUserActionType } from "src/app/root/store/actions/index";

/**
 * Side-эффекты на события, связанные с валютами.
 */
@Injectable()
export class CurrenciesEffects {
    // region Fields

    /**
     * Сервис для работы со списком валют.
     */
    private readonly _currenciesService: CurrencyService;

    //endregion
    //region Ctor

    /**
     * Конструктор эффектов на события связанные со списков валют.
     *
     * @param _actions$ События.
     * @param currenciesService Сервис для работы со списком валют.
     */
    constructor(
        private _actions$: Actions,
        currenciesService: CurrencyService,
    ) {
        this._currenciesService = currenciesService;
    }

    //endregion
    //region Public

    /**
     * Выполнение загрузки списка валют.
     *
     */
    @Effect()
    loadCurrencies$ = this._actions$.pipe(
            ofType(CurrentUserActionType.LOAD),
            switchMap(() => this._currenciesService.getAll()
                .pipe(
                    map((currencies: Currency[]): CurrenciesAction =>
                        currenciesActions.currenciesLoadSuccess({currencies})
                    ),
                    catchError((apiResponse: ApiResponse): Observable<CurrenciesAction> =>
                        of(currenciesActions.currenciesLoadFail(apiResponse))
                    ),
                ),
            )
        );

    //endregion
}
