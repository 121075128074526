/**
 * Типы события подписки веб сокета.
 */
export enum WebsocketEventType {
    //region Enum values

    /**
     * Событие обработки документа.
     */
    PROCESS_DOCUMENT = "PROCESS_DOCUMENT",

    /**
     * Событие поддержания соединения по веб сокету.
     */
    KEEP_ALIVE = "KEEP_ALIVE",

    /**
     * Событие ошибки по веб сокету.
     */
    ERROR = "ERROR",

    //endregion
}