import { HostListener } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material";

/**
 * Умная компонента диалога, который отображает подсказки горячих клавиш интерфейса оператора.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "operator-shortcuts-help-dialog",
    styleUrls: ["operator-shortcuts-help-dialog.component.scss"],
    templateUrl: "operator-shortcuts-help-dialog.component.html",
})
export class OperatorShortcutsHelpDialogComponent {
    //region Fields

    /**
     * Компонент диалога.
     */
    private readonly _dialogRef: MatDialogRef<OperatorShortcutsHelpDialogComponent>;

    //endregion
    //region Ctor

    /**
     * Конструктор компонента диалога, который отображает подсказки горячих клавиш интерфейса оператора.
     *
     * @param dialogRef Компонент диалога.
     */
    constructor(dialogRef: MatDialogRef<OperatorShortcutsHelpDialogComponent>) {

        this._dialogRef = dialogRef;
    }

    //endregion
    //region Events

    /**
     * Обработчик нажатия на Esc - закрытие диалога.
     */
    @HostListener("window:keyup.esc")
    onKeyUp() {

        this._dialogRef.close();
    }

    //endregion
}
