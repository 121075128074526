import { RouterReducerState } from "@ngrx/router-store";
import { routerReducer } from "@ngrx/router-store";
import { ActionReducerMap } from "@ngrx/store";
import { createFeatureSelector } from "@ngrx/store";
import { createSelector } from "@ngrx/store";
import { complaintOnDocumentDlgReducer } from "src/app/root/store/reducers/complaint-on-document-dlg/complaint-on-document-dlg.reducer";
import { ComplaintOnDocumentDlgState } from "src/app/root/store/reducers/complaint-on-document-dlg/complaint-on-document-dlg.state";
import { currenciesReducer } from "src/app/root/store/reducers/currecies.reducers";
import { countriesReducer } from "src/app/root/store/reducers/countries.reducers";
import { DocumentTypesState } from "src/app/root/store/reducers/document-types.reducers";
import { documentTypesReducer } from "src/app/root/store/reducers/document-types.reducers";
import { websocketReducer } from "src/app/root/store/reducers/websocket.reducer";
import { CurrenciesState } from "src/app/root/store/states/currencies.state";
import { CountriesState } from "src/app/root/store/states/countries.state";
import { RootState } from "src/app/root/store/states/root.state";
import { WebsocketState } from "src/app/root/store/states/websocket.state";
import { LoginState } from "../states";
import { StatePair } from "../states";
import { CurrentUserState } from "./current-user.reducer";
import { currentUserReducer } from "./current-user.reducer";
import { loginReducer } from "./login.reducer";
import { previousUrlReducer, PreviousUrlState } from "./previous-url.reducer";
import { RouteState } from "./router.reducer";
import { ServerSideErrorState } from "./server-side-error.reducer";
import { serverSideErrorReducer } from "./server-side-error.reducer";

/**
 * Обработчики частей состояния приложения.
 */
export const reducers: ActionReducerMap<RootState> = {

    /**
     * Обработчик состояния по данным текущего пользователя.
     */
    currentUser: currentUserReducer,

    /**
     * Обработчик состояния URL'а.
     */
    routerReducer: routerReducer,

    /**
     * Обработчик состояния данных об ошибке на сервере.
     */
    serverSideError: serverSideErrorReducer,

    /**
     * Обработчик состоняние предыдущего URL внутри приложения.
     */
    previousUrl: previousUrlReducer,

    /**
     * Обработчик событий, связанных со входом в систему.
     */
    login: loginReducer,

    /**
     * Обработчик состояния запроса жалобы на документ оператором из диалога.
     */
    complaintOnDocumentDlgState: complaintOnDocumentDlgReducer,

    /**
     * Обработчик состояния веб-сокета для операторов.
     */
    websocket: websocketReducer,

    /**
     * Состояние типов документов.
     */
    documentTypesState: documentTypesReducer,

    /**
     * Состояние типов документов.
     */
    countriesState: countriesReducer,

    /**
     * Состояние списка валют.
     */
    currenciesState: currenciesReducer,
};

/**
 * Селектор состояния данных текущего пользователя.
 */
export const currentUserStateSelector = createFeatureSelector<CurrentUserState>("currentUser");

/**
 * Селектор состояния URL'а.
 */
export const routerReducerStateSelector = createFeatureSelector<RouterReducerState<RouteState>>("routerReducer");

/**
 * Селектор состояния данных об ошибке на сервере.
 */
export const serverSideErrorStateSelector = createFeatureSelector<ServerSideErrorState>("serverSideError");

/**
 *  Селектор состояния рпедыдущего URL.
 */
export const previousUrlStateSelector = createFeatureSelector<PreviousUrlState>("previousUrl");

/**
 * Селектор состояния входа в систему.
 */
export const loginStateSelector = createFeatureSelector<LoginState>("login");

/**
 * Селектор состояния диалога жалобы на документ.
 */
export const complaintOnDocumentDlgStateSelector = createFeatureSelector<ComplaintOnDocumentDlgState>("complaintOnDocumentDlgState");

/**
 * Селектор состояния веб сокета.
 */
export const websocketStateSelector = createFeatureSelector<WebsocketState>("websocket");

/**
 * Селектор состояния типов документов.
 */
export const documentTypesStateSelector = createFeatureSelector<DocumentTypesState>("documentTypesState");

/**
 * Селектор состояния списка валют.
 */
export const currenciesStateSelector = createFeatureSelector<CurrenciesState>("currenciesState");

/**
 * Селектор состояния типов документов.
 */
export const countriesStateSelector = createFeatureSelector<CountriesState>("countriesState");
/**
 * Селектор состояния данных текущего аутентифицированного пользователя и состояния входа в систему.
 */
export const userAndLoginStatesSelector = createSelector(
    currentUserStateSelector,
    loginStateSelector,
    (first: CurrentUserState, second: LoginState): StatePair<CurrentUserState, LoginState> => ({ first, second }),
);

export { CustomSerializer } from "./router.reducer";
