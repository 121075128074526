import { OnInit } from "@angular/core";
import { Output } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { Input } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { Component } from "@angular/core";
import { ControlContainer } from "@angular/forms";
import { FormArrayName } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { AmountModifierType } from "src/app/common/models/amount-modifier-type";

/**
 * Компонента модификатора для итоговой суммы.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "amount-modifier",
    styleUrls: ["amount-modifier.component.scss"],
    templateUrl: "./amount-modifier.component.html",
    viewProviders: [
        { provide: ControlContainer, useExisting: FormArrayName },
    ],
})
export class AmountModifierComponent implements OnInit {
    //region Inputs

    /**
     * Индекс группы модификатора.
     */
    @Input()
    groupIndex: number;

    /**
     * Доступные типы модификатора.
     *
     * @param types Доступные типы модификатора.
     */
    @Input()
    modifierTypes: AmountModifierType[];

    //endregion
    //region Outputs

    /**
     * Исходящее событие изменения суммы для модификации.
     */
    @Output()
    changeAmount: EventEmitter<number> = new EventEmitter<number>();

    /**
     * Исходящее событие - требование удалить модификатор.
     */
    @Output()
    delete: EventEmitter<void> = new EventEmitter<void>();

    //endregion
    //region Fields

    /**
     * Группа модификатора.
     */
    modifierGroup: FormGroup;

    //endregion
    //region Ctor

    /**
     * Конструктор компоненты модификатора.
     *
     * @param _parent Родительский реактивный элемент.
     */
    public constructor(private _parent: FormArrayName) {}

    //endregion
    //region Hooks

    ngOnInit(): void {

        this.modifierGroup = this._parent.control.get(this.groupIndex.toString()) as FormGroup;
    }

    //endregion
    //region Events

    /**
     * Обработчик события изменения суммы для модификации.
     *
     * @param value Новое значение.
     */
    changeAmountHandler(value: number): void {

        this.changeAmount.emit(value);
    }

    /**
     * Обработчик события нажатия иконки удаления модификатора.
     */
    deleteHandler(): void {

        this.delete.emit();
    }

    //endregion
}
