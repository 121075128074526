import { Action } from "@ngrx/store";
import { ApiResponsePayloadAction } from "rootStore";
import { Company } from "../../../../common/models";
import { ApiResponse } from "../../../../common/models";


/**
 * Типы событий, связанные с редактированием компании покупателя.
 */
export enum CustomerEditActionType {
    EDIT = "[Operator] Edit customer company",
    EDIT_SUCCESS = "[Operator] Customer company update success",
    EDIT_FAIL = "[Operator] Customer company update fail",
}

/**
 * Событие запроса редактирования компании покупателя.
 */
export class CustomerEditRequestAction implements Action {
    readonly type = CustomerEditActionType.EDIT;
    constructor(public payload: Company) { }
}

/**
 * Событие удачного редактирования компании.
 */
export class CustomerEditSuccessAction implements Action {
    readonly type = CustomerEditActionType.EDIT_SUCCESS;
    constructor(public payload: Company) { }
}

/**
 * Событие неудачного редактирования компании.
 */
export class CustomerEditFailAction implements ApiResponsePayloadAction {
    readonly type = CustomerEditActionType.EDIT_FAIL;
    constructor(public payload: ApiResponse) { }
}

/**
 * Тип, объединяющий все события.
 */
export type CustomerEditAction =
    | CustomerEditRequestAction
    | CustomerEditFailAction
    | CustomerEditSuccessAction
;