import { Params } from "@angular/router";
import { environment } from "src/environments/environment";
import { RouterGoProps } from "../../root/store/actions/props";

/**
 * Утилиты для работы с URL.
 */
export class UrlUtils {
    //region Constants

    /**
     * Начало URL'ов API методов.
     */
    static readonly API_PREFIX = "/api";

    /**
     * Начало URL'ов API методов версии 1.
     */
    static readonly API_V1 = `${UrlUtils.API_PREFIX}/v1`;

    //endregion
    //region Public

    //region Commons

    /**
     * Парсит заданный URL и возвращает его в форме, пригодной для использования для события с требованием выполнить
     * переход на другую страницу.
     *
     * @param url URL для парсинга.
     *
     * @return URL в форме, пригодной для использования для события с требованием выполнить переход на другую
     * страницу.
     */
    static parse(url: string): RouterGoProps {

        let path: string[];
        let queryParams: Params;

        const questionMarkIndex = url.indexOf('?');
        if (questionMarkIndex === -1) {

            path = [ url ];
        }
        else {

            path = [ url.substring(0, questionMarkIndex) ];

            const queryString: string = url.substring(questionMarkIndex + 1);
            if (queryString) {

                queryParams = {};

                const keyValues: string[][] = queryString.split('&')
                    .map((keyValue: string): string[] => keyValue.split('='));

                for (const keyValue of keyValues) {

                    if (queryParams[keyValue[0]]) {

                        queryParams = {
                            ...queryParams,
                            [keyValue[0]]: [ ...queryParams[keyValue[0]], keyValue[1] ],
                        };
                    }
                    else {

                        queryParams = {
                            ...queryParams,
                            [keyValue[0]]: [ keyValue[1] ],
                        };
                    }
                }

                for (const key in queryParams) {

                    if (queryParams[key].length === 1) {

                        queryParams = {
                            ...queryParams,
                            [key]: queryParams[key][0],
                        };
                    }
                }
            }
        }

        return {
            path,
            queryParams,
        };
    }

    //endregion
    //region Auth

    //region API

    /**
     * Возвращает URL API метода версии 1 для выполнения входа в систему.
     *
     * @return URL API метода версии 1 для выполнения входа в систему.
     */
    static loginApiUrl(): string {

        return `${UrlUtils.API_V1}/${UrlUtils.loginPagePath()}`;
    }

    /**
     * Возвращает URL API метода версии 1 для выполнения выхода из системы.
     *
     * @return URL API метода версии 1 для выполнения выхода из систему.
     */
    static logoutApiUrl(): string {

        return UrlUtils.API_V1 + "/logout";
    }

    //endregion
    //region UI

    /**
     * Возвращает URL страницы рабочего пространства оператора в виде пригодном для использования в route guard'е.
     *
     * @return URL страницы рабочего пространства оператора в виде пригодном для использования в в route guard'е
     */
    static operatorPagePath(): string {

        return "operator";
    }

    /**
     * Возвращает URL страницы со входом в систему в виде пригодном для использования в routing модуле.
     *
     * @return URL страницы со входом в систему в виде пригодном для использования в routing модуле.
     */
    static loginPagePath(): string {

        return "login";
    }

    /**
     * Возвращает URL страницы входа в систему в виде массива пригодном для использования в качестве route'а в дириктиве
     * в шаблоне.
     *
     * @return URL страницы входа в систему в виде массива пригодном для использования в качестве route'а в дириктиве
     * в шаблоне.
     */
    static loginPageRoute(): string[] {

        return [UrlUtils.loginPagePath()];
    }

    /**
     * Возвращает URL страницы входа в систему c параметром для перенаправления после успешного входа в систему.
     *
     * @param redirectTo URL, куда нужно вернуть пользователя после выполнения входа в систему.
     *
     * @return URL страницы входа в систему c параметром для перенаправления после успешного входа в систему.
     */
    static loginPageUrl(redirectTo: string = null): string {

        let result = "/" + UrlUtils.loginPagePath();
        if (!!redirectTo) {

            redirectTo = encodeURIComponent(redirectTo);
            result = `${result}?redirectTo=${redirectTo}`;
        }

        return result;
    }

    //endregion

    //endregion
    //region WebSocket

    /**
     * Возвращает URL для доступа к веб сокету.
     */
    static webSocketUrl(): string {

        return `${environment.operatorServerUrl.replace("http", "ws")}${UrlUtils.API_PREFIX}/socket/operator`;
    }

    //endregion
    //region Something goes wrong

    //region UI

    /**
     * Возвращает URL страницы c информацией о том, что что-то пошло не так в виде пригодном для использования в
     * routing модуле.
     *
     * @return URL страницы c информацией о том, что что-то пошло не так в виде пригодном для использования в
     * routing модуле.
     */
    static somethingGoesWrongPagePath(): string {

        return "something-goes-wrong";
    }

    //endregion

    //endregion
    //region Version

    //region UI

    /**
     * Возвращает URL страницы с информацией о текущей версии системы в виде пригодном для использования в
     * routing модуле.
     *
     * @return URL страницы с информацией о текущей версии системы в виде пригодном для использования в
     * routing модуле.
     */
    static versionPagePath(): string {

        return "version";
    }

    //endregion

    //endregion

    //endregion
}
