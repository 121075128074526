var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { WebsocketActionType } from "src/app/root/store/actions/websocket.actions";
/**
 * Начальное состояние состояния веб сокета.
 */
export var initialState = { connecting: true, connected: false, activeSubscriptions: [] };
/**
 * Обработчик событий, связанных с веб сокетом.
 *
 * @param state Состояние веб сокета.
 * @param action Событие.
 *
 * @return Обновлённое или то же состояние веб сокета.
 */
export function websocketReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var result = state;
    switch (action.type) {
        case WebsocketActionType.CONNECT: {
            result = __assign({}, state, { connecting: true, connected: false });
            break;
        }
        case WebsocketActionType.CONNECTED: {
            result = __assign({}, state, { connecting: false, connected: true });
            break;
        }
        case WebsocketActionType.DISCONNECTED: {
            result = __assign({}, state, { connecting: false, connected: false });
            break;
        }
        case WebsocketActionType.SUBSCRIBE: {
            if (!state.activeSubscriptions.includes(action.eventType)) {
                result = __assign({}, state, { activeSubscriptions: state.activeSubscriptions.concat([action.eventType]) });
            }
            break;
        }
        case WebsocketActionType.UNSUBSCRIBE: {
            result = __assign({}, state, { activeSubscriptions: state.activeSubscriptions.filter(function (type) { return type !== action.eventType; }) });
            break;
        }
        case WebsocketActionType.ADD_SUBSCRIPTION: {
            var activeSubscriptions = state.activeSubscriptions;
            if (!state.activeSubscriptions.includes(action.eventType)) {
                activeSubscriptions.push(action.eventType);
            }
            result = __assign({}, state, { activeSubscriptions: activeSubscriptions });
        }
    }
    return result;
}
