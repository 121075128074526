import { CountriesActionType } from "src/app/root/store/actions/countries.action";
import { CurrentUserActionType } from "src/app/root/store/actions/current-user.action";

export * from "src/app/root/store/actions/api-response-payload-action.model";
export * from "src/app/root/store/actions/current-user.action";
export * from "src/app/root/store/actions/lang.action";
export * from "src/app/root/store/actions/login.action";
export * from "src/app/root/store/actions/router.action";
export * from "src/app/root/store/actions/server-side-error.action";
export * from "src/app/root/store/actions/document-types.action";

/**
 * События, по которым можно понять, что на сервере произошла ошибка.
 *
 * Эти события слушаются в одном месте, ответ сервера сохраняется в состояние приложения, после чего
 * выполняется переход на страницу сообщения об ошибке.
 */
export const serverSideErrors: string[] = [
    CurrentUserActionType.LOAD_FAIL,
    CurrentUserActionType.LOGOUT_FAIL,
    CountriesActionType.LOAD_FAIL,
];


