import { DOCUMENT } from "@angular/common";
import { Inject } from "@angular/core";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Store } from "@ngrx/store";
import { select } from "@ngrx/store";
import { Observable } from "rxjs";
import { of } from "rxjs";
import { filter } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { map } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { currenciesActions } from "src/app/root/store/actions/currencies.action";
import { CurrenciesAction } from "src/app/root/store/actions/currencies.action";
import { CountriesAction } from "src/app/root/store/actions/countries.action";
import { countriesActions } from "src/app/root/store/actions/countries.action";
import { websocketActions } from "src/app/root/store/actions/websocket.actions";
import { RootState } from "src/app/root/store/states/root.state";
import { ApiResponse } from "../../../common/models";
import { CurrentUserInfo } from "../../../common/models";
import { DlgService } from "../../../common/services";
import { UserService } from "../../../common/services";
import { ErrorHandlerService } from "../../services";
import { DocumentTypesLoadAction } from "../actions";
import { CurrentUserActionType } from "../actions";
import { CurrentUserLoadFailAction } from "../actions";
import { CurrentUserLoadSuccessAction } from "../actions";
import { CurrentUserLogoutFailAction } from "../actions";
import { CurrentUserLogoutSuccessAction } from "../actions";
import { currentUserInfoSelector } from "../selectors";
import { CurrentUserLogoutAction } from "../actions";

/**
 * Side-эффекты на события, связанные с текущим пользователем.
 */
@Injectable()
export class CurrentUserEffects {
    //region Constants

    private static readonly ONE_MINUTE: number = 60 * 1000;

    //endregion
    //region Private fields

    /**
     * Сервис с логикой для работы с пользователем.
     */
    private readonly _userService: UserService;

    /**
     * Состояние приложения.
     */
    private readonly _store: Store<RootState>;

    /**
     * Сервис для работы с навигацией по приложению.
     */
    private readonly _router: Router;

    /**
     * Сервис с общей логикой обработки ошибочных ответов от API.
     */
    private readonly _errorHandlerService: ErrorHandlerService;

    /**
     * Сервис для создания диалогов.
     */
    private readonly _dlgService: DlgService;

    /**
     * Данные текущего пользователя.
     */
    private _currentUser: CurrentUserInfo;

    //endregion
    //region Ctor

    /**
     * Конструктор эффектов на события, связанные с текущим пользователем.
     *
     * @param _actions$ Поток событий, происходящих в системе.
     * @param _document Сервис для работы с DOM.
     * @param userService Сервис с логикой для работы с пользователем.
     * @param store Состояние приложения.
     * @param router Сервис для работы с навигацией по приложению.
     * @param errorHandlerService Сервис с общей логикой обработки ошибочных ответов от API.
     * @param dlgService Сервис для создания диалогов.
     */
    constructor(
        private _actions$: Actions,
        @Inject(DOCUMENT) private _document: any,
        userService: UserService,
        store: Store<RootState>,
        router: Router,
        errorHandlerService: ErrorHandlerService,
        dlgService: DlgService,
    ) {
        this._userService = userService;
        this._store = store;
        this._router = router;
        this._errorHandlerService = errorHandlerService;
        this._dlgService = dlgService;

        // Подписываемся на данные текущего пользователя.
        this._store
            .pipe(
                select(currentUserInfoSelector)
            )
            .subscribe((currentUser) => this._currentUser = currentUser);
    }

    //endregion
    //region Effects

    /**
     * Обработка события требования загрузки данных текущего пользователя.
     *
     * Если аутентифицированного пользователя нет, то будет редирект на форму логина.
     */
    @Effect()
    loadCurrentUserInfo$ = this._actions$
        .pipe(
            ofType(CurrentUserActionType.LOAD),
            switchMap(() =>
                this._userService.getCurrentUser()
                    .pipe(
                        map((currentUserInfo: CurrentUserInfo): Action =>
                            new CurrentUserLoadSuccessAction(currentUserInfo)
                        ),
                        catchError((response: ApiResponse): Observable<Action> =>
                            of(new CurrentUserLoadFailAction(response))
                        ),
                    )
            )
        );

    /**
     * Обработка события удачной загрузки данных текущего пользователя.
     *
     * Если есть загруженный пользователь, диспатчит событие, требующее загрузки типов документов.
     */
    @Effect()
    loadCurrentUserSuccess$ = this._actions$
        .pipe(
            ofType(CurrentUserActionType.LOAD_SUCCESS),
            map((action: CurrentUserLoadSuccessAction): CurrentUserInfo => action.payload),
            filter(Boolean),
            map(() => new DocumentTypesLoadAction()),
        );

    /**
     * Обработка события удачной загрузки данных текущего пользователя.
     *
     * Если есть загруженный пользователь, диспатчит событие, требующее загрузки списка стран.
     */
    @Effect()
    loadCountries$ = this._actions$
        .pipe(
            ofType(CurrentUserActionType.LOAD_SUCCESS),
            map((action: CurrentUserLoadSuccessAction): CurrentUserInfo => action.payload),
            filter(Boolean),
            map((): CountriesAction => countriesActions.countriesLoad()),
        );

    /**
     * Обработка события удачной загрузки данных текущего пользователя.
     *
     * Если есть загруженные пользователь, диспатчит событие, требующее загрузки списка валют.
     */
    @Effect()
    loadCurrencies$ = this._actions$
        .pipe(
            ofType(CurrentUserActionType.LOAD_SUCCESS),
            map((action: CurrentUserLoadSuccessAction): CurrentUserInfo => action.payload),
            filter(Boolean),
            map((): CurrenciesAction => currenciesActions.currenciesLoad()),
        );

    /**
     * Обработка события требования выхода текущего пользователя из системы.
     */
    @Effect()
    currentUserLogout$ = this._actions$
        .pipe(
            ofType(CurrentUserActionType.LOGOUT),
            switchMap(() =>
                this._userService.logout()
                    .pipe(
                        map((): Action => new CurrentUserLogoutSuccessAction()),
                        catchError((response: ApiResponse): Observable<Action> =>
                            this._errorHandlerService.handle(response, new CurrentUserLogoutFailAction(response))
                        ),
                    )
            )
        );

    /**
     * Обработка события загрузки данных пользователя.
     *
     * Сравнение локального времени и времени на сервере. Если различие слишком велико, то выводится диалог с
     * предупреждением.
     */
    @Effect({ dispatch: false })
    compareLocalAndServerDate$ = this._actions$
        .pipe(
            ofType(CurrentUserActionType.LOAD_SUCCESS),
            map((action: CurrentUserLoadSuccessAction): CurrentUserInfo => action.payload),
            tap((userInfo: CurrentUserInfo) => {

                if (!!userInfo && !!userInfo.serverDate) {

                    const serverDate = new Date(userInfo.serverDate);
                    const localDateDifference = Math.abs(((new Date()).getTime() - serverDate.getTime()));

                    if (localDateDifference > CurrentUserEffects.ONE_MINUTE) {

                        this._dlgService.openSimpleDlg({
                            headerKey: "common.attention",
                            textKey: "dialogs.server-to-local-time-error.text",
                            okCallback: () => this._store.dispatch(new CurrentUserLogoutAction()),
                            cancelCallback: () => this._store.dispatch(new CurrentUserLogoutAction()),
                            disableClose: true,
                        });
                    }
                }
            }),
        );

    @Effect()
    currentUserLogoutWebsocket$ = this._actions$
        .pipe(
            ofType(CurrentUserActionType.LOGOUT),
            map((): Action => websocketActions.disconnected()),
        );

    /**
     * Обработка успешного выхода текущего пользователя из системы.
     */
    @Effect({dispatch: false})
    currentUserLogoutSuccess$ = this._actions$
        .pipe(
            ofType(CurrentUserActionType.LOGOUT_SUCCESS),
            tap((): void => {

                // Переходим на корневой путь,
                //TODO редирект на логин
                this._document.location.href = window.location.origin;
            })
        );

    //endregion
}
