var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ofType } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError } from "rxjs/operators";
import { map } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { ComplaintOnDocumentDlgService } from "src/app/root/services/complaint-on-document-dlg.service";
import { DocumentService } from "src/app/root/services/document.service";
import { ErrorHandlerService } from "src/app/root/services/error-handler.service";
import { ComplaintOnDocumentFailAction } from "src/app/root/store/actions/complaint-on-documnet-dlg.action";
import { ComplaintOnDocumentSuccessAction } from "src/app/root/store/actions/complaint-on-documnet-dlg.action";
import { ComplaintOnDocumentActionType } from "src/app/root/store/actions/complaint-on-documnet-dlg.action";
/**
 * Side-эффекты на события, связанные с запросом жалобы оператора на документ.
 */
var ComplaintOnDocumentDlgEffects = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор объекта обработки side-эффектов на события, связанные со скачиванием файла реестра.
     *
     * @param _actions$ Поток событий в системе.
     * @param _store Хранилище.
     * @param complaintOnDocumentDlgService Сервис для работы с диалогом жалобы оператора на документ.
     * @param documentService Сервис для работы с документами.
     * @param errorHandleService Сервис обработки ошибочных ответов от API.
     */
    function ComplaintOnDocumentDlgEffects(_actions$, _store, complaintOnDocumentDlgService, documentService, errorHandleService) {
        var _this = this;
        this._actions$ = _actions$;
        this._store = _store;
        //endregion
        //region Ctor
        /**
         * Обработка события с требованием пожаловаться на документ.
         */
        this.complaint$ = this._actions$
            .pipe(ofType(ComplaintOnDocumentActionType.COMPLAINT_ON_DOCUMENT_REQUEST), map(function (action) { return action.payload; }), switchMap(function (request) {
            return _this._documentService.complaint(request)
                .pipe(map(function () { return new ComplaintOnDocumentSuccessAction(); }), catchError(function (response) {
                return _this._errorHandleService.handle(response, new ComplaintOnDocumentFailAction((response)));
            }));
        }));
        this._complaintOnDocumentDlgService = complaintOnDocumentDlgService;
        this._documentService = documentService;
        this._errorHandleService = errorHandleService;
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], ComplaintOnDocumentDlgEffects.prototype, "complaint$", void 0);
    return ComplaintOnDocumentDlgEffects;
}());
export { ComplaintOnDocumentDlgEffects };
