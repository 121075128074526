import { Pipe } from "@angular/core";
import { PipeTransform } from "@angular/core";
import { SafeResourceUrl } from "@angular/platform-browser";
import { DomSanitizer } from "@angular/platform-browser";

/**
 * Pipe для преобразования url в безопасный url.
 */
@Pipe({
    name: "safeUrl"
})
export class SafeUrlPipe implements PipeTransform {
    //region Ctor

    /**
     * Конструктор пайпы для преобразования url в безопасный url.
     *
     * @param sanitizer Класс для предотвращения ошибок безопасности межсайтового скриптинга (XSS), дезинфицируя
     * значения для безопасного использования в различных контекстах DOM.
     */
    constructor(private sanitizer: DomSanitizer) {}

    //endregion
    //region Public

    /**
     * Преобразует url.
     *
     * @param url Исходные url.
     *
     * @return Безопасный url.
     */
    transform(url: string): SafeResourceUrl {

        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    //endregion
}
