import { OperatorAction } from '../actions';
import { OperatorActionType } from '../actions';
import { OperatorActivityState } from './operator-activity.state';
import { OperatorActivity } from '../../models';

/**
 * Начальное состояние лога активности оператора.
 */
const initialState: OperatorActivityState = { activities: [] };

/**
 * Обработчик событий, связанных с активностью оператора.
 *
 * @param state Состояние лога активности оператора.
 * @param action Событие произошедшее в системе.
 *
 * @return Изменённое или тоже самое состояние лога активности оператора.
 */
export function operatorActivityReducer(
    state: OperatorActivityState = initialState,
    action: OperatorAction
): OperatorActivityState {

    let result: OperatorActivityState = state;

    switch (action.type) {

        // На требование добавления активности оператора в лог.
        case OperatorActionType.ADD_ACTIVITY: {

            result = {
                ...result,
                activities: [
                    ...result.activities,
                    action.activity,
                ],
            };
            break;
        }

        // На требование удаления активностей оператора из лога.
        case OperatorActionType.REMOVE_ACTIVITIES: {

            const activities: OperatorActivity[] = result.activities
                .filter((activity: OperatorActivity): boolean => action.activities.indexOf(activity) === -1);

            result = {
                ...result,
                activities,
            };
            break;
        }
    }

    return result;
}
