<div *ngIf="loading$ | async">
    <mat-spinner [diameter]="80"></mat-spinner>
</div>
<div
    *ngIf="!(loading$ | async)"
    class="complaint-on-document-dlg"
    [formGroup]="form"
>
    <h2
        mat-dialog-title
        class="mat-h2 complaint-dlg__title"
    >
        <span >{{'complaintOnDocument.title' | translate }}</span>
    </h2>
    <div
        *ngIf="!(error$ | async)"
        mat-dialog-content
        class="complaint-on-document-dlg__text"
    >
        <div *ngFor="let place of complaintProblemPlaces">
            <mat-checkbox
                formControlName="{{place.key}}"
                color="primary"
            >
                {{place.i18nKey | translate}}
            </mat-checkbox>
        </div>
        <mat-form-field class="complaint-on-document-dlg__comment">
            <textarea
                matInput
                [placeholder]="'complaintOnDocument.comment' | translate"
                formControlName="comment"
                rows="5"
            >
            </textarea>
        </mat-form-field>
    </div>
    <p
        *ngIf="!!(error$ | async)"
        mat-dialog-content
        class="complaint-on-document-dlg__text"
    >
        <span>{{(error$ | async)?.errorCode}}</span>
        <br/>
        <span>{{(error$ | async)?.errorMessage}}</span>
    </p>
    <div
        class="complaint-on-document-dlg__buttons"
        mat-dialog-actions
    >
        <button
            class="complaint-on-document-dlg__btn"
            mat-raised-button
            matDialogClose
        >
            <span *ngIf="!(error$ | async)">
                {{ "button.cancel" | translate }}
            </span>
        </button>
        <button
            *ngIf="!!(error$ | async)"
            class="complaint-on-document-dlg__btn"
            mat-raised-button
            matDialogClose
        >
            <span>
                {{ "button.close" | translate }}
            </span>
        </button>
        <button
            *ngIf="!(error$ | async)"
            class="complaint-on-document-dlg__btn"
            mat-raised-button
            color="primary"
            [disabled]="!(anyPlaceSelected$ | async)"
            (click)="complaintHandler()"
        >
            {{ "complaintOnDocument.button" | translate }}
        </button>

    </div>
</div>
<button
    *ngIf="!(loading$ | async)"
    class="mat-dialog-cross-close-btn"
    mat-flat-button
    mat-dialog-close
    title='{{ "button.close" | translate }}'
>
    <img src="/assets/images/cross-close-btn.svg" alt="">
</button>
