import { Component } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { Input } from '@angular/core';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

/**
 * Компонент навигации по страницам.
 *
 * Навигация начинается 0, но отображение идет с 1 для удобства читаемости.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'page-navigation',
    styleUrls: ['page-navigation.component.scss'],
    templateUrl: './page-navigation.component.html'
})
export class PageNavigationComponent {
    //region Inputs

    /**
     * Входящие данные: текущий индекс навигации.
     */
    @Input()
    set index(value: number) {

        this._index = value;
        this._update();
    };

    /**
     * Входящие данные: общее количетво для навигации.
     */
    @Input()
    set count(value: number) {

        this._count = value;
        this._update();
    };

    /**
     * Входящие данные: общее количетво видимых номеров слева и справа.
     */
    @Input()
    set visible(value: number) {

        this._visible = value;
        this._update();
    };

    //endregion
    //region Outputs

    /**
     * Исходящее событие - текущая страница изменилась.
     */
    @Output()
    pageChange: EventEmitter<number> = new EventEmitter();

    //endregion
    //region Private fields

    /**
     * Текущий индекс навигации.
     */
    private _index: number = 0;

    /**
     * Общее количетво для навигации.
     */
    private _count: number = 0;

    /**
     * Общее количетво видимых номеров слева и справа.
     */
    private _visible: number = 3;

    //endregion
    //region Public fields

    /**
     * Список страниц слева от текущей страницы (страниы до текущей).
     */
    left: number[] = [];

    /**
     * Список страниц справа от текущей страницы (страниы после текущей).
     */
    right: number[] = [];

    //endregion
    //region Getters and Setters

    /**
     * Текущий индекс навигации.
     */
    get index(): number {
        return  this._index;
    }

    /**
     * Общее количетво для навигации.
     */
    get count(): number {
        return  this._count;
    }

    /**
     * Общее количетво видимых номеров слева и справа.
     */
    get visible(): number {
        return  this._visible;
    }

    //endregion
    //region Events

    /**
     * Обработчик клика по номеру страницы.
     *
     * @param pageNumber Номер страницы.
     */
    pageClickHandler(pageNumber: number): void {

        if (pageNumber !== -1) {

            this.pageChange.emit(pageNumber);
        }
    }

    /**
     * Обработчик клика по кнопке перехода к первой странице.
     */
    firstPageClickHandler(): void {

        this.pageChange.emit(0);
    }

    /**
     * Обработчик клика по кнопке перехода к предыдущей странице.
     */
    prevPageClickHandler(): void {

        if (this.index > 0) {

            this.pageChange.emit(this.index - 1);
        }
    }

    /**
     * Обработчик клика по кнопке перехода к следующей странице.
     */
    nextPageClickHandler(): void {

        if(this.index < this.count - 1)
        this.pageChange.emit(this.index + 1);
    }

    /**
     * Обработчик клика по кнопке перехода к последней странице.
     */
    lastPageClickHandler(): void {

        this.pageChange.emit(this.count - 1);
    }

    //endregion
    //region Private

    /**
     * Выполняет обновление состояния компонента на основе изменений.
     */
    private _update(): void {

        this.left = [];
        const leftMinPage = Math.max(0, this._index - this._visible);
        for (let i = leftMinPage; i < this._index; i++) {

            this.left.push(i);
        }

        // Оставшиеся места, если они есть, заполняем -1, чтобы просто занять место на странице, чтобы контрол
        // всегда стоял ровно на своём месте.
        let delta: number = this._visible - this.left.length;
        for (let i = 0; i < delta; i++) {

            this.left.unshift(-1);
        }

        this.right = [];
        const rightMaxPage = Math.min(this._count - 1, this._index + this._visible);
        for (let i = this._index + 1; i <= rightMaxPage; i++) {

            this.right.push(i);
        }

        // Оставшиеся места, если они есть, заполняем -1, чтобы просто занять место на странице, чтобы контрол
        // всегда стоял ровно на своём месте.
        delta = this._visible - this.right.length;
        for (let i = 0; i < delta; i++) {

            this.right.push(-1);
        }
    }

    //endregion
}
