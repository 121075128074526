var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ComplaintOnDocumentActionType } from "src/app/root/store/actions/complaint-on-documnet-dlg.action";
var initialState = {
    loading: false,
    success: false,
    error: null,
};
/**
 * Обработка событий, связанных с состоянием запроса жалобы оператора на документ.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 *
 * @return Состояние поле обработки события.
 */
export function complaintOnDocumentDlgReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var result = state;
    switch (action.type) {
        case ComplaintOnDocumentActionType.COMPLAINT_ON_DOCUMENT_INIT: {
            result = initialState;
            break;
        }
        case ComplaintOnDocumentActionType.COMPLAINT_ON_DOCUMENT_REQUEST: {
            result = __assign({}, result, { success: false, loading: true, error: null });
            break;
        }
        case ComplaintOnDocumentActionType.COMPLAINT_ON_DOCUMENT_SUCCESS: {
            result = __assign({}, result, { success: true, loading: false });
            break;
        }
        case ComplaintOnDocumentActionType.COMPLAINT_ON_DOCUMENT_FAIL: {
            result = __assign({}, result, { loading: false, error: action.payload });
            break;
        }
    }
    return result;
}
