import { ChangeDetectionStrategy } from "@angular/core";
import { Component } from "@angular/core";

/**
 * Компонент иконки xlsx файла.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "xlsx-file-icon-svg",
    styleUrls: ["./xlsx-file-icon-svg.component.scss"],
    templateUrl: "./xlsx-file-icon-svg.component.html",
})
export class XlsxFileIconSvgComponent { }
