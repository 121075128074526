import { HttpClient } from "@angular/common/http";
import { throwError } from "rxjs";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
/**
 * Сервис для работы со списком валют.
 */
var CurrencyService = /** @class */ (function () {
    //endregion
    //region Ctor
    /**
     * Конструктор класса для работы со списком валют.
     *
     * @param httpClient HTTP клиент.
     */
    function CurrencyService(httpClient) {
        /**
         * Основные валюты.
         */
        this.popularCurrencies = ["GBP", "INR", "AED", "EUR", "USD"];
        this._httpClient = httpClient;
    }
    //endregion
    //region Public
    /**
     * Загрузка списка валют.
     *
     * @return Валюты.
     */
    CurrencyService.prototype.getAll = function () {
        var _this = this;
        return this._httpClient.get("/api/v1/processing/documentCurrencies")
            .pipe(map(function (response) {
            return _this.movePopularCurrencyInFront(response.currencies);
        }), catchError(function (response) { return throwError(response.error); }));
    };
    //endregion
    //region private
    /**
     * Передвигает вверх списка основные валюты - (USD, EUR, GBP, AED, INR).
     *
     * @param currencies Список валют.
     *
     * @return Список волют.
     */
    CurrencyService.prototype.movePopularCurrencyInFront = function (currencies) {
        this.popularCurrencies.forEach(function (alfa3Code) {
            var index = currencies.indexOf(currencies.find(function (currency) { return currency.alpha3Code === alfa3Code; }));
            currencies.unshift(currencies.splice(index, 1)[0]);
        });
        return currencies;
    };
    CurrencyService.ngInjectableDef = i0.defineInjectable({ factory: function CurrencyService_Factory() { return new CurrencyService(i0.inject(i1.HttpClient)); }, token: CurrencyService, providedIn: "root" });
    return CurrencyService;
}());
export { CurrencyService };
