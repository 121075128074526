var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CurrentUserActionType } from "../actions";
import { LoginActionType } from "../actions";
/**
 * Начальное состояние входа в систему.
 */
export var initialState = {
    pending: false,
    loggedIn: null,
    expired: false,
    error: null,
};
/**
 * Логика обработчика событий, связанных со входом в систему.
 *
 * @param state Начальное состояние входа в систему.
 * @param action Событие, связанное со входом в систему и выходом из неё.
 */
export function loginReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        //region На событие, требующее выполнения входа в систему
        case LoginActionType.SIGN_IN: {
            return __assign({}, state, { pending: true, error: null });
        }
        //endregion
        //region На событие успешного входа в систему
        case LoginActionType.SIGN_IN_SUCCEEDED: {
            return __assign({}, state, { loggedIn: true, pending: false });
        }
        //endregion
        //region На событие при ошибке во время входа в систему
        case LoginActionType.SIGN_IN_FAILED: {
            return __assign({}, state, { pending: false, error: action.response });
        }
        //endregion
        //region На событие выхода из системы
        case LoginActionType.SIGN_OUT: {
            return __assign({}, state, { expired: action.props.expired });
        }
        //endregion
        //region На событие успешного выхода из системы
        case LoginActionType.SIGN_OUT_SUCCEEDED: {
            return __assign({}, state, { loggedIn: false });
        }
        //endregion
        //region На событие успешной загрузки текущего пользователя
        case CurrentUserActionType.LOAD_SUCCESS: {
            var user = action.payload;
            if (state.loggedIn !== !!user) {
                state = __assign({}, state, { loggedIn: !!user });
            }
            return state;
        }
        //endregion
    }
    return state;
}
