import { createFeatureSelector } from "@ngrx/store";
import { createSelector } from "@ngrx/store";
import { OperatorDocumentType } from "src/app/common/models/document-type";
import { DocumentTypesState } from "src/app/root/store/reducers/document-types.reducers";

/**
 * Селектор состояния типов документов.
 */
const state = createFeatureSelector<DocumentTypesState>("documentTypesState");

/**
 * Селектор стандартных типов.
 */
const standardTypes = createSelector(state, (s: DocumentTypesState) => filterByExtendedTypes(s.documentTypes, false));

/**
 * Селектор расширенных типов.
 */
const extendedTypes = createSelector(
    state,
    (s: DocumentTypesState) => filterByExtendedTypes(s.documentTypes, true)
        .sort((a: OperatorDocumentType, b: OperatorDocumentType): number => a.name.localeCompare(b.name)));

/**
 * Селектор иностранных типов.
 */
const foreignTypes = createSelector(
    state,
    (s: DocumentTypesState) => s.documentTypes.filter((type: OperatorDocumentType) => type.foreign)
);

/**
 * Селектор стандартных иностранных типов.
 */
const foreignStandardTypes = createSelector(
    standardTypes,
    (types: OperatorDocumentType[]) => types.filter((type: OperatorDocumentType) => type.foreign)
);

/**
 * Селектор стандартных иностранных типов.
 */
const foreignExtendedTypes = createSelector(
    extendedTypes,
    (types: OperatorDocumentType[]) => types.filter((type: OperatorDocumentType) => type.foreign)
);

/**
 * Объект селекторов.
 */
const selectors = {
    state,
    standardTypes,
    extendedTypes,
    foreignTypes,
    foreignStandardTypes,
    foreignExtendedTypes,
};

/**
 * Внешний объект селекторов.
 */
export const documentTypesSelectors: Readonly<typeof selectors> = selectors;

/**
 * Возвращает типы документов, отфильтрованные по значению флага расширенности типа документов.
 *
 * @param types Список типов документа.
 * @param extended Расширенные типы документов?
 *
 * @return Список отфильтрованных типов документов.
 */
function filterByExtendedTypes(types: OperatorDocumentType[], extended: boolean): OperatorDocumentType[] {

    return types
        .filter(
            (type: OperatorDocumentType) => type.extended === extended
                && type.id !== "RECEIPT"
                && type.id !== "SELF_EMPLOYED_RECEIPT"
        );
}
