import { Action } from "@ngrx/store";

import { ApiResponse } from "src/app/common/models/api-response.model";
import { OperatorDocumentType } from "src/app/common/models/document-type";
import { ApiResponsePayloadAction } from "src/app/root/store/actions/api-response-payload-action.model";

/**
 * Типы событий, связанные с типами документов.
 */
export enum DocumentTypesActionType {

    LOAD = '[Documents registry] Load document types',
    LOAD_SUCCESS = '[Documents registry] Load document types Success',
    LOAD_FAIL = '[Documents registry] Load document types Fail',
}

/**
 * Событие, требующее загрузки типов документов.
 */
export class DocumentTypesLoadAction implements Action {

    readonly type = DocumentTypesActionType.LOAD;
    constructor() { }
}

/**
 * Событие успешной загрузки типов документов.
 */
export class DocumentTypesLoadSuccessAction implements Action {

    readonly type = DocumentTypesActionType.LOAD_SUCCESS;
    constructor(public payload: OperatorDocumentType[]) { }
}

/**
 * Событие неудачной загрузки типов документов.
 */
export class DocumentTypesLoadFailAction implements ApiResponsePayloadAction {

    readonly type = DocumentTypesActionType.LOAD_FAIL;
    constructor(public payload: ApiResponse) { }
}

/**
 * Тип, объединяющий все события, связанные с типами документов.
 */
export type DocumentTypesAction =
    | DocumentTypesLoadAction
    | DocumentTypesLoadFailAction
    | DocumentTypesLoadSuccessAction;
