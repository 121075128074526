import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { TranslateService } from "@ngx-translate/core";
import { of } from "rxjs";
import { tap } from "rxjs/operators";
import { catchError, map, switchMap } from "rxjs/operators";
import { ApiResponse } from "src/app/common/models/api-response.model";
import { OperatorDocumentType } from "src/app/common/models/document-type";
import { DocumentTypesService } from "src/app/common/services/document-types.service";
import { DlgService } from "src/app/common/services";
import { DocumentTypesLoadFailAction } from "src/app/root/store/actions/document-types.action";
import { DocumentTypesLoadSuccessAction } from "src/app/root/store/actions/document-types.action";
import { DocumentTypesActionType } from "src/app/root/store/actions/document-types.action";


/**
 * Side-эффекты на события, связанные с типами документов.
 */
@Injectable()
export class DocumentTypesEffects {
    // region Fields

    /**
     * Сервис для работы с типами документов.
     */
    private readonly _documentTypesService: DocumentTypesService;

    /**
     * Сервис для работы с диалогами.
     */
    private readonly _dlgService: DlgService;

    /**
     * Сервис перевода.
     */
    private readonly _translateService: TranslateService;

    //endregion
    //region Ctor

    constructor(
        private readonly actions$: Actions,
        documentTypesService: DocumentTypesService,
        dlgService: DlgService,
        translateService: TranslateService,
    ) {

        this._documentTypesService = documentTypesService;
        this._dlgService = dlgService;
        this._translateService = translateService;
    }

    //endregion
    //region Public

    /**
     * Выполнение загрузки типов документов.
     */
    @Effect()
    loadDocumentTypes$ = this.actions$
        .pipe(
            ofType(DocumentTypesActionType.LOAD),
            switchMap(() => this._documentTypesService.getDocumentTypes()
                .pipe(
                    map((documentTypes: OperatorDocumentType[]) => new DocumentTypesLoadSuccessAction(documentTypes)),
                    catchError((apiResponse: ApiResponse) => of(new DocumentTypesLoadFailAction(apiResponse))),
                )
            ),
        );

    /**
     * Действие при ошибке загрузки типов документов.
     */
    @Effect({ dispatch: false })
    loadDocumentTypesError$ = this.actions$
        .pipe(
            ofType(DocumentTypesActionType.LOAD_FAIL),
            tap((props: ApiResponse) =>
                this._dlgService.openSimpleDlg({
                    headerKey: "operator.documentTypes.error.title",
                    text: this._translateService.get(
                        "operator.documentTypes.error.text",
                        {message: props.errorMessage, code: props.errorCode},
                    ),
                    disableClose: true,
                    closeBtnKey: "operator.documentTypes.error.reloadButton",
                    cancelCallback: () => window.location.reload(),
                })
            ),
        );

    //endregion
}
