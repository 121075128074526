<div class="error-details">
    <input class="error-details__hidden-input" type="text" />
    <button *ngIf="!hideCloseBtn"
        class="error-details__close-btn"
        mat-icon-button
        mat-dialog-close
    >
        <mat-icon>close</mat-icon>
    </button>
    <h1 class="error-details__code">{{ errorCode }}</h1>
    <p class="error-details__below-code">{{ 'errorDetails.somethingGoesWrong' | translate }}</p>
    <img class="error-details__img" src="/assets/images/shredder-icon.svg" />
    <p class="error-details__text" [innerHtml]="errorText"></p>
    <ng-content selector=".error-details__text"></ng-content>
    <a *ngIf="!hideGoToBtn"
        class="error-details__go-to-btn"
        mat-raised-button
        color="primary"
        [routerLink]="['']"
        (click)="goToBtnClickHandler()"
    >
        {{ 'errorDetails.goToRoot' | translate }}
    </a>
    <div *ngIf="errors?.length"
        class="error-details__errors"
    >
        <div class="error-details__errors-panel">
            <div *ngFor="let error of errors"
                class="error-details__error"
            >
                <span>{{ 'errorDetails.errorId' | translate }}: {{ error.errorId }}</span>
                <span *ngIf="error.errorCode"><br/>{{ 'errorDetails.errorCode' | translate }}: {{ error.errorCode }}</span>
            </div>
        </div>
        <button
            class="error-details__copy-btn"
            mat-button
            color="primary"
            (click)="copyBtnClickHandler()"
        >
            {{ (errors.length > 1 ? 'errorDetails.copyErrorsDetails' : 'errorDetails.copyErrorDetails') | translate }}
        </button>
    </div>
</div>