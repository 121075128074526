import { defaultNotificationUrls } from "src/environments/notification-urls.model";
import { defaultOperatorBackgroundEnvironment } from "src/environments/operator-background.model";
import { OperatorEnvironment } from "src/environments/operator-environment.model";

/**
 * Test окружение.
 */
export const environment: OperatorEnvironment = {
    production: false,
    operatorServerUrl: "https://local.mbo30.ru",
    notificationUrls: defaultNotificationUrls,
    pagedTableView: false,
    operatorBackgroundEnvironment: defaultOperatorBackgroundEnvironment,
    amountModifierTypes: [
        "Round off",
        "TCS",
        "TDS",
        "Discount",
    ],
    currencyOfCountries: {
        IND: "INR",
        ARE: "AED",
    },
    companySearchMinLength: 3,
    failedSentDocumentRetryAttempts: 3,
};
